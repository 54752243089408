import React, { useEffect, useState } from 'react';

import { DownIconSvg } from '../../../../assets';
import DropdownOptionsInterface from '../../forms/DropdownInput/DropdownOptionsInterface';
import FormControl from '../../forms/FormControl/FormControl';
import Select from '../../forms/Select/Select';
import './Paginator.scss';

const pageCountOptions: DropdownOptionsInterface[] = [
	{ value: '10', displayText: '10', disabled: false, key: 0 },
	{ value: '20', displayText: '20', disabled: false, key: 1 },
	{ value: '50', displayText: '50', disabled: false, key: 2 },
	{ value: '100', displayText: '100', disabled: false, key: 3 },
];

interface PaginatorPropsInterface {
	updatePageResults: (pageIndex: number, pageCount: string) => void;
	pageSize: string;
	totalElements: number;
	pageIndex: number;
}

const Paginator = (props: PaginatorPropsInterface) => {
	const { updatePageResults, pageSize, totalElements, pageIndex } = props;

	const pageCountFloat = Number.isNaN(Number(pageSize))
		? 0
		: Number.parseFloat(pageSize);

	const totalPages = Math.ceil(totalElements / pageCountFloat);

	const [firstItemIndex, setFirstItemIndex] = useState<number>(1);

	const [lastItemIndex, setLastItemIndex] = useState<number>(pageCountFloat);

	const updateItemIndex = (pageCount: number) => {
		setFirstItemIndex(totalElements === 0 ? 0 : 1);

		// if the items per page is larger than total elements, set index to total elements
		setLastItemIndex(pageCount > totalElements ? totalElements : pageCount);
	};

	useEffect(() => {
		updateItemIndex(pageCountFloat);
	}, [totalElements]);

	const pageChange = (newPageIndex: number) => {
		setFirstItemIndex(newPageIndex * pageCountFloat + 1);

		const newLastItem = (newPageIndex + 1) * pageCountFloat;
		setLastItemIndex(newLastItem > totalElements ? totalElements : newLastItem);

		// call prop function to get new list of elements
		updatePageResults(newPageIndex, pageSize);
	};

	return (
		<div className='paginator flex-row align-center justify-between mt-1'>
			<div className='flex-row align-center gap-1'>
				<FormControl id='page-size-select' label='' small>
					<Select
						id='page-size-select'
						value={pageSize}
						onChange={val => {
							updatePageResults(0, val);

							const newPageCount = Number.parseFloat(val);
							updateItemIndex(newPageCount);
						}}
						options={pageCountOptions}
						small
					/>
				</FormControl>
				<span style={{ whiteSpace: 'nowrap' }}>per page</span>
			</div>
			<div className='flex-row align-center gap-2'>
				<button
					type='button'
					className='paginator__arrow-button'
					disabled={pageIndex === 0}
					onClick={() => {
						pageChange(pageIndex - 1);
					}}
				>
					<img
						className='paginator__left-arrow-icon'
						src={DownIconSvg}
						alt='Previous page index'
					/>
				</button>
				<span>
					{firstItemIndex}-{lastItemIndex} of{' '}
					<span className='bold'>{totalElements}</span>
				</span>
				<button
					type='button'
					className='paginator__arrow-button'
					disabled={pageIndex >= totalPages - 1 || totalElements === 0}
					onClick={() => {
						pageChange(pageIndex + 1);
					}}
				>
					<img
						className='paginator__right-arrow-icon'
						src={DownIconSvg}
						alt='Previous page index'
					/>
				</button>
			</div>
		</div>
	);
};

export default Paginator;
