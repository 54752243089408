import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DownIconSvg } from '../../../../assets';

interface LinkType {
	name: string;
	link: string;
}

const capitalizeFirstLetter = (str: string) =>
	str.charAt(0).toUpperCase() + str.slice(1);

interface Props {
	divRef: React.RefObject<HTMLDivElement>;
}

const manageEvent = 'Manage Event';
const challengeDetails = 'Challenge Details';

const Navbar = ({ divRef }: Props) => {
	const navigate = useNavigate();

	const [navbarWidth, setNavbarWidth] = useState<number>(0);

	// Use window.location.pathname since Navbar doesn't have access to route params
	const paths = window.location.pathname.split('/');

	useEffect(() => {
		setNavbarWidth((divRef.current?.firstChild as HTMLDivElement)?.clientWidth);
	}, [divRef.current, paths]);

	// parse through url string to figure out the correct order of links displayed in Navbar
	const links = useMemo(() => {
		const list: LinkType[] = [{ name: 'Home', link: 'home' }];
		let newPath = '';
		paths.forEach(path => {
			if (path) {
				newPath += `/${path}`;

				const prevPathName = list.at(-1)?.name ?? '';

				let linkName = path;
				if (!Number.isNaN(+path)) {
					if (prevPathName === 'Events') {
						linkName = 'Event Details';
					} else if (prevPathName === challengeDetails) {
						list.splice(-1);
						linkName = challengeDetails;
					} else if (prevPathName === manageEvent) {
						list.splice(-1);
						list.push({ name: 'Event Details', link: `/events/${path}` });
						linkName = manageEvent;
					}
				} else if (path === 'strokes') {
					list.splice(-1);
					linkName = 'Challenge Strokes';
				} else if (path === 'manage-event') {
					list.push({ name: 'Events', link: '/events' });
					linkName = manageEvent;
				} else if (path === 'challenges') {
					linkName = challengeDetails;
				} else if (path === 'marketing') {
					linkName = 'Marketing Details';
				} else if (path === 'modal' || path === 'sponsorship') {
					list.splice(-1);
					linkName = `Marketing ${capitalizeFirstLetter(path)}`;
				}

				list.push({ name: capitalizeFirstLetter(linkName), link: newPath });
			}
		});

		return list;
	}, [paths]);

	return (
		<section
			className='m-auto flex-row'
			style={{
				width: navbarWidth,
				marginTop: '4rem',
			}}
		>
			{links.map((link: LinkType, idx: number, arr: LinkType[]) => {
				const islastLink = idx === arr.length - 1;
				return (
					<div key={`${link.name} ${idx.toString()}`}>
						<button
							type='button'
							className={`transparent m-0 p-0 gibson-large ${
								islastLink ? 'color-disable' : 'primary-blue'
							}`}
							style={{ fontSize: '2rem' }}
							onClick={() => {
								navigate(link.link);
							}}
							disabled={islastLink}
						>
							{link.name}
						</button>
						{!islastLink && (
							<img
								style={{ margin: '0 0.5rem', transform: 'rotate(-90deg)' }}
								src={DownIconSvg}
								alt='dropdown'
							/>
						)}
					</div>
				);
			})}
		</section>
	);
};

export default Navbar;
