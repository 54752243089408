import React from 'react';
import { useNavigate } from 'react-router-dom';

import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Modal from '../../shared/components/Modal/Modal';
import useModal from '../../shared/components/Modal/useModal';
import ScrollableTable from '../../shared/components/ScrollableTable/ScrollableTable';
import Button from '../../shared/forms/Button/Button';
import FormControl from '../../shared/forms/FormControl/FormControl';
import useListOrganizations from './useListOrganizations';

const ListOrganizations = () => {
	const navigate = useNavigate();
	const { isModalOpen, toggleModal } = useModal();

	const {
		selectedOrg,
		orgName,
		setOrgName,
		isValid,
		action,
		closeModal,
		onSubmit,
		columns,
		data,
	} = useListOrganizations(toggleModal);

	return (
		<section className='table-width m-auto'>
			<FormTitle title='Organizations' />
			<div className='flex-row w-100 mb-1' style={{ position: 'relative' }}>
				<button
					type='button'
					className='ml-auto bebas-large primary-blue transparent'
					onClick={() => navigate('/manage-organization')}
				>
					Add Organization
				</button>
			</div>
			<ScrollableTable
				columns={columns}
				data={data ?? []}
				classes='box-shadow'
				tableStyles={{ width: '100%', paddingBottom: '1rem' }}
			/>
			<Modal
				isVisible={isModalOpen}
				customStyles={{
					maxWidth: '50rem',
				}}
			>
				<>
					<FormTitle
						title={`${action} ${selectedOrg?.name}?`}
						customStyles={{ marginTop: '0' }}
					/>
					<div className='flex-col gap-2'>
						<p className='m-0'>
							Are you sure you want to {action.toLowerCase()}{' '}
							{selectedOrg?.name}?
						</p>
						<p className='m-0'>
							If so, this organization and all its data will be{' '}
							{selectedOrg?.active ? 'hidden' : 'visible'}.
						</p>
						<FormControl id='org-name' label=''>
							<input
								id='org-name'
								value={orgName}
								placeholder='Type in organization name to confirm'
								onChange={e => setOrgName(e.target.value)}
							/>
						</FormControl>
					</div>
				</>

				<div className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={closeModal}>
						Cancel
					</Button>
					<Button disabled={!isValid} onClick={onSubmit}>
						{action}
					</Button>
				</div>
			</Modal>
		</section>
	);
};

export default ListOrganizations;
