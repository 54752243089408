import { useEffect, useMemo, useState } from 'react';

import { alphaNumericSort } from '../../../misc/helper-util';
import {
	createDeleteRequest,
	createGetRequest,
	createPostRequest,
} from '../../api-util';
import { dashboardApi } from '../dashboard-api';
import SlGroup from '../schema/SlGroup';
import SlGroupParticipant from '../schema/SlGroupParticipant';
import SlParticipant from '../schema/SlParticipant';
import SlRound from '../schema/SlRound';

export const groupEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		addParticipantToGroup: builder.mutation<
			void,
			{ groupId: number; groupParticipant: SlGroupParticipant }
		>({
			query: ({ groupId, groupParticipant: participant }) =>
				createPostRequest(`/group-participants/${groupId}`, participant),
			invalidatesTags: ['Player', 'Group'],
		}),
		removeParticipantFromGroup: builder.mutation<
			void,
			{ groupId: number; groupParticipant: SlGroupParticipant }
		>({
			query: ({ groupId, groupParticipant }) =>
				createDeleteRequest(`/group-participants/${groupId}`, groupParticipant),
			invalidatesTags: ['Player', 'Group'],
		}),
	}),
});

export const groupParticipantEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		getGroupParticipants: builder.query<SlParticipant[], number>({
			query: groupId =>
				createGetRequest(`/group-participants/${groupId}?size=1000`),
			providesTags: ['Player'],
			transformResponse: (response: {
				id: number;
				name: string;
				participants?: SlParticipant[];
			}) => response.participants ?? ([] as SlParticipant[]),
		}),
	}),
});

// TODO - Make new backend endpoint that grabs all participants when given an array of group ids
export const useGetParticipantsforGroups = (groups?: SlGroup[]) => {
	const groupIds = useMemo(() => groups?.map(x => x.id), [groups]);
	const [fetchedParticipants, setFetchedParticipants] = useState<
		SlParticipant[] | undefined
	>(undefined);
	const [getParticipants] =
		groupParticipantEndpoints.useLazyGetGroupParticipantsQuery();

	const fetchParticipants = async () => {
		const participantPromises: Promise<void>[] = [];
		let participants: SlParticipant[] = [];

		if (groupIds) {
			groupIds.forEach(async id => {
				participantPromises.push(
					getParticipants(id, true)
						.unwrap()
						.then(value => {
							value.forEach(newParticipant => {
								const index = participants.findIndex(
									x => x.id === newParticipant.id
								);
								// participant not found
								if (index === -1) {
									participants = [
										...participants,
										{ ...newParticipant, groupIds: [id] },
									];
								} else {
									// add new group id to list
									participants[index].groupIds?.push(id);
								}
							});
						})
				);
			});

			await Promise.all(participantPromises);

			setFetchedParticipants(
				participants.sort(
					(a, b) =>
						alphaNumericSort(a.firstName, b.firstName) ||
						alphaNumericSort(a.lastName, b.lastName)
				)
			);
		}
	};

	useEffect(() => {
		fetchParticipants();
	}, [groupIds]);

	return { data: fetchedParticipants };
};

export const useRemoveParticipantFromGroups = () => {
	const [removeParticipantFromGroup] =
		groupEndpoints.useRemoveParticipantFromGroupMutation();

	return async function removeParticipants(
		playerId: number,
		rounds?: SlRound[],
		groups?: SlGroup[],
		participants?: SlParticipant[]
	) {
		const promises: Promise<void | number | { id: number }>[] = [];
		const errors: string[] = [];

		rounds?.forEach(round => {
			const groupIds = groups
				?.filter(x => x.roundId === round.id)
				.map(x => x.id);
			const playersInRound = participants?.filter(
				x =>
					x.groupIds && groupIds && groupIds.some(r => x.groupIds?.includes(r))
			);

			playersInRound?.forEach(player => {
				if (player.id === playerId) {
					const groupId =
						groupIds?.find(x => player.groupIds?.includes(x)) ?? -1;
					promises.push(
						removeParticipantFromGroup({
							groupId,
							groupParticipant: {
								groupId,
								participantId: playerId,
							},
						})
							.unwrap()
							.catch(_ =>
								errors.push(
									`Failed to remove player ${playerId} from group id ${groupId}`
								)
							)
					);
				}
			});
		});

		await Promise.all(promises);

		return errors;
	};
};

export const {
	useAddParticipantToGroupMutation,
	useRemoveParticipantFromGroupMutation,
} = groupEndpoints;

export const { useLazyGetGroupParticipantsQuery } = groupParticipantEndpoints;
