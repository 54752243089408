/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { DownIconSvg, UpIcon } from '../../../../assets';
import './DropdownInput.scss';
import DropdownOptionsInterface from './DropdownOptionsInterface';
import useDropdownInput from './useDropdownInput';

interface Props extends React.HTMLProps<HTMLSelectElement> {
	selectHandler: (id: string) => void;
	openDropdownHandler?: () => void;
	optionsList: DropdownOptionsInterface[];
	title: string;
	showSearchInput?: boolean;
	showCheckbox?: boolean;
	classNames?: string;
}

const DropdownInput = (props: Props) => {
	const {
		selectHandler,
		openDropdownHandler,
		optionsList,
		title,
		showSearchInput,
		showCheckbox,
		classNames,
	} = props;

	const {
		isDropdownClicked,
		searchBarValue,
		filteredList,
		wrapperRef,
		buttonRef,
		handlers: { handleSearch },
	} = useDropdownInput({
		optionsList,
		openDropdownHandler,
	});

	return (
		<div
			className={`dropdown-input ${
				isDropdownClicked ? 'light-blue-border' : ''
			} ${classNames}`}
			style={{ height: !isDropdownClicked ? 'fit-content' : 'auto' }}
			ref={wrapperRef}
		>
			<div className='w-100 item'>
				<button
					type='button'
					className='w-100 h-100 p-0 transparent flex-row justify-between align-center gibson-large'
					ref={buttonRef}
				>
					{title}
					<img src={!isDropdownClicked ? DownIconSvg : UpIcon} alt='dropdown' />
				</button>
			</div>
			{isDropdownClicked && (
				<div className='dropdown-input__list flex-col'>
					{showSearchInput && (
						<label className='w-100 h-100 item' key='search-bar'>
							<input
								type='text'
								placeholder='Search...'
								className='w-100 h-100 p-1'
								value={searchBarValue}
								onChange={e => handleSearch(e.target.value)}
							/>
						</label>
					)}
					{filteredList.map(option => (
						<button
							type='button'
							key={option.key}
							className='w-100 h-100 transparent item'
							title={option.disabled ? 'Player is in another group' : ''}
							disabled={option.disabled}
							onClick={() => selectHandler(option.value)}
							style={option.subText ? { height: 'auto' } : {}}
						>
							{showCheckbox && (
								<input
									type='checkbox'
									disabled={option.disabled}
									value={option.value}
									checked={option.selected}
									readOnly
								/>
							)}
							<div className='flex-col' style={{ textAlign: 'left' }}>
								<span className='gibson-large'>{option.displayText}</span>
								{option.subText && (
									<span className='gibson-small mt-1'>{option.subText}</span>
								)}
							</div>
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default DropdownInput;
