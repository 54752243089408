import React from 'react';

import FormTitle from '../../shared/components/FormTitle/FormTitle';
import DeleteModal from '../../shared/components/Modal/DeleteModal';
import Paginator from '../../shared/components/Paginator/Paginator';
import ScrollableTable from '../../shared/components/ScrollableTable/ScrollableTable';
import useListStrokes from './useListStrokes';

const ListStrokes = () => {
	const {
		isModalOpen,
		toggleModal,
		selectedStroke,
		deletePlayerStroke,
		challengeName,
		onPageChange,
		pageSize,
		pageIndex,
		totalElements,
		columns,
		data,
	} = useListStrokes();

	return (
		<section className='table-width m-auto'>
			<h3 className='m-0' style={{ marginTop: '6.4rem' }}>
				{challengeName} - Data Capture
			</h3>
			<FormTitle title='All Strokes' customStyles={{ marginTop: '0' }} />
			<ScrollableTable
				columns={columns}
				data={data ?? []}
				classes='box-shadow'
				tableStyles={{ width: '100%', paddingBottom: '1rem' }}
			/>
			<Paginator
				updatePageResults={onPageChange}
				pageSize={pageSize}
				totalElements={totalElements}
				pageIndex={pageIndex}
			/>
			<DeleteModal
				isVisible={isModalOpen}
				toggleModal={toggleModal}
				onSubmit={deletePlayerStroke}
				type='stroke'
				title={`stroke ${selectedStroke?.stroke} for ${selectedStroke?.participantName}`}
			/>
		</section>
	);
};

export default ListStrokes;
