import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	useCreateOrganizationMutation,
	useUploadFileToS3Mutation,
} from '../../../api/dashboard/endpoints/organization-endpoints';
import SlOrganization, {
	SlOrgImage,
} from '../../../api/dashboard/schema/SlOrganization';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import { OrgLinkType } from '../../shared/types/enums';

const useManageOrganizationForm = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [createOrganization, { isLoading: isCreateOrgLoading }] =
		useCreateOrganizationMutation();
	const [uploadFileToS3, { isLoading: isUploadFileLoading }] =
		useUploadFileToS3Mutation();

	const [org, setOrg] = useState<SlOrganization>({
		id: -1,
		name: '',
		title: '',
		address: '',
		subdomain: '',
		primaryContactName: '',
		email: '',
		phone: '',
		active: true,
		links: [
			{
				type: OrgLinkType.about,
				link: '',
			},
			{
				type: OrgLinkType.contact,
				link: '',
			},
			{
				type: OrgLinkType.linkedin,
				link: '',
			},
			{
				type: OrgLinkType.twitter,
				link: '',
			},
			{
				type: OrgLinkType.youtube,
				link: '',
			},
			{
				type: OrgLinkType.other,
				link: '',
			},
		],
	});
	const [orgImage, setOrgImage] = useState<SlOrgImage>({
		file: undefined,
		fileName: '',
	});

	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	useEffect(() => {
		setIsFormValid(
			org.name.length > 0 &&
				org.title.length > 0 &&
				!!orgImage.file &&
				org.address.length > 0 &&
				org.subdomain.length > 0 &&
				org.primaryContactName.length > 0 &&
				org.email.length > 0 &&
				org.phone.length > 0
		);
	}, [org, orgImage]);

	const setLinks = (type: OrgLinkType, value: string) => {
		setOrg({
			...org,
			links: org.links.map(x => {
				if (x.type === type) {
					return {
						...x,
						link: value,
					};
				}
				return x;
			}),
		});
	};

	const saveImage = async (file: File | undefined) => {
		setOrgImage({ file, fileName: file?.name ?? '' });
	};

	return {
		handlers: {
			onBackClick: () => navigate(-1),
			onNextClick: async () => {
				try {
					const newOrg = await createOrganization(org).unwrap();

					try {
						// now need to upload file image to s3 bucket that was just created for this new organization
						// give it consistent name so we know which file is which in the S3 bucket

						const imageData = new FormData();
						imageData.append('file', orgImage.file as Blob);
						imageData.append('fileName', 'images/org-logo.png');

						await uploadFileToS3({
							orgId: newOrg.orgId,
							body: imageData,
						}).unwrap();

						dispatch(
							setToastSuccess('The organization was successfully created.')
						);
						navigate('/organizations');
					} catch {
						dispatch(
							setToastError({
								message:
									'There was an error uploading the image to the S3 bucket.',
								errors: [],
							})
						);
					}
				} catch {
					dispatch(
						setToastError({
							message: 'There was an error creating this organization.',
							errors: [],
						})
					);
				}
			},
			saveImage,
		},
		form: {
			isFormValid,
			org,
			setOrg,
			orgImage,
			setLinks,
			isCreateOrgLoading,
			isUploadFileLoading,
		},
	};
};

export default useManageOrganizationForm;
