import React from 'react';

import states from '../../../data/states';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Button from '../../shared/forms/Button/Button';
import DatePicker from '../../shared/forms/DatePicker/DatePicker';
import FormControl from '../../shared/forms/FormControl/FormControl';
import Select from '../../shared/forms/Select/Select';
import Textarea from '../../shared/forms/Textarea/Textarea';
import { OrgLinkType } from '../../shared/types/enums';
import useManageEventForm from './useManageEventForm';

const ManageEventForm = () => {
	const {
		handlers: { onBackClick, onNextClick, saveImage },
		form: {
			isFormValid,
			currentEvent,
			challengeImage,
			setLinks,
			setCurrentEvent,
			setIsDescriptionValid,
		},
	} = useManageEventForm();

	return (
		<section className='manage-event-details form-width m-auto'>
			<FormTitle title='Manage Event' />
			<form className='flex-grid'>
				<div className='row'>
					<FormControl id='event-name' label='Event Name' required>
						<input
							id='event-name'
							value={currentEvent.name}
							onChange={e =>
								setCurrentEvent({ ...currentEvent, name: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='start-date' label='Start Date' required>
						<DatePicker
							id='start-date'
							date={currentEvent.startDate}
							onChange={e => setCurrentEvent({ ...currentEvent, startDate: e })}
						/>
					</FormControl>
					<FormControl id='end-date' label='End Date' required>
						<DatePicker
							id='end-date'
							date={currentEvent.endDate}
							onChange={e => setCurrentEvent({ ...currentEvent, endDate: e })}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='venue-name' label='Venue Name'>
						<input
							id='venue-name'
							value={currentEvent.venueName}
							onChange={e =>
								setCurrentEvent({ ...currentEvent, venueName: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='venue-address' label='Venue Address'>
						<input
							id='venue-address'
							value={currentEvent.address}
							onChange={e =>
								setCurrentEvent({ ...currentEvent, address: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='city' label='City'>
						<input
							id='city'
							value={currentEvent.city}
							onChange={e =>
								setCurrentEvent({ ...currentEvent, city: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='state' label='State'>
						<Select
							id='state'
							value={currentEvent.state !== 'State' ? currentEvent.state : ''}
							onChange={value =>
								setCurrentEvent({ ...currentEvent, state: value })
							}
							options={states}
						/>
					</FormControl>

					<FormControl id='zip-code' label='Zip Code'>
						<input
							id='zip-code'
							value={currentEvent.zipCode}
							onChange={e =>
								setCurrentEvent({ ...currentEvent, zipCode: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='event-logo' label='Event Logo'>
						<label htmlFor='file-upload' className='form-control w-100 '>
							<div
								className='div--file-upload flex-row align-center justify-between px-0'
								style={{ cursor: 'pointer', position: 'relative' }}
							>
								{challengeImage.fileName || 'Choose file...'}
								<div
									className='h-100 blue-background gibson-normal bold flex-row align-center'
									style={{
										position: 'absolute',
										right: 0,
										padding: '0 1.6rem',
									}}
								>
									Browse
								</div>
							</div>
							<input
								id='file-upload'
								name='file-upload'
								type='file'
								style={{ display: 'none' }}
								onChange={e => saveImage(e.target?.files?.[0])}
							/>
						</label>
					</FormControl>
				</div>

				<div className='row'>
					<FormControl id='about-link' label='About Link'>
						<input
							id='about-link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.about)?.link
							}
							onChange={e => setLinks(OrgLinkType.about, e.target.value)}
						/>
					</FormControl>
					<FormControl id='contact-link' label='Contact Link'>
						<input
							id='contact link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.contact)
									?.link
							}
							onChange={e => setLinks(OrgLinkType.contact, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='linkedin-link' label='LinkedIn Link'>
						<input
							id='linkedin-link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.linkedin)
									?.link
							}
							onChange={e => setLinks(OrgLinkType.linkedin, e.target.value)}
						/>
					</FormControl>
					<FormControl id='twitter-link' label='Twitter Link'>
						<input
							id='twitter link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.twitter)
									?.link
							}
							onChange={e => setLinks(OrgLinkType.twitter, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='youtube-link' label='Youtube Link'>
						<input
							id='youtube-link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.youtube)
									?.link
							}
							onChange={e => setLinks(OrgLinkType.youtube, e.target.value)}
						/>
					</FormControl>
					<FormControl id='other-link' label='Other Link'>
						<input
							id='other link'
							value={
								currentEvent.links.find(x => x.type === OrgLinkType.other)?.link
							}
							onChange={e => setLinks(OrgLinkType.other, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='event-description' label='Event Description'>
						<Textarea
							id='event-description'
							value={currentEvent.description}
							onChange={(val, error) => {
								setCurrentEvent({ ...currentEvent, description: val });
								setIsDescriptionValid(!error);
							}}
							charLimit={300}
						/>
					</FormControl>
				</div>

				<div className='row' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={onBackClick}>
						Back
					</Button>
					<Button disabled={!isFormValid} onClick={onNextClick}>
						SAVE
					</Button>
				</div>
			</form>
		</section>
	);
};

export default ManageEventForm;
