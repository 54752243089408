import { useEffect, useState } from 'react';

import {
	useCreateAuthTokenMutation,
	useDeleteAuthTokenMutation,
	useLazyGetDeeplinkQuery,
	useLazyGetDeviceUsageQuery,
	useLazyGetQRCodeQuery,
} from '../../../../api/dashboard/endpoints/device-provisioning-endpoints';
import SlDeviceUsage from '../../../../api/dashboard/schema/SlDeviceUsage';
import { setToastError, setToastSuccess } from '../../../../redux/app-slice';
import { useAppDispatch } from '../../../../redux/store';

const useAuthenticationModal = (
	isChallenge: boolean,
	date: string,
	id: number,
	deviceLimit: string,
	refetchSection: boolean
) => {
	const query = isChallenge ? `round/${id}` : `${id}`;

	const dispatch = useAppDispatch();

	const [getDeeplink] = useLazyGetDeeplinkQuery();
	const [getDeviceUsage] = useLazyGetDeviceUsageQuery();
	const [getQRCode] = useLazyGetQRCodeQuery();
	const [createAuthToken] = useCreateAuthTokenMutation();
	const [deleteAuthToken] = useDeleteAuthTokenMutation();

	const [qrCode, setQRCode] = useState<string>();
	const [deeplink, setDeeplink] = useState<string>();
	const [devices, setDevices] = useState<SlDeviceUsage>();
	const [error, setError] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	let fetchCount = 0;

	const copyLink = () => {
		navigator.clipboard.writeText(deeplink ?? '');

		dispatch(setToastSuccess('Link copied.'));
	};

	const createToken = () => {
		const defaultDeviceLimit = isChallenge ? 12 : 4;
		const maxDevices = deviceLimit
			? +deviceLimit
			: devices?.maxDevices ?? defaultDeviceLimit;

		const newDate = new Date(date);
		const expirationDateInSeconds =
			newDate.setHours(newDate.getHours() + 48) / 1000;

		return createAuthToken({
			query,
			body: {
				expiration: expirationDateInSeconds,
				maxDevices,
				editHoles: true,
			},
		}).unwrap();
	};

	const fetchDeviceUsage = async () => {
		try {
			setDevices(await getDeviceUsage(query).unwrap());
		} catch {
			setError('Could not fetch device info. Please try again.');
		} finally {
			setIsLoading(false);
			setError(undefined);
		}
	};

	const fetchDeeplink = async () => {
		await getDeeplink(query)
			.unwrap()
			.catch(link => {
				if (link === false) {
					setError('Could not fetch deeplink. Please try again.');
					setIsLoading(false);
				} else {
					setDeeplink(link as string);
					fetchDeviceUsage();
				}
			});
	};

	const fetchQRCode = async () => {
		fetchCount++;
		setIsLoading(true);

		if (fetchCount < 5) {
			await getQRCode(query)
				.unwrap()
				.catch(code => {
					if (code === false) {
						createToken().then(() => {
							// now need to re-call fetchQRCode() function. This time through the authentication token should be created
							fetchQRCode();
						});
					} else {
						setQRCode(code as string);
						fetchDeeplink();
					}
				});
		} else {
			setError('Could not fetch QR Code. Please try again.');
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchQRCode();
	}, []);

	useEffect(() => {
		if (refetchSection) {
			try {
				deleteAuthToken(query)
					.unwrap()
					.then(x => {
						fetchQRCode();
					});
			} catch {
				dispatch(
					setToastError({
						message:
							'There was an error generating a new code. Please try again.',
						errors: [],
					})
				);
			}
		}
	}, [refetchSection]);

	return {
		qrCode,
		devices,
		error,
		isLoading,
		copyLink,
	};
};

export default useAuthenticationModal;
