import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useGetOrganizationsQuery,
	useToggleActiveOrganizationMutation,
} from '../../../api/dashboard/endpoints/organization-endpoints';
import SlOrganization from '../../../api/dashboard/schema/SlOrganization';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import { TableColumn } from '../../shared/components/ScrollableTable/ScrollableTable';
import Checkbox from '../../shared/forms/Checkbox/Checkbox';

const columns: TableColumn[] = [
	{ id: 'organization', label: 'Organization' },
	{ id: 'email', label: 'Owner Email' },
	{ id: 'phone', label: 'Phone' },
	{ id: 'subdomain', label: 'Sub Domain' },
	{
		id: 'enable',
		label: 'Enabled',
		styles: { width: '6%', textAlign: 'center' },
	},
];

const useListOrganizations = (toggleModal: () => void) => {
	const { data, error } = useGetOrganizationsQuery();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [toggleActiveOrganization] = useToggleActiveOrganizationMutation();

	const [selectedOrg, setSelectedOrg] = useState<SlOrganization>();
	const [orgName, setOrgName] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(false);

	const action = selectedOrg?.active ? 'Disable' : 'Enable';

	useEffect(() => {
		setIsValid(orgName === selectedOrg?.name);
	}, [orgName, selectedOrg]);

	useEffect(() => {
		if (error) {
			dispatch(
				setToastError({
					message: 'There was an error fetching organizations',
					errors: [],
				})
			);
		}
	}, [error]);

	const closeModal = () => {
		setOrgName('');
		toggleModal();
	};

	const onSubmit = async () => {
		try {
			const id = selectedOrg?.id ?? -1;
			const isActive = !selectedOrg?.active;
			await toggleActiveOrganization({ id, isActive }).unwrap();

			dispatch(
				setToastSuccess(
					`This organization was successfully ${action.toLowerCase()}d.`
				)
			);
		} catch {
			dispatch(
				setToastError({
					message: `Failed to ${action.toLowerCase()} ${selectedOrg?.name}`,
					errors: [],
				})
			);
		}

		closeModal();
	};

	return {
		selectedOrg,
		orgName,
		setOrgName,
		action,
		isValid,
		closeModal,
		onSubmit,
		columns,
		data: useMemo(
			() =>
				data?.map((org: SlOrganization) => ({
					id: org.id,
					organization: (
						<button
							type='button'
							className={`transparent gibson-normal p-0 ${
								org.active ? 'primary-blue' : 'color-disable'
							}`}
							onClick={() => {
								SessionStorageService.orgId = org.id.toString();
								navigate('/home');
							}}
							disabled={!org.active}
							title={
								!org.active
									? 'Enable this organization before accessing its data'
									: ''
							}
						>
							<u>{org.name}</u>
						</button>
					),
					email: org.email,
					phone: org.phone,
					subdomain: org.subdomain,
					enable: (
						<Checkbox
							checked={org.active}
							onChange={e => {
								setSelectedOrg(org);
								toggleModal();
							}}
							customStyles={{ margin: '-1.2rem 0 0 2rem' }}
						/>
					),
				})),
			[data]
		),
		error,
	};
};

export default useListOrganizations;
