import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	DeleteXSvg,
	InfoIcon,
	MarketingHeroBannerSection,
	MarketingPromotionSection,
	MarketingRuleSection,
	PlusSvg,
} from '../../../assets';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import Modal from '../../shared/components/Modal/Modal';
import useModal from '../../shared/components/Modal/useModal';
import Button from '../../shared/forms/Button/Button';
import Checkbox from '../../shared/forms/Checkbox/Checkbox';
import FormControl from '../../shared/forms/FormControl/FormControl';
import IconButton from '../../shared/forms/IconButton/IconButton';
import Textarea from '../../shared/forms/Textarea/Textarea';
import ToggleSwitch from '../../shared/forms/ToggleSwitch/ToggleSwitch';
import './MarketingForm.scss';
import useMarketingForm from './useMarketingForm';

const MarketingForm = () => {
	const { eventId } = useParams();
	const {
		form: {
			form,
			setForm,
			isPromotionSectionChecked,
			setIsPromotionSectionChecked,
			isPlaySectionChecked,
			setIsPlaySectionChecked,
			isFormValid,
			newPromotionCardId,
			isSubmitLoading,
		},
		handlers: {
			onBackClick,
			onNextClick,
			saveHeroImage,
			addPromotionCard,
			updatePromotionCard,
			deletePromotionCard,
			addRule,
			updateRule,
			deleteRule,
		},
	} = useMarketingForm(+(eventId ?? -1));

	const { isModalOpen, toggleModal } = useModal();

	const wrapperRef = useRef<HTMLDivElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);

	const [exampleImg, setExampleImg] = useState<string>();

	useEffect(() => {
		const toggleDropdown = (e: MouseEvent) => {
			// close modal if it's open and user click outside of it
			if (!modalRef.current?.contains(e.target as Node) && isModalOpen) {
				toggleModal();
			}
		};
		document.addEventListener('mouseup', toggleDropdown);
		return () => document.removeEventListener('mouseup', toggleDropdown);
	}, [wrapperRef, isModalOpen]);

	const openImageModal = (img: string) => {
		setExampleImg(img);
		toggleModal();
	};

	const hoverElement = (
		<span>Click me to see a preview of what this section will look like</span>
	);

	return (
		<section className='marketing-details form-width m-auto' ref={wrapperRef}>
			<FormTitle title='Marketing Details' />
			{isSubmitLoading && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<form className='flex-col gap-2'>
				<section className='flex-col gap-2'>
					<div className='flex-row align-center'>
						<h2 className='m-0'>Hero Banner Section</h2>
						<InfoIcon
							onClick={() => openImageModal(MarketingHeroBannerSection)}
							customStyles={{ marginLeft: '0.7rem' }}
							hoverElement={hoverElement}
						/>
					</div>
					<div className='flex-row'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat.
					</div>
					<div className='flex-row gap-2'>
						<FormControl id='hero-title' label='Hero Title' required>
							<input
								id='hero-title'
								value={form.title}
								onChange={e => setForm({ ...form, title: e.target.value })}
							/>
						</FormControl>
						<FormControl id='hero-image' label='Hero Image'>
							<label htmlFor='hero-file-upload' className='form-control w-100'>
								<div
									className='div--file-upload flex-row align-center justify-between h-100'
									style={{ cursor: 'pointer', position: 'relative' }}
								>
									{form.heroImage?.fileName || 'Choose file...'}
									<div
										className='h-100 blue-background gibson-normal bold flex-row align-center'
										style={{
											position: 'absolute',
											right: 0,
											padding: '0 1.6rem',
										}}
									>
										Browse
									</div>
								</div>
								<input
									id='hero-file-upload'
									name='hero-file-upload'
									type='file'
									style={{ display: 'none' }}
									onChange={e => saveHeroImage(e.target?.files?.[0])}
								/>
							</label>
						</FormControl>
					</div>
					<div className='flex-row'>
						<FormControl id='hero-subtitle' label='Hero Subtitle'>
							<Textarea
								id='hero-subtitle'
								value={form.subtitle ?? ''}
								onChange={val => setForm({ ...form, subtitle: val })}
							/>
						</FormControl>
					</div>
					<div className='flex-row gap-2'>
						<FormControl
							id='link-text'
							label='Link Text'
							required={form.linkUrl.length > 0}
						>
							<input
								id='link-text'
								value={form.linkText}
								onChange={e => setForm({ ...form, linkText: e.target.value })}
							/>
						</FormControl>
						<FormControl
							id='link-url'
							label='Link Url'
							required={form.linkText.length > 0}
						>
							<input
								id='link-url'
								value={form.linkUrl}
								onChange={e => setForm({ ...form, linkUrl: e.target.value })}
							/>
						</FormControl>
					</div>
				</section>

				<hr className='break-section' />

				<section className='marketing-details__promotion-section flex-col gap-2'>
					<div className='flex-row align-center'>
						<h2 className='m-0'>Promotion Section</h2>
						<ToggleSwitch
							selected={isPromotionSectionChecked}
							onClick={() => setIsPromotionSectionChecked(prev => !prev)}
							customStyles={{ margin: '0 1rem' }}
						/>
						<InfoIcon
							onClick={() => openImageModal(MarketingPromotionSection)}
							hoverElement={hoverElement}
						/>
					</div>
					<div className='flex-row'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat.
					</div>
					{isPromotionSectionChecked && (
						<>
							<div className='flex-row gap-2'>
								<FormControl
									id='promotion-title'
									label='Promotion Title'
									required
								>
									<input
										id='promotion-title'
										value={form.prizeText}
										onChange={e =>
											setForm({ ...form, prizeText: e.target.value })
										}
									/>
								</FormControl>
								<FormControl id='contest-url' label='Full Contest Details Url'>
									<input
										id='contest-url'
										value={form.prizeDetailsLink}
										onChange={e =>
											setForm({ ...form, prizeDetailsLink: e.target.value })
										}
									/>
								</FormControl>
							</div>
							<div className='flex-row gap-2'>
								<Checkbox
									onChange={() =>
										setForm({ ...form, showImages: !form.showImages })
									}
									checked={form.showImages}
								>
									Show images for each prize
								</Checkbox>
							</div>
							{form.prizes.map(prize => (
								<div
									className='marketing-details__promotion-section__card flex-col gap-2'
									key={prize.id}
								>
									<button
										type='button'
										onClick={() => deletePromotionCard(prize.id)}
										className='marketing-details__button transparent p-0'
										disabled={form.prizes.length === 1}
										title={
											form.prizes.length === 1
												? 'You must have at least one prize when this section is checked'
												: ''
										}
									>
										<img
											src={DeleteXSvg}
											alt={`Delete promotion ${prize.id}`}
										/>
									</button>
									<div className='flex-row gap-2'>
										<FormControl
											id='promotion-card-title'
											label='Card Title'
											required
										>
											<input
												id='promotion-card-title'
												value={prize.title}
												onChange={e =>
													updatePromotionCard({
														...prize,
														title: e.target.value,
													})
												}
											/>
										</FormControl>
										{form.showImages && (
											<FormControl
												id='promotion-card-image'
												label='Card Image'
												required
											>
												<label
													htmlFor={`promotion-file-upload-${prize.id}`}
													className='form-control w-100'
												>
													<div
														className='div--file-upload flex-row align-center justify-between h-100'
														style={{ cursor: 'pointer', position: 'relative' }}
													>
														{prize.image?.fileName || 'Choose file...'}
														<div
															className='h-100 blue-background gibson-normal bold flex-row align-center'
															style={{
																position: 'absolute',
																right: 0,
																padding: '0 1.6rem',
															}}
														>
															Browse
														</div>
													</div>
													<input
														id={`promotion-file-upload-${prize.id}`}
														name={`promotion-file-upload-${prize.id}`}
														type='file'
														style={{ display: 'none' }}
														onChange={e => {
															const file = e.target?.files?.[0];
															updatePromotionCard({
																...prize,
																image: { file, fileName: file?.name ?? '' },
															});
														}}
													/>
												</label>
											</FormControl>
										)}
									</div>
									<div className='flex-row'>
										<FormControl
											id='promotion-card-description'
											label='Card Description'
											required
										>
											<Textarea
												id='promotion-card-description'
												value={prize.description}
												onChange={val => {
													if (val.length <= 200)
														updatePromotionCard({ ...prize, description: val });
												}}
												charLimit={200}
												customStyles={{ height: '8rem' }}
											/>
										</FormControl>
									</div>
								</div>
							))}
							<div className='marketing-details__promotion-section__button'>
								<IconButton
									icon={PlusSvg}
									title='Add Promotion Card'
									classNames='bebas-large primary-blue'
									onClick={() => {
										addPromotionCard({
											id: newPromotionCardId,
											marketingInfoId: form.id,
											title: '',
											imagePath: '',
											description: '',
											image: {
												file: undefined,
												fileName: '',
											},
										});
									}}
								>
									ADD PROMOTION CARD
								</IconButton>
							</div>
						</>
					)}
				</section>

				<hr className='break-section' />

				<section className='marketing-details__input-list-section flex-col gap-2'>
					<div className='flex-row align-center'>
						<h2 className='m-0'>How to Play Section</h2>
						<ToggleSwitch
							selected={isPlaySectionChecked}
							onClick={() => setIsPlaySectionChecked(prev => !prev)}
							customStyles={{ margin: '0 1rem' }}
						/>
						<InfoIcon
							onClick={() => openImageModal(MarketingRuleSection)}
							hoverElement={hoverElement}
						/>
					</div>
					<div className='flex-row'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat.
					</div>
					{isPlaySectionChecked && (
						<>
							{form.rules.map((rule, index) => (
								<div style={{ position: 'relative' }} key={`Rule ${index + 1}`}>
									<FormControl
										id={`rule-input-${index}`}
										label={`Rule ${index + 1}`}
										required
									>
										<button
											type='button'
											onClick={() => deleteRule(index)}
											className='marketing-details__button delete-rule transparent p-0'
											disabled={form.rules.length === 1}
											title={
												form.rules.length === 1
													? 'You must have at least one rule when this section is checked'
													: ''
											}
										>
											<img src={DeleteXSvg} alt={`Delete rule ${index}`} />
										</button>
										<input
											id={`rule-input-${index}`}
											value={rule}
											onChange={e => updateRule(index, e.target.value)}
											style={{ paddingRight: '4rem' }}
										/>
									</FormControl>
								</div>
							))}
							<div className='marketing-details__promotion-section__button'>
								<IconButton
									icon={PlusSvg}
									title='Add Rule'
									classNames='bebas-large primary-blue'
									onClick={addRule}
								>
									ADD RULE
								</IconButton>
							</div>
						</>
					)}
				</section>
				<section className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={onBackClick}>
						Back
					</Button>
					<Button disabled={!isFormValid} onClick={onNextClick}>
						Save
					</Button>
				</section>
			</form>
			<Modal
				modalRef={modalRef}
				isVisible={isModalOpen}
				customStyles={{ width: '80%' }}
			>
				<img width='100%' src={exampleImg} alt='Marketing Rule Section' />
			</Modal>
		</section>
	);
};

export default MarketingForm;
