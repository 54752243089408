const envs: {
	[name: string]: { HOST: string; AUTH_URL: string; LOGOUT_URL: string };
} = {
	'dev-localhost': {
		HOST: 'shotlandtst.golf',
		AUTH_URL:
			'https://shotlander-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=1b8q8tirto96kavmgkv9cfg979&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A8081',
		LOGOUT_URL:
			'https://shotlander-dev.auth.us-east-1.amazoncognito.com/logout?client_id=1b8q8tirto96kavmgkv9cfg979&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A8081',
	},
	dev: {
		HOST: 'shotlandtst.golf',
		AUTH_URL:
			'https://shotlander-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=1b8q8tirto96kavmgkv9cfg979&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlandtst.golf',
		LOGOUT_URL:
			'https://shotlander-dev.auth.us-east-1.amazoncognito.com/logout?client_id=1b8q8tirto96kavmgkv9cfg979&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlandtst.golf',
	},
	staging: {
		HOST: 'shotlandint.golf',
		AUTH_URL:
			'https://shotlander-staging.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=6487q008kfkm2sbf5gt401r20p&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlandint.golf',
		LOGOUT_URL:
			'https://shotlander-staging.auth.us-east-1.amazoncognito.com/logout?client_id=6487q008kfkm2sbf5gt401r20p&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlandint.golf',
	},
	prod: {
		HOST: 'shotlander.io',
		AUTH_URL:
			'https://shotlander-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=cf9sm7kfv7h5sc16ia30rie5l&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlander.io',
		LOGOUT_URL:
			'https://shotlander-prod.auth.us-east-1.amazoncognito.com/logout?client_id=cf9sm7kfv7h5sc16ia30rie5l&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fshotlander.io',
	},
};

let stage = 'dev-localhost';
if (
	process.env.REACT_APP_STAGE &&
	Object.keys(envs).includes(process.env.REACT_APP_STAGE)
) {
	stage = process.env.REACT_APP_STAGE;
}

export const REVOKE_AUTH_URL =
	'https://shotlander-dev.auth.us-east-1.amazoncognito.com/oauth2/revoke?';

export const { HOST, AUTH_URL, LOGOUT_URL } = envs[stage];
