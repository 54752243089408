import React, { useMemo } from 'react';

import './Textarea.scss';

interface Props {
	id: string;
	value: string;
	charLimit?: number;
	onChange: (value: string, error: boolean) => void;
	customStyles?: { [key: string]: string };
}

const Textarea = ({ id, charLimit, value, onChange, customStyles }: Props) => {
	const overLimit = useMemo(() => {
		if (charLimit) {
			return value.length > charLimit;
		}

		return false;
	}, [charLimit, value]);

	return (
		<div className='textarea-wrapper w-100'>
			<textarea
				id={id}
				rows={5}
				className='w-100'
				onChange={e => {
					onChange(e.target.value, overLimit);
				}}
				value={value}
				style={customStyles}
			/>
			{charLimit && (
				<div className={`char-count ${overLimit ? 'over-limit ' : ''}`}>
					{value?.length} / {charLimit}
				</div>
			)}
		</div>
	);
};

export default Textarea;
