import React from 'react';

export interface TableColumn {
	id: string;
	label: string;
	styles?: { [key: string]: string | number | boolean };
}

interface Props {
	columns: TableColumn[];
	data: { id: number | string }[];
	classes?: string;
	tableStyles?: { [key: string]: string | number | boolean };
}

const ScrollableTable = ({ columns, data, classes, tableStyles }: Props) => (
	<section
		className={`scrollable-table ${classes ?? ''}`}
		style={tableStyles ?? {}}
	>
		<div>
			<div>
				<table cellSpacing={0}>
					<thead>
						<tr>
							{columns.map(column => (
								<th key={column.id} align='left' style={column.styles ?? {}}>
									<div>{column.label}</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map(row => (
							<tr key={row.id}>
								{columns.map(column => {
									const value = row[column.id as keyof typeof row];
									return (
										<td
											key={column.id}
											align='left'
											style={column.styles ?? {}}
										>
											{value}
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</section>
);

export default ScrollableTable;
