/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import Button from '../../shared/forms/Button/Button';
import FormControl from '../../shared/forms/FormControl/FormControl';
import { OrgLinkType } from '../../shared/types/enums';
import useManageOrganizationForm from './useManageOrganizationForm';

const ManageOrganizationForm = () => {
	const {
		handlers: { onBackClick, onNextClick, saveImage },
		form: {
			isFormValid,
			org,
			setOrg,
			orgImage,
			setLinks,
			isCreateOrgLoading,
			isUploadFileLoading,
		},
	} = useManageOrganizationForm();

	return (
		<section className='form-width m-auto'>
			<FormTitle title='Organization Information' />
			{(isCreateOrgLoading || isUploadFileLoading) && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<form className='flex-col gap-2'>
				<div className='flex-row'>
					<FormControl id='org-name' label='Organization Name' required>
						<input
							id='org-name'
							value={org.name}
							onChange={e => setOrg({ ...org, name: e.target.value })}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl id='page-title' label='Page Title' required>
						<input
							id='page-title'
							value={org.title}
							onChange={e => setOrg({ ...org, title: e.target.value })}
						/>
					</FormControl>
					<FormControl id='org-logo' label='Organization Logo' required>
						<label htmlFor='file-upload' className='form-control w-100'>
							<div
								className='div--file-upload flex-row align-center justify-between h-100'
								style={{ cursor: 'pointer', position: 'relative' }}
							>
								{orgImage.fileName || 'Choose file...'}
								<div
									className='h-100 blue-background gibson-normal bold flex-row align-center'
									style={{
										position: 'absolute',
										right: 0,
										padding: '0 1.6rem',
									}}
								>
									Browse
								</div>
							</div>
							<input
								id='file-upload'
								name='file-upload'
								type='file'
								style={{ display: 'none' }}
								onChange={e => saveImage(e.target?.files?.[0])}
							/>
						</label>
					</FormControl>
				</div>
				<div className='flex-row'>
					<FormControl id='mailing-address' label='Mailing Address' required>
						<input
							id='mailing-address'
							value={org.address}
							onChange={e => setOrg({ ...org, address: e.target.value })}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl id='subdomain' label='Subdomain' required>
						<input
							id='subdomain'
							value={org.subdomain}
							onChange={e => setOrg({ ...org, subdomain: e.target.value })}
						/>
					</FormControl>
					<FormControl id='contact-name' label='Primary Contact Name' required>
						<input
							id='contact-name'
							value={org.primaryContactName}
							onChange={e =>
								setOrg({ ...org, primaryContactName: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl
						id='email'
						label='Primary Contact Email Address'
						required
					>
						<input
							id='email'
							value={org.email}
							onChange={e => setOrg({ ...org, email: e.target.value })}
						/>
					</FormControl>
					<FormControl
						id='phone-number'
						label='Primary Contact Phone Number'
						required
					>
						<input
							id='phone-number'
							value={org.phone}
							onChange={e => setOrg({ ...org, phone: e.target.value })}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl id='about-link' label='Company About Link'>
						<input
							id='about-link'
							value={org.links.find(x => x.type === OrgLinkType.about)?.link}
							onChange={e => setLinks(OrgLinkType.about, e.target.value)}
						/>
					</FormControl>
					<FormControl id='contact-link' label='Contact Link'>
						<input
							id='contact link'
							value={org.links.find(x => x.type === OrgLinkType.contact)?.link}
							onChange={e => setLinks(OrgLinkType.contact, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl id='linkedin-link' label='LinkedIn Link'>
						<input
							id='linkedin-link'
							value={org.links.find(x => x.type === OrgLinkType.linkedin)?.link}
							onChange={e => setLinks(OrgLinkType.linkedin, e.target.value)}
						/>
					</FormControl>
					<FormControl id='twitter-link' label='Twitter Link'>
						<input
							id='twitter link'
							value={org.links.find(x => x.type === OrgLinkType.twitter)?.link}
							onChange={e => setLinks(OrgLinkType.twitter, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2'>
					<FormControl id='youtube-link' label='Youtube Link'>
						<input
							id='youtube-link'
							value={org.links.find(x => x.type === OrgLinkType.youtube)?.link}
							onChange={e => setLinks(OrgLinkType.youtube, e.target.value)}
						/>
					</FormControl>
					<FormControl id='other-link' label='Other Link'>
						<input
							id='other link'
							value={org.links.find(x => x.type === OrgLinkType.other)?.link}
							onChange={e => setLinks(OrgLinkType.other, e.target.value)}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={onBackClick}>
						Back
					</Button>
					<Button disabled={!isFormValid} onClick={onNextClick}>
						Save
					</Button>
				</div>
			</form>
		</section>
	);
};

export default ManageOrganizationForm;
