import React from 'react';

import './FormTitle.scss';

interface Props {
	title: string;
	customStyles?: { [key: string]: string };
}

const FormTitle = ({ title, customStyles }: Props) => (
	<section style={customStyles} className='form-title'>
		<h1 className='noto-title'>{title}</h1>
		<hr />
	</section>
);

export default FormTitle;
