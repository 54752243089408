import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useCreateMarketingSponsorshipMutation,
	useGetMarketingSponsorshipQuery,
	useUpdateMarketingSponsorshipMutation,
} from '../../../api/dashboard/endpoints/event-endpoints';
import { SlMarketingSponsorship } from '../../../api/dashboard/schema/SlMarketingSponsorship';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';

const useMarketingSponsorshipForm = (eventId: number) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const orgId = +SessionStorageService.orgId;

	const { data: sponsorship, isError } = useGetMarketingSponsorshipQuery({
		orgId,
		eventId,
	});

	const [
		createMarketingSponsorship,
		{ isLoading: isCreateSponsorshipLoading },
	] = useCreateMarketingSponsorshipMutation();
	const [
		updateMarketingSponsorship,
		{ isLoading: isUpdateSponsorshipLoading },
	] = useUpdateMarketingSponsorshipMutation();

	const [form, setForm] = useState<SlMarketingSponsorship>({
		id: -1,
		eventId,
		title: '',
		description: '',
		imagePath: '',
		link: '',
	});

	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [isNewSponsorship, setIsNewSponsorship] = useState<boolean>(true);

	useEffect(() => {
		if (sponsorship && !isError) {
			setForm(sponsorship);
			setIsNewSponsorship(false);
		}
	}, [sponsorship, isError]);

	useEffect(() => {
		setIsFormValid(form.title.length > 0 && form.description.length > 0);
	}, [form]);

	return {
		form: {
			form,
			setForm,
			isFormValid,
			isSubmitLoading: isCreateSponsorshipLoading || isUpdateSponsorshipLoading,
		},
		handlers: {
			onBackClick: () => navigate(-1),
			onNextClick: async () => {
				try {
					if (isNewSponsorship) {
						await createMarketingSponsorship({
							orgId,
							sponsorship: form,
						}).unwrap();
					} else {
						await updateMarketingSponsorship({
							orgId,
							sponsorship: form,
						}).unwrap();
					}

					dispatch(
						setToastSuccess(
							'The marketing sponsorship was successfully saved for this event.'
						)
					);
				} catch {
					dispatch(
						setToastError({
							message:
								'Failed to update the marketing sponsorship for this event.',
							errors: [],
						})
					);
				}
				navigate(`/events/${eventId}`);
			},
		},
	};
};

export default useMarketingSponsorshipForm;
