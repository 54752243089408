import React from 'react';
import { useParams } from 'react-router-dom';

import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import Button from '../../shared/forms/Button/Button';
import FormControl from '../../shared/forms/FormControl/FormControl';
import Textarea from '../../shared/forms/Textarea/Textarea';
import useMarketingSponsorshipForm from './useMarketingSponsorshipForm';

const MarketingSponsorshipForm = () => {
	const { eventId } = useParams();
	const {
		form: { form, setForm, isFormValid, isSubmitLoading },
		handlers: { onBackClick, onNextClick },
	} = useMarketingSponsorshipForm(+(eventId ?? -1));

	return (
		<section className='marketing-details form-width m-auto'>
			<FormTitle title='Marketing Sponsorship' />
			{isSubmitLoading && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<form className='flex-col gap-2'>
				<div className='flex-row gap-2'>
					<FormControl
						id='sponsorship-title'
						label='Sponsorship Title'
						required
					>
						<input
							id='sponsorship-title'
							value={form.title}
							onChange={e => setForm({ ...form, title: e.target.value })}
						/>
					</FormControl>
					<FormControl id='sponsorship-link' label='Link'>
						<input
							id='sponsorship-link'
							value={form.link}
							onChange={e => setForm({ ...form, link: e.target.value })}
						/>
					</FormControl>
				</div>
				<div className='flex-row'>
					<FormControl
						id='sponsorship-description'
						label='Sponsorship Description'
						required
					>
						<Textarea
							id='sponsorship-description'
							value={form.description}
							onChange={val => {
								if (val.length <= 100) setForm({ ...form, description: val });
							}}
							charLimit={100}
							customStyles={{ height: '8rem' }}
						/>
					</FormControl>
				</div>
				<div className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={onBackClick}>
						Back
					</Button>
					<Button disabled={!isFormValid} onClick={onNextClick}>
						Save
					</Button>
				</div>
			</form>
		</section>
	);
};

export default MarketingSponsorshipForm;
