/* eslint-disable no-alert */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	AuthenticateSvg,
	EditSvg,
	ManageShotsSvg,
	TrashCanSvg,
} from '../../../assets';
import { ModalType } from '../../shared/types/enums';

interface ActionOption {
	displayText: string;
	icon?: string;
	iconAlt?: string;
	onClickHandler: () => void;
}

interface Props {
	text?: string;
	id?: number;
	options?: ActionOption[];
	styles?: { [key: string]: string | number | boolean };
	mouseLeaveHandler: () => void;
	pickModalType?: (type: ModalType) => void;
}

const ActionPopup = ({
	text,
	id,
	options,
	styles,
	mouseLeaveHandler,
	pickModalType,
}: Props) => {
	const navigate = useNavigate();
	const actions = useMemo(
		() =>
			options ?? [
				{
					displayText: `View/Edit ${text}`,
					icon: EditSvg,
					iconAlt: 'Edit icon',
					onClickHandler: () => {
						if (text === 'Challenge') {
							navigate(`challenges/${id}`);
						} else if (text === 'Player') {
							pickModalType?.(ModalType.Edit);
							mouseLeaveHandler();
						}
					},
				},
				{
					displayText: `Authenticate ${text}`,
					icon: AuthenticateSvg,
					iconAlt: 'Authenticate icon',
					onClickHandler: () => {
						pickModalType?.(ModalType.Authenticate);
						mouseLeaveHandler();
					},
				},
				{
					displayText: 'Manage Shots',
					icon: ManageShotsSvg,
					iconAlt: 'Manage shots icon',
					onClickHandler: () => {
						navigate(`challenges/${id}/strokes`);
					},
				},
				{
					displayText: `Delete ${text}`,
					icon: TrashCanSvg,
					iconAlt: 'Trashcan icon',
					onClickHandler: () => {
						pickModalType?.(ModalType.Delete);
						mouseLeaveHandler();
					},
				},
			],
		[text, id, options]
	);

	if (text === 'Player') actions.splice(1, 2);

	return (
		<div
			className='action-popup flex-col'
			style={styles}
			onMouseLeave={mouseLeaveHandler}
		>
			{actions.map(action => (
				<button
					type='button'
					onClick={action.onClickHandler}
					key={action.displayText}
					className='flex-row align-center'
				>
					{action.icon && <img src={action.icon} alt={action.iconAlt} />}
					<span>{action.displayText}</span>
				</button>
			))}
		</div>
	);
};

export default ActionPopup;
