import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { HOST } from '../../auth/auth-config';

export const dashboardApi = createApi({
	reducerPath: 'dashboardApi',
	baseQuery: fetchBaseQuery({ baseUrl: `https://api.${HOST}/dashboard` }),
	tagTypes: [
		'Org',
		'Event',
		'Challenge',
		'Group',
		'Player',
		'Stroke',
		'Marketing',
	],
	endpoints: () => ({}),
});

export default dashboardApi;
