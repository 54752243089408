import { capitalizeFirstLetter } from '../../../misc/helper-util';
import DropdownOptionsInterface from '../../shared/forms/DropdownInput/DropdownOptionsInterface';
import { DivisionType } from '../../shared/types/enums';

const createSerialOptions = (
	min: number,
	max: number,
	isHeightDropdown: boolean,
	interval?: number
) => {
	const options: DropdownOptionsInterface[] = [];
	const incrementValue = interval ?? 1;
	const unit = isHeightDropdown ? 'in.' : 'lbs.';

	for (let i = min; i <= max; i += incrementValue) {
		const option: DropdownOptionsInterface = {
			value: `${i}`,
			displayText: `${i} ${unit}`,
			disabled: false,
			key: i,
		};
		options.push(option);
	}

	return options;
};

export const genderOptions = [
	{ value: 'woman', displayText: 'Woman' },
	{ value: 'man', displayText: 'Man' },
	{ value: 'transgender', displayText: 'Transgender' },
	{
		value: 'non-binary',
		displayText: 'Non-binary/non-conforming',
	},
	{
		value: 'no-response',
		displayText: 'Prefer not to respond',
	},
];

export const heightOptions = [
	{
		value: 'height',
		disabled: true,
		key: 0,
		displayText: 'Height (in.)',
	},
	...createSerialOptions(60, 84, true),
];
export const weightOptions = [
	{
		value: 'weight',
		disabled: true,
		key: 0,
		displayText: 'Weight (lbs.)',
	},
	...createSerialOptions(100, 250, false, 10),
];
export const dominantHandOptions = [
	{
		displayText: 'Dominant Hand',
		disabled: true,
		key: 0,
		value: 'dominant-hand',
	},
	{
		displayText: 'Left',
		value: 'left',
		disabled: false,
		key: 1,
	},
	{
		displayText: 'Right',
		value: 'right',
		disabled: false,
		key: 2,
	},
];
export const divisionOptions = [
	{
		displayText: 'Division',
		disabled: true,
		key: 0,
		value: 'division-level',
	},
	...Object.values(DivisionType).map((type, index) => ({
		displayText: capitalizeFirstLetter(type),
		disabled: false,
		key: index + 1,
		value: type,
	})),
];
