import { useEffect, useState } from 'react';

import { alphaNumericSort } from '../../../misc/helper-util';
import { setPlayersInOrg } from '../../../redux/app-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	createDeleteRequest,
	createGetRequest,
	createPostRequest,
	createPutRequest,
} from '../../api-util';
import { dashboardApi } from '../dashboard-api';
import SlOrganization, { SlOrgResponse } from '../schema/SlOrganization';
import SlParticipant from '../schema/SlParticipant';
import SlResponseArray from '../schema/SlResponseArray';

const orgEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		createOrganization: builder.mutation<SlOrgResponse, SlOrganization>({
			query: body => createPostRequest('/organization/register', body),
			invalidatesTags: ['Org'],
		}),
		uploadFileToS3: builder.mutation<void, { orgId: number; body: FormData }>({
			query: ({ orgId, body }) =>
				createPostRequest(`/organization/s3/${orgId}`, body, false),
		}),
		getOrganization: builder.query<SlOrganization, number>({
			query: orgId => createGetRequest(`/organization/${orgId}`),
			providesTags: ['Org'],
		}),
		getOrganizations: builder.query<SlOrganization[], void>({
			query: () => createGetRequest(`/organization?size=1000`),
			transformResponse: (response: SlResponseArray<SlOrganization>) =>
				response.content ?? [],
			providesTags: ['Org'],
		}),
		updateOrganization: builder.mutation<void, SlOrganization>({
			query: body => createPutRequest('/organization', body),
			invalidatesTags: ['Org'],
		}),
		toggleActiveOrganization: builder.mutation<
			void,
			{ id: number; isActive: boolean }
		>({
			query: ({ id, isActive }) =>
				createPutRequest(`/organization/${id}/status`, isActive),
			invalidatesTags: ['Org'],
		}),
	}),
});

const orgParticipantEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		addParticipant: builder.mutation<
			SlParticipant,
			{ orgId: number; participant: SlParticipant }
		>({
			query: ({ orgId, participant }) =>
				createPostRequest(`/organization/${orgId}/participant`, participant),
			invalidatesTags: ['Player'],
		}),
		addParticipants: builder.mutation<
			SlParticipant[],
			{ orgId: number; participants: SlParticipant[] }
		>({
			query: ({ orgId, participants }) =>
				createPostRequest(
					`/organization/${orgId}/participant/bulk`,
					participants
				),
			invalidatesTags: ['Player'],
		}),
		getParticipant: builder.query<
			SlParticipant,
			{ orgId: number; playerId: number }
		>({
			query: ({ orgId, playerId }) =>
				createGetRequest(`/organization/${orgId}/participant/${playerId}`),
			providesTags: ['Player'],
		}),
		getParticipants: builder.query<
			SlResponseArray<SlParticipant>,
			{ orgId: number; pageIndex?: number }
		>({
			query: ({ orgId, pageIndex = 0 }) =>
				createGetRequest(
					`/organization/${orgId}/participant?size=1000&page=${pageIndex}`
				),
			providesTags: ['Player'],
		}),
		updateParticipant: builder.mutation<
			SlParticipant,
			{ orgId: number; participant: SlParticipant }
		>({
			query: ({ orgId, participant }) =>
				createPutRequest(
					`/organization/${orgId}/participant/${participant.id}`,
					participant
				),
			invalidatesTags: ['Player'],
		}),
		removeParticipant: builder.mutation<
			number,
			{ orgId: number; participantId: number }
		>({
			query: ({ orgId, participantId }) =>
				createDeleteRequest(
					`/organization/${orgId}/participant/${participantId}`
				),
			invalidatesTags: ['Player'],
		}),
	}),
});

// if more than a 1000 players are returned, then loop through each page until have entire collection
export const useGetAllParticipantsQuery = (orgId: number) => {
	const dispatch = useAppDispatch();
	const { allPlayersInOrg } = useAppSelector(state => state.app);

	const [getParticipants, { isFetching }] =
		orgParticipantEndpoints.useLazyGetParticipantsQuery();

	const [fetchedParticipants, setFetchedParticipants] = useState<
		SlParticipant[] | undefined
	>(undefined);

	const fetchParticipants = async () => {
		const participants: SlParticipant[] = [];
		let keepGoing = true;
		let pageIndex = 0;

		while (keepGoing) {
			// eslint-disable-next-line no-await-in-loop
			const response = await getParticipants({ orgId, pageIndex }).unwrap();

			if (response && response.content) {
				participants.push(...response.content);

				if (participants.length >= response.totalElements) {
					keepGoing = false;
				} else {
					pageIndex++;
				}
			}
		}

		const playersSorted = participants.sort(
			(a, b) =>
				alphaNumericSort(a.firstName, b.firstName) ||
				alphaNumericSort(a.lastName, b.lastName)
		);

		dispatch(setPlayersInOrg(playersSorted));
		setFetchedParticipants(playersSorted);
	};

	useEffect(() => {
		// fetch players if store collection is empty
		if (allPlayersInOrg.length === 0) {
			fetchParticipants();
		} else {
			setFetchedParticipants(allPlayersInOrg);
		}
	}, [orgId, allPlayersInOrg]);

	return { data: fetchedParticipants, isFetching };
};

export const {
	useCreateOrganizationMutation,
	useUploadFileToS3Mutation,
	useGetOrganizationQuery,
	useGetOrganizationsQuery,
	useUpdateOrganizationMutation,
	useToggleActiveOrganizationMutation,
} = orgEndpoints;

export const {
	useAddParticipantMutation,
	useAddParticipantsMutation,
	useLazyGetParticipantQuery,
	useGetParticipantsQuery,
	useUpdateParticipantMutation,
	useRemoveParticipantMutation,
} = orgParticipantEndpoints;
