import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useDeleteChallengeMutation,
	useGetChallengesQuery,
	useGetGroupsforRounds,
	useGetHolesforRounds,
} from '../../../api/dashboard/endpoints/challenge-endpoints';
import {
	useDeleteEventMutation,
	useDeleteMarketingInfoMutation,
	useDeleteMarketingModalInfoMutation,
	useDeleteMarketingSponsorshipMutation,
	useGetEventQuery,
	useGetMarketingInfoQuery,
	useGetMarketingModalInfoQuery,
	useGetMarketingSponsorshipQuery,
} from '../../../api/dashboard/endpoints/event-endpoints';
import {
	useGetParticipantsforGroups,
	useRemoveParticipantFromGroups,
} from '../../../api/dashboard/endpoints/group-endpoints';
import SlParticipant from '../../../api/dashboard/schema/SlParticipant';
import {
	setToastError,
	setToastSuccess,
	updatePlayerInOrg,
} from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import useModal from '../../shared/components/Modal/useModal';
import { TableColumn } from '../../shared/components/ScrollableTable/ScrollableTable';
import { widthOfActionPopup } from '../../shared/constants';
import { ActionType } from '../../shared/types/enums';

export interface ChallengeData {
	id: number;
	challenge: string;
	date: string;
	holeCount: number | undefined;
	playerCount: number | undefined;
	groupCount: number | undefined;
	actions: Element;
}

// eslint-disable-next-line no-shadow
export enum MarketingType {
	MarketingPage = 'Marketing Page',
	MarketingModal = 'Marketing Modal',
	Offer = 'Offer',
}

const useEventDetails = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { isModalOpen, toggleModal } = useModal();

	const [deleteEvent] = useDeleteEventMutation();
	const removeParticipants = useRemoveParticipantFromGroups();
	const [deleteChallenge] = useDeleteChallengeMutation();

	const [deleteMarketingInfo] = useDeleteMarketingInfoMutation();
	const [deleteMarketingModalInfo] = useDeleteMarketingModalInfoMutation();
	const [deleteMarketingSponsorship] = useDeleteMarketingSponsorshipMutation();

	const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
	const [popupTop, setPopupTop] = useState<number>(0);
	const [popupLeft, setPopupLeft] = useState<number>(0);
	const [actionType, setActionType] = useState<ActionType>();
	const [elementId, setElementId] = useState<number>(0);
	const [rowName, setRowName] = useState<string>('');
	const [playersInEvent, setPlayersInEvent] = useState<SlParticipant[]>([]);

	const handleModal = (action: ActionType, id: number, name: string) => {
		setActionType(action);
		setElementId(id);
		setRowName(name);
	};

	const { eventId } = useParams();

	const orgId = +SessionStorageService.orgId;

	const eventIdNum = eventId ? +eventId : 0;
	const { data: currentEvent } = useGetEventQuery({
		orgId,
		eventId: eventIdNum,
	});
	const { data: rounds } = useGetChallengesQuery(eventIdNum);
	const holes = useGetHolesforRounds(rounds);
	const groups = useGetGroupsforRounds(rounds);

	const { data: participants } = useGetParticipantsforGroups(groups);

	const { data: marketingInfo, isError: isMarketingInfoError } =
		useGetMarketingInfoQuery({
			orgId,
			eventId: eventIdNum,
		});

	const { data: marketingModalInfo, isError: isMarketingModalError } =
		useGetMarketingModalInfoQuery({
			orgId,
			eventId: eventIdNum,
		});

	const { data: sponsorship, isError: isSponsorshipError } =
		useGetMarketingSponsorshipQuery({
			orgId,
			eventId: eventIdNum,
		});

	useEffect(() => {
		if (participants) setPlayersInEvent(participants);
	}, [participants]);

	const updatePlayer = (playerToUpdate: SlParticipant | null) => {
		if (playerToUpdate) {
			const players = [...playersInEvent];
			const playerIndex = players.findIndex(x => x.id === playerToUpdate.id);

			// need to add in players group id's since this was calculated in player fetch process
			players[playerIndex] = {
				...playerToUpdate,
				groupIds: players[playerIndex].groupIds,
			};
			setPlayersInEvent(players);

			// also update store with updated player
			dispatch(updatePlayerInOrg(playerToUpdate));
		}
		toggleModal();
	};

	const handleClickEvent = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		action: ActionType,
		id: number,
		name: string
	) => {
		setPopupLeft(
			event.clientX + document.documentElement.scrollLeft - widthOfActionPopup
		);
		setPopupTop(event.clientY + document.documentElement.scrollTop);

		handleModal(action, id, name);
		setIsPopupVisible(true);
	};

	const handleDelete = async () => {
		if (currentEvent) {
			if (actionType === ActionType.Event) {
				try {
					await deleteEvent({
						orgId: currentEvent.orgId,
						eventId: currentEvent.id,
					}).unwrap();
					dispatch(setToastSuccess('This event was successfully deleted.'));

					navigate('/events');
				} catch {
					dispatch(
						setToastError({
							message: `Failed to delete ${currentEvent.name}`,
							errors: [],
						})
					);
				}
			} else if (actionType === ActionType.Player) {
				const errors = await removeParticipants(
					elementId,
					rounds,
					groups,
					playersInEvent
				);

				if (errors.length === 0) {
					dispatch(setToastSuccess('This player was successfully deleted.'));

					setPlayersInEvent(playersInEvent.filter(x => x.id !== elementId));
				} else {
					dispatch(
						setToastError({
							message: 'Unexpected errors.',
							errors,
						})
					);
				}
			} else if (actionType === ActionType.Challenge) {
				const currentChallenge = rounds?.find(x => x.id === elementId);
				try {
					await deleteChallenge({
						eventId: currentChallenge?.eventId ?? -1,
						challengeId: currentChallenge?.id ?? -1,
					});

					dispatch(setToastSuccess('This challenge was successfully deleted.'));
				} catch {
					dispatch(
						setToastError({
							message: `Failed to delete ${currentChallenge?.name}`,
							errors: [],
						})
					);
				}
			} else if (actionType === ActionType.Marketing) {
				try {
					if (rowName === MarketingType.MarketingPage && marketingInfo) {
						await deleteMarketingInfo({
							orgId: currentEvent.orgId,
							eventId: currentEvent.id,
							marketingInfoId: marketingInfo.id,
						}).unwrap();
					} else if (
						rowName === MarketingType.MarketingModal &&
						marketingModalInfo
					) {
						await deleteMarketingModalInfo({
							orgId: currentEvent.orgId,
							eventId: currentEvent.id,
							marketingInfoModalId: marketingModalInfo.id,
						}).unwrap();
					} else if (rowName === MarketingType.Offer && sponsorship) {
						await deleteMarketingSponsorship({
							orgId: currentEvent.orgId,
							eventId: currentEvent.id,
							marketingSponsorshipId: sponsorship.id,
						}).unwrap();
					}

					dispatch(
						setToastSuccess(
							`The ${rowName} for this event was successfully deleted.`
						)
					);
				} catch {
					dispatch(
						setToastError({
							message: `The ${rowName} for this event failed to delete.`,
							errors: [],
						})
					);
				}
			}

			toggleModal();
		}
	};

	return {
		state: {
			isModalOpen,
			toggleModal,
			isPopupVisible,
			setIsPopupVisible,
			popupTop,
			popupLeft,
			actionType,
			elementId,
			rowName,
			handleModal,
			currentEvent,
			handleDelete,
			updatePlayer,
			marketingInfoId: isMarketingInfoError ? undefined : marketingInfo?.id,
			marketingModalId: isMarketingModalError
				? undefined
				: marketingModalInfo?.id,
			marketingOfferId: isSponsorshipError ? undefined : sponsorship?.id,
		},
		data: {
			roundColumns: [
				{ id: 'challenge', label: 'Challenge' },
				{ id: 'date', label: 'Date' },
				{ id: 'holeCount', label: 'Holes' },
				{ id: 'playerCount', label: 'Players' },
				{ id: 'groupCount', label: 'Groups' },
				{
					id: 'actions',
					label: 'Actions',
					styles: { width: '8%', textAlign: 'center' },
				},
			] as TableColumn[],
			roundData: rounds?.map(({ id, name, date }) => ({
				id,
				challenge: name,
				date,
				holeCount: holes?.filter(x => x.roundId === id).length,
				playerCount: (() => {
					const groupIds = groups?.filter(x => x.roundId === id).map(x => x.id);
					return playersInEvent.filter(
						x =>
							x.groupIds &&
							groupIds &&
							groupIds.some(r => x.groupIds?.includes(r))
					).length;
				})(),
				groupCount: groups?.filter(x => x.roundId === id).length,
				actions: (
					<button
						type='button'
						className='transparent action-button'
						onClick={e => handleClickEvent(e, ActionType.Challenge, id, name)}
					>
						•••
					</button>
				),
			})) as ChallengeData[] | undefined,
			playerColumns: [
				{ id: 'player', label: 'Player' },
				{ id: 'email', label: 'Email' },
				{
					id: 'actions',
					label: 'Actions',
					styles: { width: '8%', textAlign: 'center' },
				},
			] as TableColumn[],
			playerData: playersInEvent.map(({ id, firstName, lastName, email }) => ({
				id,
				player: `${firstName} ${lastName}`,
				email,
				actions: (
					<button
						type='button'
						className='transparent action-button'
						onClick={e =>
							handleClickEvent(
								e,
								ActionType.Player,
								id,
								`${firstName} ${lastName}`
							)
						}
					>
						•••
					</button>
				),
			})),
		},
	};
};

export default useEventDetails;
