export const getAuthToken = () => window.sessionStorage.getItem('authToken');
export const removeAuthToken = () =>
	window.sessionStorage.removeItem('authToken');
export const setAuthToken = (authToken: string) =>
	window.sessionStorage.setItem('authToken', authToken);

/**
 * Pull auth values from the url
 * @param prefix
 * @param separator
 * @returns
 */
export const parseStringFromAuthResponse = (
	prefix: string,
	separator = '&'
): string | null => {
	try {
		const {
			location: { href },
		} = window;
		if (!href.includes(prefix)) {
			throw new Error('Invalid href: Token not found in href');
		}
		const [token] = href.split(separator).filter(val => val.includes(prefix));
		const start = token.indexOf(prefix);
		const parsedToken = token.substring(start + prefix.length);
		return parsedToken;
	} catch (err) {
		// TODO: replace with proper error logging
		// eslint-disable-next-line no-console
		console.log(err);
		return null;
	}
};
