import React, { PropsWithChildren } from 'react';

import IconButton from '../../forms/IconButton/IconButton';
import './Card.scss';

interface Props extends PropsWithChildren {
	title: string;
	buttons?: {
		icon?: string;
		text: string;
		action: () => void;
		disabled?: boolean;
		hoverTitle?: string;
	}[];
}

const Card = ({ buttons, title, children }: Props) => (
	<div className='card w-100 box-shadow'>
		<div className='card__header w-100 flex-row'>
			<h3 className='gibson-large m-0 mr-auto'>{title}</h3>
			{buttons?.map(({ action, icon, text, disabled, hoverTitle }) => (
				<IconButton
					key={text}
					onClick={action}
					icon={icon}
					title={title}
					warningMessage={hoverTitle ?? ''}
					disabled={disabled}
				>
					{text}
				</IconButton>
			))}
		</div>
		<hr />
		{children}
	</div>
);

export default Card;
