import { getAuthToken } from '../auth/auth-util';

export function createGetRequest(url: string) {
	return {
		url,
		headers: {
			Authorization: `Bearer ${getAuthToken()}`,
		},
	};
}

export function createPostRequest<T>(url: string, body: T, isJson = true) {
	const headers = isJson
		? {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: `Bearer ${getAuthToken()}`,
		  }
		: { Authorization: `Bearer ${getAuthToken()}` };

	return {
		url,
		method: 'POST',
		body,
		headers,
	};
}

export function createPutRequest<T>(url: string, body: T) {
	return {
		url,
		method: 'PUT',
		body,
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			Authorization: `Bearer ${getAuthToken()}`,
		},
	};
}

export function createDeleteRequest<T = void>(url: string, body?: T) {
	return {
		url,
		body,
		method: 'DELETE',
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			Authorization: `Bearer ${getAuthToken()}`,
		},
	};
}
