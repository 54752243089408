import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	useDeleteStrokeMutation,
	useGetChallengeQuery,
	useLazyGetStrokesQuery,
} from '../../../api/dashboard/endpoints/challenge-endpoints';
import SlStroke from '../../../api/dashboard/schema/SlStroke';
import { DeleteXSvg } from '../../../assets';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import useModal from '../../shared/components/Modal/useModal';
import { TableColumn } from '../../shared/components/ScrollableTable/ScrollableTable';

const columns: TableColumn[] = [
	{ id: 'name', label: 'Player Name' },
	{ id: 'holeNumber', label: 'Hole Number' },
	{ id: 'shotNumber', label: 'Shot Number' },
	{ id: 'timestamp', label: 'Timestamp' },
	{
		id: 'delete',
		label: 'Delete',
		styles: { width: '5%', textAlign: 'center' },
	},
];

const useListStrokes = () => {
	const { eventId, challengeId } = useParams();
	const eventIdNum = eventId ? +eventId : -1;
	const challengeIdNum = challengeId ? +challengeId : -1;

	const { isModalOpen, toggleModal } = useModal();
	const dispatch = useAppDispatch();

	const { data: challenge } = useGetChallengeQuery({
		eventId: eventIdNum,
		challengeId: challengeIdNum,
	});
	const [deleteStroke] = useDeleteStrokeMutation();

	const [getStrokes, { data: strokes, error }] = useLazyGetStrokesQuery();

	const [selectedStroke, setSelectedStroke] = useState<SlStroke>();

	const [pageIndex, setPageIndex] = useState<number>(0);
	const [pageSize, setPageSize] = useState<string>('20');
	const [totalElements, setTotalElements] = useState<number>(0);

	useEffect(() => {
		if (error) {
			dispatch(
				setToastError({
					message: `There was an error fetching stroke data for challenge ${challengeIdNum}`,
					errors: [],
				})
			);
		}
	}, [error]);

	const deletePlayerStroke = async () => {
		try {
			await deleteStroke({
				eventId: eventIdNum,
				challengeId: challengeIdNum,
				strokeId: selectedStroke?.id ?? -1,
			}).unwrap();

			dispatch(setToastSuccess('This stroke was successfully deleted.'));
		} catch {
			dispatch(
				setToastError({
					message: `Failed to delete stroke ${selectedStroke?.stroke} for ${selectedStroke?.participantName}`,
					errors: [],
				})
			);
		}
		toggleModal();
	};

	useEffect(() => {
		getStrokes({
			eventId: eventIdNum,
			challengeId: challengeIdNum,
			pageSize,
			pageIndex,
		});
	}, [pageSize, pageIndex]);

	const onPageChange = (pageNumber: number, elementsPerPage: string) => {
		setPageIndex(pageNumber);
		setPageSize(elementsPerPage);
	};

	return {
		isModalOpen,
		toggleModal,
		selectedStroke,
		deletePlayerStroke,
		challengeName: challenge?.name,
		onPageChange,
		pageSize,
		pageIndex,
		totalElements,
		columns,
		data: useMemo(() => {
			setTotalElements(strokes?.totalElements ?? 0);

			return strokes?.content.map(stroke => ({
				id: stroke.id,
				name: stroke.participantName,
				holeNumber: stroke.holeNumber,
				shotNumber: stroke.stroke,
				timestamp: dayjs(stroke.created).format('hh:mm:ss A'),
				delete: (
					<button
						type='button'
						className='transparent'
						onClick={() => {
							toggleModal();
							setSelectedStroke(stroke);
						}}
					>
						<img src={DeleteXSvg} alt={`Delete stroke ${stroke.id}`} />
					</button>
				),
			}));
		}, [strokes]),
	};
};

export default useListStrokes;
