import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SlParticipant from '../../../api/dashboard/schema/SlParticipant';
import {
	AuthenticateSvg,
	DeleteXSvg,
	PlusSvg,
	TrashCanSvg,
} from '../../../assets';
import { addPlayerToOrg, setToastError } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import Modal from '../../shared/components/Modal/Modal';
import useModal from '../../shared/components/Modal/useModal';
import DropdownInput from '../../shared/forms/DropdownInput/DropdownInput';
import FormControl from '../../shared/forms/FormControl/FormControl';
import IconButton from '../../shared/forms/IconButton/IconButton';
import { ChallengeType } from '../../shared/types/enums';
import AddPlayerForm from '../AddPlayerForm/AddPlayerForm';
import GroupDetails from './GroupDetails';
import './GroupDetailsEntry.scss';

interface Props {
	allGroups: GroupDetails[];
	groupDetails: GroupDetails;
	groupNum: number;
	onUpdate: (details: GroupDetails) => void;
	onDelete: (id: number) => void;
	onAuthenticate: (groups: GroupDetails[]) => void;
	playersInOrg: {
		id: number;
		firstName: string;
		lastName: string;
	}[];
	challengeType: ChallengeType;
}

const GroupDetailsEntry = ({
	allGroups,
	groupDetails,
	groupNum,
	onUpdate,
	onDelete,
	onAuthenticate,
	playersInOrg,
	challengeType,
}: Props) => {
	const dispatch = useAppDispatch();

	const { isModalOpen, toggleModal } = useModal();
	const { challengeId } = useParams();
	// disable authenticate button if it's a new challenge or new group
	const isAuthenticateDisabled =
		challengeId === undefined || groupDetails.id < 0;

	const addPlayerDisabledMessage =
		challengeType === ChallengeType.FullRound &&
		allGroups.flatMap(x => x.playerIds).length === 12
			? 'You cannot add more than 12 players for this challenge type'
			: '';

	const addPlayerToGroup = (playerToAdd: number) => {
		onUpdate({
			...groupDetails,
			playerIds: [...groupDetails.playerIds, playerToAdd],
		});
	};

	const removePlayersFromGroup = (playerIds: number[]): void => {
		const filteredPlayers = groupDetails.playerIds.filter(
			x => !playerIds.includes(x)
		);

		onUpdate({ ...groupDetails, playerIds: filteredPlayers });
	};

	const updatePlayerInGroup = (id: string) => {
		const playerId = parseInt(id, 10);
		const playerUpdate = playersInOrg.find(player => player.id === playerId);

		if (playerUpdate) {
			const foundPlayerInGroup = groupDetails.playerIds.some(
				x => x === playerUpdate.id
			);

			if (foundPlayerInGroup) {
				removePlayersFromGroup([playerUpdate.id]);
			} else {
				addPlayerToGroup(playerUpdate.id);
			}
		}
	};

	const saveNewPlayer = (playerToAdd: SlParticipant | null) => {
		// if player was created successfully, then add it to group
		if (playerToAdd) {
			addPlayerToGroup(playerToAdd.id);
			dispatch(addPlayerToOrg(playerToAdd));
		}
		toggleModal();
	};

	const playerPills = useMemo(
		() =>
			groupDetails.playerIds.map(id => {
				const player = playersInOrg.find(x => x.id === id);
				return player ? (
					<span
						key={player?.id}
						className='flex-row align-center justify-between'
					>
						{player?.firstName} {player?.lastName}
						<button
							type='button'
							onClick={() => updatePlayerInGroup(`${id}`)}
							className='transparent p-0'
						>
							<img src={DeleteXSvg} alt={`Delete player ${id}`} />
						</button>
					</span>
				) : null;
			}),
		[groupDetails.playerIds, playersInOrg]
	);

	return (
		<>
			<div className='group-details-entry'>
				<div className='flex-row justify-between align-center'>
					<h3 className='header gibson-large m-0'>Group {groupNum}</h3>
					<div className='flex-row'>
						<IconButton
							icon={AuthenticateSvg}
							title='Authenticate'
							onClick={() => onAuthenticate([groupDetails])}
							classNames='mr-1'
							disabled={isAuthenticateDisabled}
							warningMessage={
								isAuthenticateDisabled
									? 'Please save this group before authenticating'
									: ''
							}
						>
							Authenticate
						</IconButton>
						<IconButton
							onClick={() => onDelete(groupDetails.id)}
							icon={TrashCanSvg}
							title='Delete Group'
						>
							Delete
						</IconButton>
					</div>
				</div>
				<div className='row'>
					<FormControl id='group-name-x' label='Group Name'>
						<input
							id='group-name-x'
							value={groupDetails.groupName}
							onChange={e =>
								onUpdate({ ...groupDetails, groupName: e.target.value })
							}
						/>
					</FormControl>
				</div>
				<div className='flex-col gap-2' style={{ marginTop: '2rem' }}>
					<div>Players in {groupDetails.groupName}</div>
					<div className='group-details-entry__player-section flex-row gap-2'>
						{groupDetails && playerPills}
					</div>
				</div>
				<hr />
				<div className='row'>
					<DropdownInput
						selectHandler={e => {
							if (!addPlayerDisabledMessage) {
								updatePlayerInGroup(e);
							} else {
								dispatch(
									setToastError({
										message: addPlayerDisabledMessage,
										errors: [],
									})
								);
							}
						}}
						optionsList={playersInOrg.map(player => {
							const isPlayerInCurrentGroup = groupDetails.playerIds.some(
								x => x === player.id
							);
							return {
								value: `${player.id}`,
								displayText: `${player.firstName} ${player.lastName}`,
								selected: isPlayerInCurrentGroup,
								disabled:
									// disable option if player is in a group, but not this one
									allGroups
										.flatMap(x => x.playerIds)
										.some(x => x === player.id) && !isPlayerInCurrentGroup,
								key: player.id,
							};
						})}
						title='Add Players From List'
						showSearchInput
						showCheckbox
						classNames='w-50'
					/>
					<IconButton
						icon={PlusSvg}
						title='add player'
						onClick={toggleModal}
						classNames='add-player-button w-50 bebas-large bebas-large primary-blue'
						disabled={!!addPlayerDisabledMessage}
						warningMessage={addPlayerDisabledMessage}
					>
						ADD PLAYER
					</IconButton>
				</div>
			</div>
			<Modal
				isVisible={isModalOpen}
				toggleModal={toggleModal}
				customStyles={{ width: '90rem', height: '92rem' }}
			>
				<AddPlayerForm toggleModal={saveNewPlayer} groupId={groupDetails.id} />
			</Modal>
		</>
	);
};

export default GroupDetailsEntry;
