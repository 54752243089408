import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuthenticateSvg, DownIconSvg, PlusSvg, UpIcon } from '../../../assets';
import {
	capitalizeFirstLetter,
	reverseCamelCase,
} from '../../../misc/helper-util';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import AuthenticationModal from '../../shared/components/Modal/AuthenticationModal';
import useModal from '../../shared/components/Modal/useModal';
import Button from '../../shared/forms/Button/Button';
import Checkbox from '../../shared/forms/Checkbox/Checkbox';
import DatePicker from '../../shared/forms/DatePicker/DatePicker';
import DropdownInput from '../../shared/forms/DropdownInput/DropdownInput';
import FormControl from '../../shared/forms/FormControl/FormControl';
import IconButton from '../../shared/forms/IconButton/IconButton';
import Textarea from '../../shared/forms/Textarea/Textarea';
import { ChallengeType, MapType, TeeType } from '../../shared/types/enums';
import './ChallengeDetails.scss';
import GroupDetails from './GroupDetails';
import GroupDetailsEntry from './GroupDetailsEntry';
import HoleDetailsEntry from './HoleDetailsEntry';
import useChallengeDetails from './useChallengeDetails';

const ChallengeDetails = () => {
	const { eventId, challengeId } = useParams();
	const isNewChallenge = challengeId === undefined;

	const {
		form: {
			challenge,
			setChallenge,
			currentEvent,
			isFormValid,
			challengeTypeList,
			csvFile,
			csvFileError,
			parseCsvFile,
			isLoading,
			onNextClick,
			onBackClick,
		},
		hole: {
			newHoleId,
			holesDetails,
			nextAvailableHoleNum,
			isHoleDetailsOpen,
			setIsHoleDetailsOpen,
			addHole,
			updateHole,
			deleteHole,
			updateAllHoles,
		},
		group: {
			requiredDatasheetHeaders,
			nonRequiredDatasheetHeaders,
			allParticipants,
			groupsDetails,
			newGroupId,
			isGroupDetailsOpen,
			setIsGroupDetailsOpen,
			addGroup,
			updateGroup,
			deleteGroup,
		},
	} = useChallengeDetails({ eventId: eventId ?? '-1', challengeId });

	const [authGroups, setAuthGroups] = useState(groupsDetails);

	const { isModalOpen, toggleModal } = useModal();

	const pageTitle = `${isNewChallenge ? 'Add' : 'Edit'} Challenge`;

	const updateAuthGroups = (groups: GroupDetails[]) => {
		// Only authenticate groups that are already saved in DB
		setAuthGroups(groups.filter(x => x.id >= 0));
		toggleModal();
	};

	return (
		<section className='manage-challenge form-width m-auto'>
			<FormTitle title={pageTitle} />
			{isLoading && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<div className='row'>
				<FormControl id='challenge-name' label='Challenge Name' required>
					<input
						id='challenge-name'
						value={challenge.name}
						onChange={e => setChallenge({ ...challenge, name: e.target.value })}
					/>
				</FormControl>
				<FormControl id='challenge-date' label='Date' required>
					<DatePicker
						id='challenge-date'
						date={challenge.date}
						minDate={currentEvent?.startDate}
						maxDate={currentEvent?.endDate}
						onChange={value => setChallenge({ ...challenge, date: value })}
					/>
				</FormControl>
			</div>
			<div className='row'>
				<FormControl id='challenge-description' label='Description'>
					<Textarea
						id='challenge-description'
						charLimit={300}
						onChange={value =>
							setChallenge({ ...challenge, description: value })
						}
						value={challenge.description}
					/>
				</FormControl>
			</div>
			<div className='row'>
				<Checkbox
					checked={!challenge.active}
					onChange={e => {
						setChallenge({ ...challenge, active: !challenge.active });
					}}
				>
					<h3>Keep This Challenge Private</h3>
					<h4>Only users with a direct link can access</h4>
				</Checkbox>
			</div>
			<div className='row' style={{ marginTop: '3rem' }}>
				<DropdownInput
					selectHandler={e => {
						if (e !== challenge.challengeType) {
							setChallenge({ ...challenge, challengeType: e as ChallengeType });

							if (e === ChallengeType.PinPointLeaderboard) {
								updateAllHoles([
									{
										id: newHoleId,
										challengeId: challengeId ? +challengeId : 0,
										par: 3,
										holeNum: '1',
										pinLocation: {
											latitude: '0',
											longitude: '0',
										},
										teeLocations: [
											{
												id: -1,
												holeConfigId: newHoleId,
												location: {
													latitude: '0',
													longitude: '0',
												},
												type: TeeType.default,
											},
										],
									},
								]);
							} else {
								updateAllHoles([]);
							}
						}
					}}
					optionsList={challengeTypeList}
					title={`Challenge Type: ${challenge.challengeType
						.replace(/([A-Z])/g, ' $1')
						.trim()}`}
					classNames='w-100'
					showCheckbox
				/>
				<DropdownInput
					selectHandler={e => {
						setChallenge({ ...challenge, mapType: e as MapType });
					}}
					optionsList={Object.values(MapType).map((type, index) => ({
						value: type,
						displayText: capitalizeFirstLetter(type),
						selected: type === challenge.mapType,
						disabled: false,
						key: index,
					}))}
					title={`Map Type: ${capitalizeFirstLetter(challenge.mapType)}`}
					classNames='w-100'
					showCheckbox
				/>
			</div>

			{/* Hole Details */}
			{challenge.challengeType !== ChallengeType.PinPointLeaderboard && (
				<section className='hole-details'>
					<div className='flex-row justify-between mb-1'>
						<button
							type='button'
							className='flex-row align-center p-0 transparent'
							onClick={() => setIsHoleDetailsOpen(prev => !prev)}
						>
							<h3 className='m-0 mr-1 gibson-large'>Hole Details</h3>
							<img
								src={!isHoleDetailsOpen ? DownIconSvg : UpIcon}
								alt='dropdown'
							/>
						</button>
						{isHoleDetailsOpen && (
							<IconButton
								icon={PlusSvg}
								title='Add Hole'
								classNames='bebas-large primary-blue'
								onClick={() =>
									addHole({
										id: newHoleId,
										challengeId: challengeId ? +challengeId : 0,
										par: 3,
										holeNum: nextAvailableHoleNum,
										pinLocation: {
											latitude: '0',
											longitude: '0',
										},
										teeLocations: [
											{
												id: -1,
												holeConfigId: newHoleId,
												location: {
													latitude: '0',
													longitude: '0',
												},
												type: TeeType.default,
											},
										],
									})
								}
								disabled={
									challenge.challengeType !== ChallengeType.FullRound &&
									holesDetails.length >= 1
								}
								warningMessage={
									challenge.challengeType !== ChallengeType.FullRound &&
									holesDetails.length >= 1
										? 'You can only add one hole for this challenge type'
										: ''
								}
							>
								ADD HOLE
							</IconButton>
						)}
					</div>
					<div
						style={{
							height: isHoleDetailsOpen ? 'auto' : '0',
							visibility: isHoleDetailsOpen ? 'visible' : 'hidden',
						}}
					>
						{holesDetails.length === 0 && (
							<div className='empty-list'>No Holes Added</div>
						)}
						<div className='hole-entries'>
							{holesDetails.map(details => (
								<HoleDetailsEntry
									key={details.id}
									details={details}
									onUpdate={updateHole}
									onDelete={deleteHole}
									disabledHoleNumbers={holesDetails.map(x => x.holeNum)}
									challengeType={challenge.challengeType}
								/>
							))}
						</div>
					</div>
				</section>
			)}

			{/* Group Details */}
			<section className='group-details'>
				<div className='flex-row justify-between mb-1'>
					<button
						type='button'
						className='flex-row align-center p-0 transparent'
						onClick={() => setIsGroupDetailsOpen(prev => !prev)}
					>
						<h3 className='m-0 mr-1 gibson-large'>Group Details</h3>
						<img
							src={!isGroupDetailsOpen ? DownIconSvg : UpIcon}
							alt='dropdown'
						/>
					</button>
					{isGroupDetailsOpen && (
						<div className='flex-row'>
							<IconButton
								icon={AuthenticateSvg}
								title='Authenticate Groups'
								classNames='mx-2 bebas-large primary-blue'
								onClick={() => {
									updateAuthGroups(groupsDetails);
								}}
								disabled={isNewChallenge || groupsDetails.length === 0}
								warningMessage={
									isNewChallenge
										? 'Please save these groups before authenticating'
										: ''
								}
							>
								Authenticate Groups
							</IconButton>
							<IconButton
								icon={PlusSvg}
								title='Add Group'
								classNames='bebas-large primary-blue'
								onClick={() =>
									addGroup({
										groupName: '',
										challengeId: challengeId ? +challengeId : 0,
										id: newGroupId,
										playerIds: [],
									})
								}
							>
								ADD GROUP
							</IconButton>
						</div>
					)}
				</div>
				<div
					style={{
						height: isGroupDetailsOpen ? 'auto' : '0',
						visibility: isGroupDetailsOpen ? 'visible' : 'hidden',
					}}
				>
					<FormControl
						id='participant-csv'
						label='Bulk Participant Add'
						style={{ marginBottom: '1rem' }}
						infoIconHoverElement={
							<ul>
								<li>
									Please choose a file with an extension of <q>.csv</q>,{' '}
									<q>.xls</q>, or <q>.xlsx</q>
								</li>
								<li>
									The following column headers are required:
									<ul>
										{requiredDatasheetHeaders.map((x: string) => (
											<li key={x}>{reverseCamelCase(x)}</li>
										))}
									</ul>
								</li>
								<li>
									The following column headers are optional:
									<ul>
										{nonRequiredDatasheetHeaders.map((x: string) => (
											<li key={x}>{reverseCamelCase(x)}</li>
										))}
									</ul>
								</li>
							</ul>
						}
					>
						<label
							htmlFor='participant-csv-file-upload'
							className='form-control w-100'
						>
							<div
								className={`${
									csvFileError ? 'input-error' : ''
								} div--file-upload flex-row align-center justify-between h-100`}
								style={{ cursor: 'pointer', position: 'relative' }}
							>
								{csvFile.fileName || 'Choose file...'}
								<div
									className='h-100 blue-background gibson-normal bold flex-row align-center'
									style={{
										position: 'absolute',
										right: 0,
										padding: '0 1.6rem',
									}}
								>
									Browse
								</div>
							</div>
							<input
								id='participant-csv-file-upload'
								name='participant-csv-file-upload'
								type='file'
								style={{ display: 'none' }}
								accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
								onChange={async e => parseCsvFile(e.target?.files?.[0])}
							/>
						</label>
					</FormControl>
					<hr
						style={{
							border: '1px solid rgba(0, 0, 0, 0.1)',
							margin: '1.5rem 0',
						}}
					/>
					{groupsDetails.length === 0 && (
						<div className='empty-list'>No Groups Added</div>
					)}
					<div className='group-entries'>
						{groupsDetails.map((details, index) => (
							<GroupDetailsEntry
								key={details.id}
								playersInOrg={allParticipants ?? []}
								groupNum={index + 1}
								allGroups={groupsDetails}
								groupDetails={details}
								onUpdate={updateGroup}
								onDelete={deleteGroup}
								onAuthenticate={updateAuthGroups}
								challengeType={challenge.challengeType}
							/>
						))}
					</div>
				</div>
			</section>
			<div className='row' style={{ marginTop: '6rem' }}>
				<Button variant='outlined' onClick={onBackClick}>
					Back
				</Button>
				<Button disabled={!isFormValid} onClick={onNextClick}>
					SAVE
				</Button>
			</div>
			<AuthenticationModal
				isVisible={isModalOpen}
				toggleModal={toggleModal}
				title={
					authGroups.length > 1 ? 'Authenticate Groups' : 'Authenticate Group'
				}
				subTitle={
					authGroups.length > 0 ? authGroups[0].groupName : challenge.name
				}
				groups={authGroups}
				challengeDate={challenge.date}
			/>
		</section>
	);
};

export default ChallengeDetails;
