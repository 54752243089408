import { useEffect, useMemo, useState } from 'react';

import { alphaNumericSort, changeDateFormat } from '../../../misc/helper-util';
import {
	createDeleteRequest,
	createGetRequest,
	createPostRequest,
	createPutRequest,
} from '../../api-util';
import { dashboardApi } from '../dashboard-api';
import SlChallengeType from '../schema/SlChallengeType';
import SlGroup from '../schema/SlGroup';
import SlHole from '../schema/SlHole';
import SlResponseArray from '../schema/SlResponseArray';
import SlRound from '../schema/SlRound';
import SlStroke from '../schema/SlStroke';

export const challengeEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		createChallenge: builder.mutation<SlRound, SlRound>({
			query: challenge =>
				createPostRequest(`/event/${challenge.eventId}/round`, challenge),
			invalidatesTags: ['Challenge'],
		}),
		getChallenge: builder.query<
			SlRound,
			{ eventId: number; challengeId: number }
		>({
			query: ({ eventId, challengeId }) =>
				createGetRequest(`/event/${eventId}/round/${challengeId}`),
			providesTags: ['Challenge'],
		}),
		getChallenges: builder.query<SlRound[], number>({
			query: eventId => createGetRequest(`/event/${eventId}/round?size=1000`),
			transformResponse: (response: SlResponseArray<SlRound>) => {
				let rounds = response.content ?? [];
				// update round date format
				rounds = rounds.map(round => ({
					...round,
					date: changeDateFormat(round.date),
				}));
				return rounds.sort(
					(a, b) =>
						// sort by oldest to newest date, then by round name
						new Date(a.date).getTime() - new Date(b.date).getTime() ||
						alphaNumericSort(a.name, b.name)
				);
			},
			providesTags: ['Challenge'],
		}),
		getChallengeTypes: builder.query<SlChallengeType[], void>({
			query: () => createGetRequest('/challenge-types'),
		}),
		updateChallenge: builder.mutation<number, SlRound>({
			query: challenge =>
				createPutRequest(
					`/event/${challenge.eventId}/round/${challenge.id}`,
					challenge
				),
			invalidatesTags: ['Challenge'],
		}),
		deleteChallenge: builder.mutation<
			void,
			{ eventId: number; challengeId: number }
		>({
			query: ({ eventId, challengeId }) =>
				createDeleteRequest(`/event/${eventId}/round/${challengeId}`),
			invalidatesTags: ['Challenge'],
		}),
	}),
});

const challengeStrokeEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		getStrokes: builder.query<
			SlResponseArray<SlStroke>,
			{
				eventId: number;
				challengeId: number;
				pageSize: string;
				pageIndex: number;
			}
		>({
			query: ({ eventId, challengeId, pageSize, pageIndex }) =>
				createGetRequest(
					`/event/${eventId}/round/${challengeId}/strokes?size=${pageSize}&page=${pageIndex}`
				),

			providesTags: ['Stroke'],
		}),
		deleteStroke: builder.mutation<
			void,
			{ eventId: number; challengeId: number; strokeId: number }
		>({
			query: ({ eventId, challengeId, strokeId }) =>
				createDeleteRequest(
					`/event/${eventId}/round/${challengeId}/stroke/${strokeId}`
				),
			invalidatesTags: ['Stroke'],
		}),
	}),
});

const challengeHoleEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		addHole: builder.mutation<
			{ id: number },
			{ challengeId: number; hole: SlHole }
		>({
			query: ({ challengeId, hole }) =>
				createPostRequest(`/round/${challengeId}/hole`, hole),
		}),
		getHole: builder.query<SlHole[], { challengeId: number; holeId: number }>({
			query: ({ challengeId, holeId }) =>
				createGetRequest(`/round/${challengeId}/hole/${holeId}`),
		}),
		getHoles: builder.query<SlHole[], number>({
			query: challengeId =>
				createGetRequest(`/round/${challengeId}/hole?size=1000`),
			transformResponse: (response: SlResponseArray<SlHole>) =>
				response.content ?? ([] as SlHole[]),
		}),
		updateHole: builder.mutation<void, { challengeId: number; hole: SlHole }>({
			query: ({ challengeId, hole }) =>
				createPutRequest(`/round/${challengeId}/hole/${hole.id}`, hole),
		}),
		deleteHole: builder.mutation<void, { challengeId: number; holeId: number }>(
			{
				query: ({ challengeId, holeId }) =>
					createDeleteRequest(`/round/${challengeId}/hole/${holeId}`),
			}
		),
	}),
});

const challengeGroupEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		addGroup: builder.mutation<
			{ id: number },
			{ challengeId: number; group: SlGroup }
		>({
			query: ({ challengeId, group }) =>
				createPostRequest(`/round/${challengeId}/group`, group),
		}),
		getGroups: builder.query<SlGroup[], number>({
			query: challengeId =>
				createGetRequest(`/round/${challengeId}/group?size=1000`),

			transformResponse: (response: SlResponseArray<SlGroup>) => {
				const groups = response.content ?? ([] as SlGroup[]);
				return groups.sort((a, b) => alphaNumericSort(a.name, b.name));
			},
		}),
		updateGroup: builder.mutation<
			void,
			{ challengeId: number; group: SlGroup }
		>({
			query: ({ challengeId, group }) =>
				createPutRequest(`/round/${challengeId}/group/${group.id}`, group),
		}),
		deleteGroup: builder.mutation<
			void,
			{ challengeId: number; groupId: number }
		>({
			query: ({ challengeId, groupId }) =>
				createDeleteRequest(`/round/${challengeId}/group/${groupId}`),
		}),
		removeGroups: builder.mutation<
			void,
			{ challengeId: number; group: SlGroup }
		>({
			query: ({ challengeId, group }) =>
				createPutRequest(`/round/${challengeId}/group/${group.id}`, group),
		}),
	}),
});

// TODO - Make new backend endpoint that grabs all holes when given an array of round ids
export const useGetHolesforRounds = (rounds?: SlRound[]) => {
	const roundIds = useMemo(() => rounds?.map(x => x.id), [rounds]);
	const [fetchedHoles, setFetchedHoles] = useState<SlHole[] | undefined>(
		undefined
	);
	const [getHoles] = challengeHoleEndpoints.useLazyGetHolesQuery();

	const fetchHoles = async () => {
		const holePromises: Promise<void>[] = [];
		let holes: SlHole[] = [];

		if (roundIds) {
			roundIds.forEach(async id => {
				holePromises.push(
					getHoles(id)
						.unwrap()
						.then(value => {
							value.forEach(newHole => {
								if (!holes.some(x => x.id === newHole.id)) {
									holes = [...holes, newHole];
								}
							});
						})
				);
			});

			await Promise.all(holePromises);

			setFetchedHoles(holes);
		}
	};

	useEffect(() => {
		fetchHoles();
	}, [roundIds]);

	return fetchedHoles;
};

// TODO - Make new backend endpoint that grabs all groups when given an array of round ids
export const useGetGroupsforRounds = (rounds?: SlRound[]) => {
	const roundIds = useMemo(() => rounds?.map(x => x.id), [rounds]);
	const [fetchedGroups, setFetchedGroups] = useState<SlGroup[] | undefined>(
		undefined
	);
	const [getGroups] = challengeGroupEndpoints.useLazyGetGroupsQuery();

	const fetchGroups = async () => {
		const groupPromises: Promise<void>[] = [];
		let groups: SlGroup[] = [];

		if (roundIds) {
			roundIds.forEach(async id => {
				groupPromises.push(
					getGroups(id)
						.unwrap()
						.then(value => {
							value.forEach(newGroup => {
								if (!groups.some(x => x.id === newGroup.id)) {
									groups = [...groups, newGroup];
								}
							});
						})
				);
			});

			await Promise.all(groupPromises);

			setFetchedGroups(groups);
		}
	};

	useEffect(() => {
		fetchGroups();
	}, [roundIds]);

	return fetchedGroups;
};

export const {
	useCreateChallengeMutation,
	useGetChallengeQuery,
	useLazyGetChallengeQuery,
	useGetChallengesQuery,
	useGetChallengeTypesQuery,
	useUpdateChallengeMutation,
	useDeleteChallengeMutation,
	useAddHoleMutation,
	useDeleteHoleMutation,
	useUpdateHoleMutation,
	useAddGroupMutation,
	useDeleteGroupMutation,
	useUpdateGroupMutation,
	useLazyGetGroupsQuery,
	useRemoveGroupsMutation,
	useGetHolesQuery,
	useLazyGetHolesQuery,
	useLazyGetStrokesQuery,
	useDeleteStrokeMutation,
} = {
	...challengeEndpoints,
	...challengeHoleEndpoints,
	...challengeGroupEndpoints,
	...challengeStrokeEndpoints,
};
