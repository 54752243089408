import { useCallback, useEffect, useRef, useState } from 'react';

import DropdownOptionsInterface from './DropdownOptionsInterface';

interface Props {
	optionsList: DropdownOptionsInterface[];
	openDropdownHandler?: () => void;
}

const useDropdownInput = ({ optionsList, openDropdownHandler }: Props) => {
	const [isDropdownClicked, setIsDropdownClicked] = useState(false);
	const [filteredList, setFilteredList] =
		useState<DropdownOptionsInterface[]>(optionsList);
	const [searchBarValue, setSearchBarValue] = useState<string>('');

	const wrapperRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const updateFilteredList = useCallback(
		(value: string) => {
			setFilteredList(
				value !== ''
					? optionsList.filter(x =>
							x.displayText.toLowerCase().includes(value.toLowerCase())
					  )
					: optionsList
			);
		},
		[optionsList, setFilteredList]
	);

	useEffect(() => {
		updateFilteredList(searchBarValue);
	}, [optionsList]);

	useEffect(() => {
		const toggleDropdown = (e: MouseEvent) => {
			// if button that opens and closes dropdown is clicked, then toggle open/close value
			if (buttonRef.current?.contains(e.target as Node)) {
				setIsDropdownClicked(prev => !prev);
			}
			// if div wrapper (anywhere inside the dropdown) is clicked, keep it open
			else if (wrapperRef.current?.contains(e.target as Node)) {
				setIsDropdownClicked(true);
			}
			// close dropdown if anywhere else on the page is clicked
			else {
				setIsDropdownClicked(false);
			}
		};
		document.addEventListener('mousedown', toggleDropdown);
		return () => document.removeEventListener('mousedown', toggleDropdown);
	}, [wrapperRef]);

	useEffect(() => {
		if (!isDropdownClicked) {
			setSearchBarValue('');
			setFilteredList(optionsList);
		}
	}, [isDropdownClicked]);

	return {
		isDropdownClicked,
		setIsDropdownClicked,
		searchBarValue,
		filteredList,
		wrapperRef,
		buttonRef,
		handlers: {
			handleSearch: (value: string) => {
				setSearchBarValue(value);
				updateFilteredList(value);
			},
		},
	};
};

export default useDropdownInput;
