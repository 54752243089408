import React, { useEffect, useState } from 'react';

import { CheckMarkSvg, DeleteXSvg } from '../../../../assets';
import GroupDetails from '../../../pages/ChallengeDetails/GroupDetails';
import { ChallengeData } from '../../../pages/EventDetails/useEventDetails';
import Button from '../../forms/Button/Button';
import FormTitle from '../FormTitle/FormTitle';
import ChallengeAuthentication from './ChallengeAuthentication';
import GroupAuthentication from './GroupAuthentication';
import Modal from './Modal';
import useModal from './useModal';

interface Props {
	title: string;
	subTitle: string;
	isVisible: boolean;
	toggleModal: () => void;
	groups?: GroupDetails[];
	rounds?: ChallengeData[];
	challengeDate?: string;
	customStyles?: { [key: string]: string };
}

const AuthenticationModal = (props: Props) => {
	const {
		title,
		subTitle,
		isVisible,
		toggleModal,
		groups,
		rounds,
		challengeDate,
		customStyles,
	} = props;

	const { isModalOpen, toggleModal: newCodeModalToggle } = useModal();

	const [deviceLimit, setDeviceLimit] = useState<string>('');
	const [showDeviceLimit, setShowDeviceLimit] = useState<boolean>(false);
	const [selectedSections, setSelectedSections] = useState<number[]>([]);
	const [isDelete, setIsDelete] = useState<boolean>(false);

	const extraButtonMargin =
		(groups?.length ?? 0) > 1 || (rounds?.length ?? 0) > 1;

	// make sure it's a valid positive integer
	const deviceLimitButtonDisabled =
		deviceLimit.includes('-') ||
		deviceLimit.includes('.') ||
		Number.isNaN(Number(deviceLimit)) ||
		deviceLimit === '';

	const closeModal = () => {
		toggleModal();
		setShowDeviceLimit(false);
		setSelectedSections([]);
		setDeviceLimit('');
	};

	const openGenerateNewCodeModal = (id: number) => {
		setSelectedSections([id]);
		newCodeModalToggle();
	};

	useEffect(() => {
		if (!isModalOpen || !showDeviceLimit) {
			setIsDelete(false);
		}
	}, [isModalOpen, showDeviceLimit]);

	return (
		<>
			<Modal
				isVisible={isVisible}
				toggleModal={closeModal}
				customStyles={customStyles}
			>
				<h3 className='m-0'>{subTitle}</h3>
				<FormTitle title={title} customStyles={{ margin: '0' }} />
				<div
					className='manage-device-limit'
					style={{
						margin: `0 ${extraButtonMargin ? '1.5rem' : '0'} 1rem auto`,
					}}
				>
					{!showDeviceLimit && (
						<button
							type='button'
							className='transparent bebas-large primary-blue p-0 w-100'
							onClick={() => setShowDeviceLimit(prev => !prev)}
						>
							Manage Device Limit
						</button>
					)}
					{showDeviceLimit && (
						<div className='manage-device-limit__input flex-row justify-between'>
							<input
								id='device-limit'
								value={deviceLimit}
								onChange={e => setDeviceLimit(e.target.value)}
							/>
							<button
								type='button'
								className='p-100 flex-row align-center justify-center'
								onClick={() => {
									setShowDeviceLimit(prev => !prev);
									setIsDelete(true);

									if (groups) setSelectedSections(groups.map(x => x.id));
									else if (rounds) setSelectedSections(rounds.map(x => x.id));
								}}
								disabled={deviceLimitButtonDisabled}
								title={
									deviceLimitButtonDisabled
										? 'Please enter a valid positive integer'
										: ''
								}
							>
								<img
									src={CheckMarkSvg}
									alt='checkmark to submit new device limit'
								/>
							</button>
							<button
								type='button'
								className='cancel-device p-100 flex-row align-center justify-center'
								onClick={() => setShowDeviceLimit(prev => !prev)}
							>
								<img src={DeleteXSvg} alt='cancel new device limit' />
							</button>
						</div>
					)}
				</div>
				<div className='auth-section flex-col gap-2'>
					{groups?.map(group => (
						<GroupAuthentication
							group={group}
							challengeDate={challengeDate}
							newCodeModalToggle={openGenerateNewCodeModal}
							key={group.id}
							refetchSection={isDelete && selectedSections.includes(group.id)}
							deviceLimit={deviceLimit}
						/>
					))}
					{rounds?.map(round => (
						<ChallengeAuthentication
							challenge={round}
							newCodeModalToggle={openGenerateNewCodeModal}
							key={round.id}
							refetchSection={isDelete && selectedSections.includes(round.id)}
							deviceLimit={deviceLimit}
						/>
					))}
				</div>
			</Modal>
			{/* Generate New Code Modal  */}
			<Modal
				isVisible={isModalOpen}
				customStyles={{
					maxWidth: '50rem',
				}}
			>
				<FormTitle
					title='Generate New Code?'
					customStyles={{ marginTop: '0' }}
				/>
				<div className='flex-col gap-2'>
					<p className='m-0'>
						Once generated, all devices using the current code will need to
						authenticate using the new code.
					</p>
				</div>

				<div className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={newCodeModalToggle}>
						Cancel
					</Button>
					<Button
						onClick={() => {
							setIsDelete(true);
							newCodeModalToggle();
						}}
					>
						Continue
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default AuthenticationModal;
