import React from 'react';

import { CopySquaresSvg, NewCodeSvg } from '../../../../assets';
import { ChallengeData } from '../../../pages/EventDetails/useEventDetails';
import IconButton from '../../forms/IconButton/IconButton';
import Loader from '../Loader/Loader';
import useAuthenticationModal from './useAuthenticationModal';

interface Props {
	challenge: ChallengeData;
	newCodeModalToggle: (id: number) => void;
	refetchSection: boolean;
	deviceLimit: string;
}

const ChallengeAuthentication = ({
	challenge,
	newCodeModalToggle,
	refetchSection,
	deviceLimit,
}: Props) => {
	const { qrCode, devices, error, isLoading, copyLink } =
		useAuthenticationModal(
			true,
			challenge.date,
			challenge.id,
			deviceLimit,
			refetchSection
		);

	return (
		<div className='auth-section__rounds flex-row gap-2'>
			<div
				style={{ width: '66%', position: 'relative' }}
				className='flex-col gap-2'
			>
				<h3 className='m-0'>{challenge.challenge}</h3>
				<span>{challenge.date}</span>
				<span>{challenge.holeCount} Hole</span>
				<span>{challenge.playerCount} Players</span>
				{devices && !isLoading && (
					<span className='auth-devices'>
						Devices in Use:{' '}
						<b>
							{devices.devicesRegistered}/{devices.maxDevices}
						</b>
					</span>
				)}
			</div>
			<div
				style={{ width: '33%', alignItems: 'end' }}
				className='flex-col gap-2'
			>
				<div className='qr-code ml-auto'>
					{isLoading && <Loader customStyles={{ marginLeft: 'auto' }} />}
					{qrCode && !isLoading && !error && (
						<img className='qr-code' src={qrCode} alt='QR Code' />
					)}
					{error && !isLoading && <div className='qr-code__error'>{error}</div>}
				</div>
				<IconButton icon={CopySquaresSvg} title='Copy Link' onClick={copyLink}>
					<span className='bebas-large primary-blue'>Copy Link</span>
				</IconButton>
				<IconButton
					icon={NewCodeSvg}
					title='Generate New Code Link'
					onClick={() => newCodeModalToggle(challenge.id)}
				>
					<span className='bebas-large primary-blue'>Generate New Code</span>
				</IconButton>
			</div>
		</div>
	);
};

export default ChallengeAuthentication;
