import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import { useLazyGetEventsQuery } from '../../../api/dashboard/endpoints/event-endpoints';
import SlEventOverview from '../../../api/dashboard/schema/SlEventOverview';
import { DeleteXSvg } from '../../../assets';
import { setToastError } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import useModal from '../../shared/components/Modal/useModal';
import { TableColumn } from '../../shared/components/ScrollableTable/ScrollableTable';
import { EventFilterType } from '../../shared/types/enums';

const columns: TableColumn[] = [
	{ id: 'name', label: 'Name' },
	{ id: 'startDate', label: 'Start Date' },
	{ id: 'endDate', label: 'End Date' },
	{ id: 'roundCount', label: 'Round' },
	{ id: 'groupCount', label: 'Groups' },
	{ id: 'playerCount', label: 'Players' },
	{
		id: 'delete',
		label: 'Delete',
		styles: { width: '5%', textAlign: 'center' },
	},
];

interface FilterOptionType {
	text: string;
	type: EventFilterType;
}

const filterOptionList: FilterOptionType[] = [
	{
		text: 'All Events',
		type: EventFilterType.all,
	},
	{
		text: 'Active Events',
		type: EventFilterType.active,
	},
	{
		text: 'Upcoming Events',
		type: EventFilterType.upcoming,
	},
	{
		text: 'Past Events',
		type: EventFilterType.past,
	},
];

const useListEvents = () => {
	const orgId = +SessionStorageService.orgId;
	const [getEvents, { data, error, isFetching: isLoading }] =
		useLazyGetEventsQuery();

	const { isModalOpen, toggleModal } = useModal();
	const dispatch = useAppDispatch();

	const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
	const [filterValue, setFilterValue] = useState<FilterOptionType>(
		filterOptionList[0]
	);
	const [selectedEvent, setSelectedEvent] = useState<SlEventOverview>();

	const [pageIndex, setPageIndex] = useState<number>(0);
	const [pageSize, setPageSize] = useState<string>('20');
	const [totalElements, setTotalElements] = useState<number>(0);

	useEffect(() => {
		if (error && 'data' in error) {
			dispatch(
				setToastError({
					message: 'There was an error fetching events',
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					errors: [(error.data as any).message],
				})
			);
		}
	}, [error]);

	const tableData = useMemo(() => {
		setTotalElements(data?.totalElements ?? 0);

		return data?.content.map(event => ({
			id: event.eventId,
			name: (
				<Link
					className='transparent gibson-normal primary-blue p-0'
					to={`/events/${event.eventId}`}
				>
					{event.eventName}
				</Link>
			),
			startDate: event.eventStartDate,
			endDate: event.eventEndDate,
			roundCount: event.numberOfRounds,
			groupCount: event.numberOfGroups,
			playerCount: event.numberOfPlayers,
			delete: (
				<button
					type='button'
					className='transparent'
					onClick={() => {
						toggleModal();
						setSelectedEvent(event);
					}}
				>
					<img src={DeleteXSvg} alt={`Delete event${event.eventName}`} />
				</button>
			),
		}));
	}, [data]);

	const filterOptions = useMemo(
		() =>
			filterOptionList.map(option => ({
				displayText: option.text,
				onClickHandler: () => {
					setFilterValue(option);
					setIsFilterVisible(false);
					setPageIndex(0);
				},
			})),
		[filterOptionList]
	);

	useEffect(() => {
		getEvents({
			orgId,
			pageSize,
			pageIndex,
			status: filterValue.type,
		}).unwrap();
	}, [pageSize, pageIndex, filterValue]);

	const onPageChange = (pageNumber: number, elementsPerPage: string) => {
		setPageIndex(pageNumber);
		setPageSize(elementsPerPage);
	};

	return {
		columns,
		data: tableData,
		isLoading,
		isFilterVisible,
		setIsFilterVisible,
		filterOptions,
		filterValue,
		selectedEvent,
		isModalOpen,
		toggleModal,
		onPageChange,
		pageSize,
		pageIndex,
		totalElements,
	};
};

export default useListEvents;
