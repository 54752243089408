/* eslint-disable no-use-before-define */
import { useRef } from 'react';

import {
	useAddGroupMutation,
	useAddHoleMutation,
	useCreateChallengeMutation,
	useDeleteGroupMutation,
	useDeleteHoleMutation,
	useUpdateChallengeMutation,
	useUpdateGroupMutation,
	useUpdateHoleMutation,
} from '../../../api/dashboard/endpoints/challenge-endpoints';
import {
	useAddParticipantToGroupMutation,
	useRemoveParticipantFromGroupMutation,
} from '../../../api/dashboard/endpoints/group-endpoints';
import SlRound from '../../../api/dashboard/schema/SlRound';
import GroupDetails from './GroupDetails';
import HoleDetails from './HoleDetails';

const useSubmitChallengeDetails = () => {
	const [deleteGroup] = useDeleteGroupMutation();
	const [addGroup] = useAddGroupMutation();
	const [updateGroup] = useUpdateGroupMutation();

	const [addParticipantToGroup] = useAddParticipantToGroupMutation();
	const [removeParticipantFromGroup] = useRemoveParticipantFromGroupMutation();

	const [deleteHole] = useDeleteHoleMutation();
	const [addHole] = useAddHoleMutation();
	const [updateHole] = useUpdateHoleMutation();

	const [updateChallenge] = useUpdateChallengeMutation();
	const [createChallenge] = useCreateChallengeMutation();

	const isSubmitLoading = useRef<boolean>(false);

	const submitChallengeDetails = async (
		challenge: SlRound,
		originalChallenge: SlRound,
		originalGroups: GroupDetails[],
		groupDetails: GroupDetails[],
		originalHoles: HoleDetails[],
		holeDetails: HoleDetails[]
	) => {
		isSubmitLoading.current = true;

		const [challengeErrors, challengeId] = await handleChallengeSubmit(
			challenge,
			originalChallenge
		);
		const groupErrors = await handleGroupsSubmit(
			originalGroups,
			groupDetails,
			challengeId
		);
		const holeErrors = await handleHolesSubmit(
			originalHoles,
			holeDetails,
			challengeId
		);

		const errors = [...challengeErrors, ...groupErrors, ...holeErrors];

		isSubmitLoading.current = false;

		return {
			success: errors.length === 0,
			errors,
		};
	};

	return { submitChallengeDetails, isSubmitLoading: isSubmitLoading.current };

	async function handleChallengeSubmit(
		challenge: SlRound,
		originalChallenge: SlRound
	): Promise<[string[], number]> {
		const errors = [];
		let newChallenge: SlRound | null = null;
		const isNewChallenge = challenge.id < 0;
		try {
			if (isNewChallenge) {
				newChallenge = await createChallenge(challenge).unwrap();
			} else if (
				JSON.stringify(originalChallenge) !== JSON.stringify(challenge)
			) {
				await updateChallenge(challenge).unwrap();
			}
		} catch {
			const action = isNewChallenge ? 'create' : 'update';
			if (challenge.name !== originalChallenge.name)
				errors.push(`Challenge name failed to ${action}.`);
			if (challenge.date !== originalChallenge.date)
				errors.push(`Challenge date failed to ${action}.`);
			if (challenge.description !== originalChallenge.description)
				errors.push(`Challenge description failed to ${action}.`);
		}
		return [errors, newChallenge?.id ?? challenge.id];
	}

	async function handleGroupsSubmit(
		originalGroups: GroupDetails[],
		groupDetails: GroupDetails[],
		challengeId: number
	): Promise<string[]> {
		const promises: Promise<void | number | { id: number }>[] = [];
		const playerGroupPromises: Promise<void | number | { id: number }>[] = [];
		const errors: string[] = [];

		// CREATE GROUP
		groupDetails
			.filter(x => x.id < 0)
			.forEach(async group => {
				try {
					const addGroupPromise = addGroup({
						challengeId,
						group: {
							id: group.id,
							roundId: challengeId,
							name: group.groupName,
						},
					}).unwrap();

					promises.push(addGroupPromise);

					const { id: newGroupId } = await addGroupPromise;

					// ADD PLAYERS TO NEW GROUP
					group.playerIds.forEach(playerId => {
						playerGroupPromises.push(
							addParticipantToGroup({
								groupId: newGroupId,
								groupParticipant: {
									groupId: newGroupId,
									participantId: playerId,
								},
							})
								.unwrap()
								.catch(error =>
									errors.push(
										`Failed to add participant with ID ${playerId} to ${group.groupName}`
									)
								)
						);
					});
				} catch (error) {
					errors.push(`Failed to create ${group.groupName}`);
				}
			});

		// UPDATE GROUP
		groupDetails
			.filter(x => x.id > -1)
			.forEach(group => {
				const oldGroupString = JSON.stringify(
					originalGroups.find(x => x.id === group.id)
				);

				if (oldGroupString !== JSON.stringify(group)) {
					promises.push(
						updateGroup({
							challengeId,
							group: {
								id: group.id,
								roundId: challengeId,
								name: group.groupName,
							},
						})
							.unwrap()
							.catch(error =>
								errors.push(`Failed to update ${group.groupName}`)
							)
					);

					// REMOVE PLAYERS FROM GROUP
					const originalGroup = originalGroups.find(x => x.id === group.id);
					if (originalGroup) {
						originalGroup.playerIds.forEach(id => {
							if (!group.playerIds.includes(id)) {
								playerGroupPromises.push(
									removeParticipantFromGroup({
										groupId: group.id,
										groupParticipant: {
											groupId: group.id,
											participantId: id,
										},
									})
										.unwrap()
										.catch(error =>
											errors.push(
												`Failed to remove player ${id} from ${group.groupName}`
											)
										)
								);
							}
						});

						// ADD PLAYERS TO GROUP
						group.playerIds.forEach(id => {
							if (!originalGroup.playerIds.includes(id)) {
								playerGroupPromises.push(
									addParticipantToGroup({
										groupId: group.id,
										groupParticipant: {
											groupId: group.id,
											participantId: id,
										},
									})
										.unwrap()
										.catch(error =>
											errors.push(
												`Failed to add player ${id} from ${group.groupName}`
											)
										)
								);
							}
						});
					}
				}
			});

		// DELETE GROUP
		const newIds = groupDetails.map(y => y.id);
		originalGroups
			.filter(x => !newIds.includes(x.id))
			.forEach(group => {
				promises.push(
					deleteGroup({
						challengeId,
						groupId: group.id,
					})
						.unwrap()
						.catch(error => errors.push(`Failed to delete ${group.groupName}`))
				);
			});

		await Promise.allSettled(promises);
		await Promise.allSettled(playerGroupPromises);

		return errors;
	}

	async function handleHolesSubmit(
		originalHoles: HoleDetails[],
		holeDetails: HoleDetails[],
		challengeId: number
	) {
		const promises: Promise<void | number | { id: number }>[] = [];
		const errors: string[] = [];
		// CREATE HOLE
		holeDetails
			.filter(x => !originalHoles.map(y => y.holeNum).includes(x.holeNum))
			.forEach(hole => {
				promises.push(
					addHole({
						challengeId,
						hole: {
							id: hole.id,
							roundId: challengeId,
							par: hole.par,
							teeLocations: hole.teeLocations.map(tee => ({
								...tee,
								location: {
									latitude: +tee.location.latitude,
									longitude: +tee.location.longitude,
								},
							})),
							pinLocation: {
								latitude: +hole.pinLocation.latitude,
								longitude: +hole.pinLocation.longitude,
							},
							holeNumber: +hole.holeNum,
						},
					})
						.unwrap()
						.catch(error =>
							errors.push(`Failed to create hole number ${hole.holeNum}`)
						)
				);
			});

		// UPDATE HOLE
		holeDetails
			.filter(x => originalHoles.map(y => y.holeNum).includes(x.holeNum))
			.forEach(hole => {
				const oldHole = originalHoles.find(x => x.holeNum === hole.holeNum);
				const oldHolestring = JSON.stringify(oldHole);
				if (oldHolestring !== JSON.stringify(hole) && oldHole) {
					promises.push(
						updateHole({
							challengeId,
							hole: {
								id: oldHole.id,
								roundId: challengeId,
								par: hole.par,
								teeLocations: hole.teeLocations.map(tee => ({
									...tee,
									location: {
										latitude: +tee.location.latitude,
										longitude: +tee.location.longitude,
									},
								})),
								pinLocation: {
									latitude: +hole.pinLocation.latitude,
									longitude: +hole.pinLocation.longitude,
								},
								holeNumber: +hole.holeNum,
							},
						})
							.unwrap()
							.catch(error =>
								errors.push(`Failed to update hole number ${hole.holeNum}`)
							)
					);
				}
			});

		// DELETE HOLE
		const newIds = holeDetails.map(y => y.holeNum);
		originalHoles
			.filter(x => !newIds.includes(x.holeNum))
			.forEach(hole => {
				promises.push(
					deleteHole({
						challengeId,
						holeId: hole.id,
					})
						.unwrap()
						.catch(error =>
							errors.push(`Failed to delete hole number ${hole.holeNum}`)
						)
				);
			});

		await Promise.allSettled(promises);

		return errors;
	}
};

export default useSubmitChallengeDetails;
