import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import SlParticipant from '../api/dashboard/schema/SlParticipant';
import { SlToast } from '../features/shared/components/Toast/Toast';

interface State {
	toast: SlToast;
	allPlayersInOrg: SlParticipant[];
}

const initialState: State = {
	toast: {
		isVisible: false,
		isSuccess: true,
		message: '',
		subMessages: [],
	},
	allPlayersInOrg: [],
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setToastSuccess: (state, action: PayloadAction<string>) => {
			state.toast = {
				isVisible: true,
				isSuccess: true,
				message: action.payload,
				subMessages: [],
				showIndefinitely: false,
			};
		},
		setToastError: (
			state,
			action: PayloadAction<{
				message: string;
				errors: string[];
				showIndefinitely?: boolean;
			}>
		) => {
			state.toast = {
				isVisible: true,
				isSuccess: false,
				message: action.payload.message,
				subMessages: action.payload.errors,
				showIndefinitely: action.payload.showIndefinitely,
			};
		},
		resetToast: state => {
			state.toast.isVisible = false;
		},
		setPlayersInOrg: (state, action: PayloadAction<SlParticipant[]>) => {
			state.allPlayersInOrg = action.payload;
		},
		updatePlayerInOrg: (state, action: PayloadAction<SlParticipant>) => {
			const index = state.allPlayersInOrg.findIndex(
				x => x.id === action.payload.id
			);
			state.allPlayersInOrg[index] = action.payload;
		},
		addPlayerToOrg: (state, action: PayloadAction<SlParticipant>) => {
			state.allPlayersInOrg = [...state.allPlayersInOrg, action.payload];
		},
	},
});

export const {
	setToastError,
	setToastSuccess,
	resetToast,
	setPlayersInOrg,
	updatePlayerInOrg,
	addPlayerToOrg,
} = appSlice.actions;
