import React from 'react';

import './InfoIcon.scss';

interface Props {
	onClick: () => void;
	classes?: string;
	customStyles?: { [key: string]: string };
	hoverElement?: JSX.Element;
	hoverStyles?: { [key: string]: string };
}

const InfoIcon = ({
	onClick,
	classes,
	customStyles,
	hoverElement,
	hoverStyles,
}: Props) => (
	<button
		type='button'
		className={`info-icon ${classes} m-0 p-0 gibson-normal flex-row align-center justify-center`}
		style={customStyles}
		onClick={onClick}
	>
		<span>i</span>
		{hoverElement && (
			<div className='hover-text' style={hoverStyles}>
				{hoverElement}
				<div />
			</div>
		)}
	</button>
);

export default InfoIcon;
