import {
	createDeleteRequest,
	createGetRequest,
	createPostRequest,
	createPutRequest,
} from '../../api-util';
import { dashboardApi } from '../dashboard-api';
import SlEvent from '../schema/SlEvent';
import SlEventOverview from '../schema/SlEventOverview';
import SlMarketing from '../schema/SlMarketing';
import { SlMarketingModal } from '../schema/SlMarketingModal';
import { SlMarketingSponsorship } from '../schema/SlMarketingSponsorship';
import SlResponseArray from '../schema/SlResponseArray';

export const eventEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		getEvent: builder.query<SlEvent, { orgId: number; eventId: number }>({
			query: ({ orgId, eventId }) =>
				createGetRequest(`/organization/${orgId}/event/${eventId}`),
			providesTags: ['Event', 'Challenge', 'Group', 'Player'],
		}),
		getEvents: builder.query<
			SlResponseArray<SlEventOverview>,
			{ orgId: number; pageSize: string; pageIndex: number; status: string }
		>({
			query: ({ orgId, pageSize, pageIndex, status }) =>
				createGetRequest(
					`/organization/${orgId}/event/overview?status=${status}&size=${pageSize}&page=${pageIndex}`
				),
			providesTags: ['Event', 'Challenge', 'Group', 'Player'],
		}),
		createEvent: builder.mutation<SlEvent, SlEvent>({
			query: event =>
				createPostRequest(`/organization/${event.orgId}/event`, event),
			invalidatesTags: ['Event'],
		}),
		updateEvent: builder.mutation<SlEvent, SlEvent>({
			query: event =>
				createPutRequest(
					`/organization/${event.orgId}/event/${event.id}`,
					event
				),
			invalidatesTags: ['Event'],
		}),
		deleteEvent: builder.mutation<void, { orgId: number; eventId: number }>({
			query: ({ orgId, eventId }) =>
				createDeleteRequest(`/organization/${orgId}/event/${eventId}`),
			invalidatesTags: ['Event'],
		}),
	}),
});

const marketingEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		getMarketingInfo: builder.query<
			SlMarketing,
			{ orgId: number; eventId: number }
		>({
			query: ({ orgId, eventId }) =>
				createGetRequest(`/organization/${orgId}/event/${eventId}/marketing`),
			providesTags: ['Marketing'],
		}),
		createMarketingInfo: builder.mutation<
			SlMarketing,
			{ orgId: number; marketing: SlMarketing }
		>({
			query: ({ orgId, marketing }) =>
				createPostRequest(
					`/organization/${orgId}/event/${marketing.eventId}/marketing`,
					marketing
				),
			invalidatesTags: ['Marketing'],
		}),
		updateMarketingInfo: builder.mutation<
			SlMarketing,
			{ orgId: number; marketing: SlMarketing }
		>({
			query: ({ orgId, marketing }) =>
				createPutRequest(
					`/organization/${orgId}/event/${marketing.eventId}/marketing`,
					marketing
				),
			invalidatesTags: ['Marketing'],
		}),
		deleteMarketingInfo: builder.mutation<
			void,
			{ orgId: number; eventId: number; marketingInfoId: number }
		>({
			query: ({ orgId, eventId, marketingInfoId }) =>
				createDeleteRequest(
					`/organization/${orgId}/event/${eventId}/marketing/${marketingInfoId}`
				),
			invalidatesTags: ['Marketing'],
		}),
		getMarketingModalInfo: builder.query<
			SlMarketingModal,
			{ orgId: number; eventId: number }
		>({
			query: ({ orgId, eventId }) =>
				createGetRequest(
					`/organization/${orgId}/event/${eventId}/marketing/bonus`
				),
			providesTags: ['Marketing'],
		}),
		createMarketingModalInfo: builder.mutation<
			SlMarketingModal,
			{ orgId: number; marketingModal: SlMarketingModal }
		>({
			query: ({ orgId, marketingModal }) =>
				createPostRequest(
					`/organization/${orgId}/event/${marketingModal.eventId}/marketing/bonus`,
					marketingModal
				),
			invalidatesTags: ['Marketing'],
		}),
		updateMarketingModalInfo: builder.mutation<
			SlMarketingModal,
			{ orgId: number; marketingModal: SlMarketingModal }
		>({
			query: ({ orgId, marketingModal }) =>
				createPutRequest(
					`/organization/${orgId}/event/${marketingModal.eventId}/marketing/bonus`,
					marketingModal
				),
			invalidatesTags: ['Marketing'],
		}),
		deleteMarketingModalInfo: builder.mutation<
			void,
			{ orgId: number; eventId: number; marketingInfoModalId: number }
		>({
			query: ({ orgId, eventId, marketingInfoModalId }) =>
				createDeleteRequest(
					`/organization/${orgId}/event/${eventId}/marketing/bonus/${marketingInfoModalId}`
				),
			invalidatesTags: ['Marketing'],
		}),
		getMarketingSponsorship: builder.query<
			SlMarketingSponsorship,
			{ orgId: number; eventId: number }
		>({
			query: ({ orgId, eventId }) =>
				createGetRequest(`/organization/${orgId}/event/${eventId}/sponsorship`),
			transformResponse: (response: SlMarketingSponsorship[]) =>
				// API returns a list of sponsorships for an event, but for now, we only want them to have one per event
				response[0],
			providesTags: ['Marketing'],
		}),
		createMarketingSponsorship: builder.mutation<
			SlMarketingSponsorship,
			{ orgId: number; sponsorship: SlMarketingSponsorship }
		>({
			query: ({ orgId, sponsorship }) =>
				createPostRequest(
					`/organization/${orgId}/event/${sponsorship.eventId}/sponsorship`,
					sponsorship
				),
			invalidatesTags: ['Marketing'],
		}),
		updateMarketingSponsorship: builder.mutation<
			SlMarketingSponsorship,
			{ orgId: number; sponsorship: SlMarketingSponsorship }
		>({
			query: ({ orgId, sponsorship }) =>
				createPutRequest(
					`/organization/${orgId}/event/${sponsorship.eventId}/sponsorship`,
					sponsorship
				),
			invalidatesTags: ['Marketing'],
		}),
		deleteMarketingSponsorship: builder.mutation<
			void,
			{ orgId: number; eventId: number; marketingSponsorshipId: number }
		>({
			query: ({ orgId, eventId, marketingSponsorshipId }) =>
				createDeleteRequest(
					`/organization/${orgId}/event/${eventId}/sponsorship/${marketingSponsorshipId}`
				),
			invalidatesTags: ['Marketing'],
		}),
	}),
});

export const {
	useLazyGetEventQuery,
	useGetEventQuery,
	useLazyGetEventsQuery,
	useCreateEventMutation,
	useUpdateEventMutation,
	useDeleteEventMutation,
} = eventEndpoints;

export const {
	useGetMarketingInfoQuery,
	useCreateMarketingInfoMutation,
	useUpdateMarketingInfoMutation,
	useDeleteMarketingInfoMutation,
	useGetMarketingModalInfoQuery,
	useCreateMarketingModalInfoMutation,
	useUpdateMarketingModalInfoMutation,
	useDeleteMarketingModalInfoMutation,
	useGetMarketingSponsorshipQuery,
	useCreateMarketingSponsorshipMutation,
	useUpdateMarketingSponsorshipMutation,
	useDeleteMarketingSponsorshipMutation,
} = marketingEndpoints;
