import React, { PropsWithChildren } from 'react';

import './Checkbox.scss';

interface Props extends PropsWithChildren {
	checked?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	customStyles?: { [key: string]: string };
}

const Checkbox = ({ checked, children, onChange, customStyles }: Props) => (
	<div className='checkbox-container' style={customStyles}>
		<button type='button' className='checkbox-button'>
			<input
				type='checkbox'
				className='checkbox'
				onChange={onChange}
				checked={checked}
			/>
		</button>
		<div className='checkbox-description'>{children}</div>
	</div>
);

export default Checkbox;
