import React from 'react';

import './ToggleSwitch.scss';

interface Props {
	selected?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	customStyles?: { [key: string]: string };
}

const ToggleSwitch = ({ selected, onClick, customStyles }: Props) => (
	<button
		className={`toggle-switch flex-row m-0 ${
			selected ? 'toggle-switch__selected' : ''
		}`}
		style={customStyles}
		onClick={onClick}
		type='button'
	>
		<div />
	</button>
);

export default ToggleSwitch;
