import React from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../../api/SessionStorageService';
import { CaptechSportsExperiences, HomeIcon } from '../../../../assets';
import './AppHeader.scss';

const AppHeader = () => {
	const navigate = useNavigate();

	return (
		<section className='app-header w-100 flex-row align-center'>
			<button
				type='button'
				onClick={() => navigate('/home')}
				className='p-0 h-100 transparent'
			>
				<img src={HomeIcon} alt='Admin Dashboard Home Icon' />
			</button>
			<img
				src={CaptechSportsExperiences}
				alt='CapTech Sports Experiences logo'
				style={{ marginLeft: '1.2rem' }}
			/>
			{SessionStorageService.haveMultipleOrgs === 'true' && (
				<button
					type='button'
					className='transparent ml-auto gibson-normal px-2 neutral'
					onClick={() => {
						SessionStorageService.orgId = '-1';
						navigate('/organizations');
					}}
				>
					Sign out of Org
				</button>
			)}
		</section>
	);
};

export default AppHeader;
