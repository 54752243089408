import React from 'react';

import './Button.scss';

export type ButtonVariant = 'outlined' | 'default';

interface Props {
	disabled?: boolean;
	variant?: ButtonVariant;
	type?: 'submit' | 'button' | 'reset' | undefined;
	classes?: string;
}

const Button = ({
	children,
	classes,
	disabled = false,
	variant = 'default',
	type = 'button',
	...rest
}: Props & React.HTMLProps<HTMLButtonElement>) => (
	<button
		className={`btn w-100 bebas-large ${variant} ${
			disabled ? 'disabled' : ''
		} ${classes ?? ''}`}
		disabled={disabled}
		// eslint-disable-next-line react/button-has-type
		type={type}
		{...rest}
	>
		{children}
	</button>
);

export default Button;
