import React, { useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import './App.scss';
import SessionStorageService from './api/SessionStorageService';
import useAuth from './auth/useAuthEffect';
import ChallengeDetails from './features/pages/ChallengeDetails/ChallengeDetails';
import EventDetails from './features/pages/EventDetails/EventDetails';
import ListEvents from './features/pages/ListEvents/ListEvents';
import ListOrganizations from './features/pages/ListOrganizations/ListOrganizations';
import ListStrokes from './features/pages/ListStrokes/ListStrokes';
import ManageEventForm from './features/pages/ManageEventForm/ManageEventForm';
import ManageOrganizationForm from './features/pages/ManageOrganizationForm/ManageOrganizationForm';
import MarketingForm from './features/pages/MarketingForm/MarketingForm';
import MarketingModalForm from './features/pages/MarketingModalForm/MarketingModalForm';
import MarketingSponsorshipForm from './features/pages/MarketingSponsorshipForm/MarketingSponsorshipForm';
import AppHeader from './features/shared/components/AppHeader/AppHeader';
import Home from './features/shared/components/Home/Home';
import Navbar from './features/shared/components/Navbar/Navbar';
import Toast from './features/shared/components/Toast/Toast';
import { useAppSelector } from './redux/store';

const App = () => {
	const { isAuthToken } = useAuth();
	const navigate = useNavigate();
	const divRef = useRef<HTMLDivElement>(null);

	const { toast } = useAppSelector(state => state.app);

	useEffect(() => {
		const relativePath = window.location.pathname;
		if (
			relativePath !== '/' &&
			relativePath !== SessionStorageService.currentPage
		) {
			// if user manually adjusts url string to go to different page, we want to navigate to that page, not go to whatever is stored in session storage
			SessionStorageService.currentPage = relativePath;
		}

		// if session storage doesn't have orgId, navigate home in order to assign one
		if (SessionStorageService.orgId === '-1') {
			navigate('/home');
		}
	}, []);

	if (!isAuthToken) return null;

	// READ : when adding a new route, make sure to go to Navbar component and add link to logic there as well

	return (
		<>
			<AppHeader />
			<Navbar divRef={divRef} />
			<div className='app-content' ref={divRef}>
				<Routes>
					<Route path='/home' element={<Home />} />
					<Route path='/events' element={<ListEvents />} />
					<Route path='/manage-event' element={<ManageEventForm />} />
					<Route path='/manage-event/:eventId' element={<ManageEventForm />} />
					<Route path='/events/:eventId' element={<EventDetails />} />
					<Route
						path='/events/:eventId/marketing'
						element={<MarketingForm />}
					/>
					<Route
						path='/events/:eventId/marketing/modal'
						element={<MarketingModalForm />}
					/>
					<Route
						path='/events/:eventId/marketing/sponsorship'
						element={<MarketingSponsorshipForm />}
					/>
					<Route
						path='/events/:eventId/challenges'
						element={<ChallengeDetails />}
					/>
					<Route
						path='/events/:eventId/challenges/:challengeId'
						element={<ChallengeDetails />}
					/>
					<Route
						path='/events/:eventId/challenges/:challengeId/strokes'
						element={<ListStrokes />}
					/>
					<Route
						path='/manage-organization'
						element={<ManageOrganizationForm />}
					/>
					<Route
						path='/manage-organization/:orgId'
						element={<ManageOrganizationForm />}
					/>
					<Route path='/organizations' element={<ListOrganizations />} />

					<Route
						path='*'
						element={
							<Navigate to={SessionStorageService.currentPage} replace />
						}
					/>
				</Routes>
				<Toast params={toast} />
			</div>
		</>
	);
};

export default App;
