import React, { PropsWithChildren } from 'react';

import './IconButton.scss';

interface Props extends PropsWithChildren {
	onClick: () => void;
	icon?: string;
	title: string;
	classNames?: string;
	disabled?: boolean;
	warningMessage?: string;
}

const IconButton = ({
	classNames,
	onClick,
	icon,
	title,
	disabled,
	warningMessage,
	children,
}: Props) => (
	<button
		className={`icon-button transparent gibson-normal flex-row align-center p-0 ${classNames}`}
		type='button'
		onClick={onClick}
		key={`${icon} for the ${title} card`}
		disabled={disabled}
		title={warningMessage}
	>
		{icon && <img src={icon} alt={`${icon} for the ${title} card`} />}
		{children}
	</button>
);

export default IconButton;
