const SessionStorageService = {
	page: 'currentPage',
	org: 'orgId',
	multipleOrgs: 'haveMultipleOrgs',

	// save current page in storage in order to access it after browser refresh
	get currentPage() {
		return sessionStorage.getItem(this.page) ?? '/home';
	},
	set currentPage(value: string) {
		sessionStorage.setItem(this.page, value);
	},

	// save org id in storage in order to access it after browser refresh
	get orgId() {
		return sessionStorage.getItem(this.org) ?? '-1';
	},
	set orgId(value: string) {
		sessionStorage.setItem(this.org, value);
	},

	get haveMultipleOrgs() {
		return sessionStorage.getItem(this.multipleOrgs) ?? 'false';
	},
	set haveMultipleOrgs(value: string) {
		sessionStorage.setItem(this.multipleOrgs, value);
	},
};

export default SessionStorageService;
