import React from 'react';

import SessionStorageService from '../../../../api/SessionStorageService';
import { useGetAllParticipantsQuery } from '../../../../api/dashboard/endpoints/organization-endpoints';
import { CopySquaresSvg, NewCodeSvg } from '../../../../assets';
import GroupDetails from '../../../pages/ChallengeDetails/GroupDetails';
import IconButton from '../../forms/IconButton/IconButton';
import Loader from '../Loader/Loader';
import useAuthenticationModal from './useAuthenticationModal';

interface Props {
	group: GroupDetails;
	challengeDate: string | undefined;
	newCodeModalToggle: (id: number) => void;
	refetchSection: boolean;
	deviceLimit: string;
}

const GroupAuthentication = ({
	group,
	challengeDate,
	newCodeModalToggle,
	refetchSection,
	deviceLimit,
}: Props) => {
	const { qrCode, devices, error, isLoading, copyLink } =
		useAuthenticationModal(
			false,
			challengeDate ?? new Date().toDateString(),
			group.id,
			deviceLimit,
			refetchSection
		);

	const orgId = +SessionStorageService.orgId;
	const { data: allParticipants } = useGetAllParticipantsQuery(orgId);

	return (
		<div className='auth-section__groups flex-row gap-2'>
			<div
				style={{ width: '66%', position: 'relative' }}
				className='flex-col gap-2'
			>
				<h3 className='m-0'>{group.groupName}</h3>
				<div className='auth-section__groups__player-section flex-row gap-2'>
					{group.playerIds.map(id => {
						const player = allParticipants?.find(x => x.id === id);
						return (
							<span key={id}>
								{player ? `${player.firstName} ${player.lastName}` : ''}
							</span>
						);
					})}
				</div>
				{devices && !isLoading && (
					<span className='auth-devices'>
						Devices in Use:{' '}
						<b>
							{devices.devicesRegistered}/{devices.maxDevices}
						</b>
					</span>
				)}
			</div>
			<div
				style={{ width: '33%', alignItems: 'end' }}
				className='flex-col gap-2'
			>
				<div className='qr-code ml-auto'>
					{isLoading && <Loader customStyles={{ marginLeft: 'auto' }} />}
					{qrCode && !isLoading && !error && (
						<img className='qr-code' src={qrCode} alt='QR Code' />
					)}
					{error && !isLoading && <div className='qr-code__error'>{error}</div>}
				</div>
				<IconButton icon={CopySquaresSvg} title='Copy Link' onClick={copyLink}>
					<span className='bebas-large primary-blue'>Copy Link</span>
				</IconButton>
				<IconButton
					icon={NewCodeSvg}
					title='Generate New Code Link'
					onClick={() => newCodeModalToggle(group.id)}
				>
					<span className='bebas-large primary-blue'>Generate New Code</span>
				</IconButton>
			</div>
		</div>
	);
};

export default GroupAuthentication;
