import React, { useMemo } from 'react';

import { PlusSvg, TrashCanSvg } from '../../../assets';
import { capitalizeFirstLetter } from '../../../misc/helper-util';
import Checkbox from '../../shared/forms/Checkbox/Checkbox';
import FormControl from '../../shared/forms/FormControl/FormControl';
import IconButton from '../../shared/forms/IconButton/IconButton';
import Select from '../../shared/forms/Select/Select';
import { ChallengeType, TeeType } from '../../shared/types/enums';
import HoleDetails from './HoleDetails';
import './HoleDetailsEntry.scss';

interface Props {
	details: HoleDetails;
	onUpdate: (details: HoleDetails) => void;
	onDelete: (id: number) => void;
	disabledHoleNumbers: string[];
	challengeType: ChallengeType;
}

const HoleDetailsEntry = ({
	details,
	onUpdate,
	onDelete,
	disabledHoleNumbers,
	challengeType,
}: Props) => {
	let disabledMessage = '';
	if (details.teeLocations.length >= 3) {
		disabledMessage = 'You can only have up to 3 tee boxes';
	} else if (
		challengeType === ChallengeType.PinPoint ||
		challengeType === ChallengeType.PinPointLeaderboard
	) {
		disabledMessage =
			'You cannot add multiple tee boxes for this challenge type';
	}

	// need to sort tee locations so default tee type is always first
	details.teeLocations = details.teeLocations.sort((a, b) =>
		// eslint-disable-next-line no-nested-ternary
		a.type === TeeType.default ? -1 : b.type === TeeType.default ? 1 : 0
	);

	return (
		<div className='hole-details-entry'>
			<div className='flex-row justify-between align-center'>
				<div className='flex-row align-center'>
					<h3 className='header gibson-large'>Hole</h3>
					<FormControl id='hole-num' label='' small>
						<Select
							id='hole-num'
							onChange={value => onUpdate({ ...details, holeNum: value })}
							options={useMemo(
								() =>
									Array.from(Array(19).keys()).map(x => ({
										value: (x + 1).toString(),
										displayText: (x + 1).toString(),
										disabled: disabledHoleNumbers.includes((x + 1).toString()),
									})),
								[disabledHoleNumbers]
							)}
							value={details.holeNum}
							small
						/>
					</FormControl>

					<h3 style={{ marginLeft: '1rem' }} className='header gibson-large'>
						Par
					</h3>
					<FormControl id='par-num' label='' small>
						<Select
							id='par-num'
							onChange={value => onUpdate({ ...details, par: +value })}
							options={useMemo(() => {
								let parArr = [3];
								if (challengeType === ChallengeType.FullRound)
									parArr = [3, 4, 5];
								else if (challengeType === ChallengeType.LongestDrive) {
									parArr = [4, 5];
									if (details.par === 3) details.par = 4;
								}
								return parArr.map(x => ({
									value: x.toString(),
									displayText: x.toString(),
									disabled: details.par === x,
								}));
							}, [challengeType, details.par])}
							value={details.par.toString()}
							small
						/>
					</FormControl>
				</div>
				<IconButton
					onClick={() => onDelete(details.id || 0)}
					icon={TrashCanSvg}
					title='Hole Details'
				>
					Delete
				</IconButton>
			</div>

			<div
				className='flex-row'
				style={{ justifyContent: 'space-between', marginTop: '2rem' }}
			>
				<div className='flex-col'>
					<span style={{ marginBottom: '0.5rem' }}>Tee Box Locations</span>
					{details.teeLocations.map((tee, index) => (
						<div
							className='flex-row gap-1'
							style={{ marginBottom: '0.5rem', position: 'relative' }}
							key={tee.id}
						>
							<FormControl
								id='lat'
								label='Latitude'
								style={{ width: '18.2rem' }}
								small
							>
								<input
									type='number'
									value={tee.location.latitude}
									onChange={e => {
										onUpdate({
											...details,
											teeLocations: details.teeLocations.map(x => {
												if (x.id === tee.id) {
													return {
														...x,
														location: {
															...x.location,
															latitude: e.target.value,
														},
													};
												}
												return { ...x };
											}),
										});
									}}
								/>
							</FormControl>
							<FormControl
								id='long'
								label='Longitude'
								style={{ width: '18.2rem' }}
								small
							>
								<input
									type='number'
									value={tee.location.longitude}
									onChange={e => {
										onUpdate({
											...details,
											teeLocations: details.teeLocations.map(x => {
												if (x.id === tee.id) {
													return {
														...x,
														location: {
															...x.location,
															longitude: e.target.value,
														},
													};
												}
												return { ...x };
											}),
										});
									}}
								/>
							</FormControl>
							{tee.type !== TeeType.default && (
								<div
									style={{
										position: 'absolute',
										right: '-12rem',
										bottom: '0',
									}}
								>
									<FormControl id='tee-type' label='Tee Type' small>
										<div className='flex-row align-center'>
											<Select
												id='tee-type'
												onChange={value => {
													onUpdate({
														...details,
														teeLocations: details.teeLocations.map(x => {
															if (x.id === tee.id) {
																return {
																	...x,
																	type: value as TeeType,
																};
															}
															return { ...x };
														}),
													});
												}}
												options={Object.values(TeeType).map(x => ({
													value: x.toString(),
													displayText: capitalizeFirstLetter(x),
													disabled:
														details.teeLocations.some(y => y.type === x) &&
														tee.type !== x,
												}))}
												value={tee.type.toString()}
												small
												customStyles={{ height: '4rem', padding: '0 1rem' }}
											/>
											<IconButton
												onClick={() => {
													const newArr = [...details.teeLocations];
													newArr.splice(index, 1);
													onUpdate({ ...details, teeLocations: newArr });
												}}
												icon={TrashCanSvg}
												title='Delete Tee box location'
												classNames='ml-1'
											/>
										</div>
									</FormControl>
								</div>
							)}
						</div>
					))}
				</div>
				<div className='flex-col'>
					<span style={{ marginBottom: '0.5rem' }}>Hole / Pin Location</span>
					<div className='flex-row gap-1'>
						<FormControl
							id='lat'
							label='Latitude'
							style={{ width: '18.2rem' }}
							small
						>
							<input
								type='number'
								disabled={details.addLater}
								value={details.pinLocation.latitude}
								onChange={e =>
									onUpdate({
										...details,
										pinLocation: {
											...details.pinLocation,
											latitude: e.target.value,
										},
									})
								}
							/>
						</FormControl>
						<FormControl
							id='long'
							label='Longitude'
							style={{ width: '18.2rem' }}
							small
						>
							<input
								type='number'
								disabled={details.addLater}
								value={details.pinLocation.longitude}
								onChange={e =>
									onUpdate({
										...details,
										pinLocation: {
											...details.pinLocation,
											longitude: e.target.value,
										},
									})
								}
							/>
						</FormControl>
					</div>
				</div>
			</div>
			<IconButton
				icon={PlusSvg}
				title='Add new teebox'
				classNames='primary-blue'
				onClick={() => {
					onUpdate({
						...details,
						teeLocations: [
							...details.teeLocations,
							{
								id: -Math.abs(details.teeLocations.at(-1)?.id ?? 0) - 1,
								holeConfigId: details.id,
								location: {
									latitude: '0',
									longitude: '0',
								},
								type:
									// find first available tee type
									Object.values(TeeType).find(
										x => !details.teeLocations.map(y => y.type).includes(x)
									) ?? TeeType.default,
							},
						],
					});
				}}
				disabled={!!disabledMessage}
				warningMessage={disabledMessage}
			>
				Add new teebox
			</IconButton>
			<div className='row'>
				<Checkbox
					onChange={e =>
						onUpdate({
							...details,
							pinLocation: {
								latitude: '0',
								longitude: '0',
							},
							addLater: e.target.checked,
						})
					}
					checked={details.addLater}
				>
					Add locations later using mobile app
				</Checkbox>
			</div>
		</div>
	);
};

export default HoleDetailsEntry;
