import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useGetChallengeTypesQuery,
	useLazyGetChallengeQuery,
	useLazyGetGroupsQuery,
	useLazyGetHolesQuery,
} from '../../../api/dashboard/endpoints/challenge-endpoints';
import { useGetEventQuery } from '../../../api/dashboard/endpoints/event-endpoints';
import { useGetParticipantsforGroups } from '../../../api/dashboard/endpoints/group-endpoints';
import {
	useAddParticipantsMutation,
	useGetAllParticipantsQuery,
} from '../../../api/dashboard/endpoints/organization-endpoints';
import { SlOrgImage } from '../../../api/dashboard/schema/SlOrganization';
import { SlParticipantWithGroupName } from '../../../api/dashboard/schema/SlParticipant';
import SlRound from '../../../api/dashboard/schema/SlRound';
import { parseDataSheet, reverseCamelCase } from '../../../misc/helper-util';
import {
	addPlayerToOrg,
	resetToast,
	setToastError,
	setToastSuccess,
} from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import DropdownOptionInterface from '../../shared/forms/DropdownInput/DropdownOptionsInterface';
import { ChallengeType, DivisionType, MapType } from '../../shared/types/enums';
import GroupDetails from './GroupDetails';
import HoleDetails from './HoleDetails';
import useSubmitChallengeDetails from './useSubmitChallengeDetails';

const requiredDatasheetHeaders = ['firstName', 'lastName', 'dateOfBirth'];

const defaultParticipantObject: SlParticipantWithGroupName = {
	id: -1,
	orgId: -1,
	firstName: '',
	lastName: '',
	dateOfBirth: '',
	dominantHand: '',
	address: '',
	state: '',
	city: '',
	zip: '',
	height: null,
	weight: null,
	gender: '',
	email: '',
	phone: '',
	division: null,
	groupName: '',
	description: '',
};

const useChallengeDetails = ({
	eventId,
	challengeId,
}: {
	eventId: string;
	challengeId?: string;
}) => {
	const navigate = useNavigate();

	const orgId = +SessionStorageService.orgId;
	const dispatch = useAppDispatch();

	const defaultChallenge: SlRound = {
		id: -1,
		eventId: +eventId,
		name: '',
		date: '',
		description: '',
		challengeType: ChallengeType.PinPoint,
		active: true,
		mapType: MapType.google,
	};

	const { data: currentEvent } = useGetEventQuery({
		orgId,
		eventId: +eventId,
	});

	const [addPlayers, { isLoading: isAddPlayersSubmitLoading }] =
		useAddParticipantsMutation();

	const [originalGroups, setOriginalGroups] = useState<GroupDetails[]>([]);
	const [originalHoles, setOriginalHoles] = useState<HoleDetails[]>([]);
	const [originalChallenge, setOriginalChallenge] =
		useState<SlRound>(defaultChallenge);

	// Any challenge not saved to DB will have a negative ID
	const [newHoleId, setNewHoleId] = useState(-1);
	const [newGroupId, setNewGroupId] = useState(-1);

	const [getChallenge, { data: round }] = useLazyGetChallengeQuery();

	const [challenge, setChallenge] = useState<SlRound>(defaultChallenge);

	const [isFormValid, setIsFormValid] = useState(false);

	const [isHoleDetailsOpen, setIsHoleDetailsOpen] = useState<boolean>(true);
	const [isGroupDetailsOpen, setIsGroupDetailsOpen] = useState<boolean>(true);

	const [getHoles, { data: holesData, isFetching: isHolesDataLoading }] =
		useLazyGetHolesQuery();
	const [holesDetails, setHolesDetails] = useState<HoleDetails[]>([]);

	const [getGroups, { data: groupsData, isFetching: isGroupsDataLoading }] =
		useLazyGetGroupsQuery();
	const [groupsDetails, setGroupsDetails] = useState<GroupDetails[]>([]);

	const { data: allParticipants, isFetching: isParticipantsLoading } =
		useGetAllParticipantsQuery(orgId);

	const { data: players } = useGetParticipantsforGroups(groupsData);

	const { data: challengeTypes } = useGetChallengeTypesQuery();

	const [challengeTypeList, setChallengeTypeList] = useState<
		DropdownOptionInterface[]
	>([]);

	const [csvFile, setCsvFile] = useState<SlOrgImage>({
		file: undefined,
		fileName: '',
	});

	const [csvFileError, setCsvFileError] = useState<boolean>(false);

	const { submitChallengeDetails, isSubmitLoading } =
		useSubmitChallengeDetails();

	useEffect(() => {
		if (challengeId) {
			getChallenge({ eventId: +eventId, challengeId: +challengeId });
			getHoles(+challengeId);
			getGroups(+challengeId);
		}
	}, [challengeId]);

	useEffect(() => {
		if (round) {
			setChallenge(round);
			setOriginalChallenge(round);
		}
	}, [round]);

	useEffect(() => {
		if (challengeTypes) {
			setChallengeTypeList(
				challengeTypes.map(type => ({
					value: type.challengeType,
					displayText: type.challengeType.replace(/([A-Z])/g, ' $1').trim(),
					subText: type.challengeDescription,
					selected: type.challengeType === challenge.challengeType,
					disabled: false,
					key: type.id,
				}))
			);
		}
	}, [challengeTypes, challenge]);

	useEffect(() => {
		if (holesData) {
			const holeDetails: HoleDetails[] = holesData.map(x => ({
				id: x.id,
				challengeId: x.roundId,
				par: x.par || 3,
				holeNum: x.holeNumber.toString(),
				teeLocations: x.teeLocations.map(tee => ({
					...tee,
					location: {
						latitude: tee.location.latitude.toString(),
						longitude: tee.location.longitude.toString(),
					},
				})),
				pinLocation: {
					latitude: x.pinLocation.latitude.toString(),
					longitude: x.pinLocation.longitude.toString(),
				},
				addLater: false,
			}));

			setOriginalHoles([...holeDetails]);

			setHolesDetails(holeDetails);
		}
	}, [holesData]);

	useEffect(() => {
		if (groupsData && players) {
			const groupDetails: GroupDetails[] = groupsData.map(x => ({
				id: x.id,
				challengeId: x.roundId,
				groupName: x.name,
				playerIds: players
					.filter(y => y.groupIds?.includes(x.id))
					.map(y => y.id),
			}));
			setGroupsDetails(groupDetails);

			// quick way to save new array by value rather than by reference
			setOriginalGroups(JSON.parse(JSON.stringify(groupDetails)));
		}
	}, [groupsData, players]);

	const nextAvailableHoleNum = useMemo(() => {
		const usedHoleNumbers = holesDetails.map(x => +x.holeNum);
		const availNumbers = Array.from(Array(19).keys())
			.map(x => x + 1)
			.filter(x => !usedHoleNumbers.includes(x));
		return Math.min(...availNumbers).toString();
	}, [holesDetails.length]);

	// Form validation
	useEffect(() => {
		if (
			challenge.name.length > 0 &&
			challenge.date.length > 0 &&
			challenge.challengeType !== ChallengeType.Default &&
			holesDetails.length > 0 &&
			groupsDetails &&
			!groupsDetails.some(x => x.groupName === '') &&
			!csvFileError
		) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [challenge, holesDetails, groupsDetails, csvFileError]);

	const parseCsvFile = async (file: File | undefined) => {
		setCsvFile({
			file,
			fileName: file?.name ?? '',
		});

		const errors: string[] = [];

		const { data, requiredHeaders } =
			await parseDataSheet<SlParticipantWithGroupName>(
				file,
				requiredDatasheetHeaders
			);

		if (data.length > 0) {
			requiredHeaders.forEach(x => {
				errors.push(
					`Required header ${reverseCamelCase(x)} is missing from the dataset`
				);
			});
		}

		let heightError = false;
		let weightError = false;
		const newParticipants = data.map(x => {
			let height = 0;
			let weight = 0;
			if (x.height) {
				if (!Number.isNaN(+x.height)) height = +x.height;
				else heightError = true;
			}
			if (x.weight) {
				if (!Number.isNaN(+x.weight)) weight = +x.weight;
				else weightError = true;
			}

			return {
				...defaultParticipantObject,
				...x,
				orgId,
				height,
				weight,
				division:
					DivisionType[
						(x.division?.toLowerCase() ?? '') as keyof typeof DivisionType
					] ?? null,
			};
		});
		if (heightError)
			errors.push(
				'Please make sure the Height column is of type "number" in inches'
			);
		if (weightError)
			errors.push(
				'Please make sure the Weight column is of type "number" in pounds'
			);

		if (errors.length > 0) {
			dispatch(
				setToastError({
					message: 'Failed to load datasheet, please try again.',
					errors,
					showIndefinitely: true,
				})
			);
		} else if (newParticipants.length > 0) {
			try {
				const addedPlayers = await addPlayers({
					orgId,
					participants: newParticipants,
				}).unwrap();

				addedPlayers.forEach(player => {
					dispatch(addPlayerToOrg(player));
				});

				// now that players have been added to org, need to check if a group name was supplied in datasheet, and if so, then need to add these new players to those groups
				const newGroupArr = [...groupsDetails];
				let newGroupIdCounter = newGroupId;
				newParticipants.forEach(player => {
					if (player.groupName) {
						// need to get id value of newly created player
						const newPlayerId = addedPlayers.find(
							x =>
								x.firstName === player.firstName &&
								x.lastName === player.lastName &&
								x.dateOfBirth === player.dateOfBirth
						)?.id;
						if (newPlayerId) {
							// now need to check to see if group name exists. If so, add it to that group. If not, then need to create new group
							const group = newGroupArr.find(
								x => x.groupName === player.groupName
							);
							if (group) {
								const index = newGroupArr.findIndex(x => x.id === group.id);
								if (index > -1)
									newGroupArr[index].playerIds = [
										...group.playerIds,
										newPlayerId,
									];
							} else {
								newGroupArr.push({
									groupName: player.groupName,
									challengeId: challengeId ? +challengeId : 0,
									id: newGroupIdCounter,
									playerIds: [newPlayerId],
								});
								newGroupIdCounter--;
							}
						}
					}
				});

				setGroupsDetails(newGroupArr);
				setNewGroupId(newGroupIdCounter);

				dispatch(setToastSuccess('The new players were successfully created.'));
			} catch {
				dispatch(
					setToastError({
						message: 'Failed to add players, please try again.',
						errors: [],
						showIndefinitely: false,
					})
				);
			}
		} else {
			dispatch(resetToast());
		}

		setCsvFileError(errors.length > 0);
	};

	const nonRequiredDatasheetHeaders = useMemo(
		() =>
			Object.keys(defaultParticipantObject)
				.filter(x => !requiredDatasheetHeaders.includes(x))
				.filter(x => x !== 'id' && x !== 'orgId'),
		[]
	);

	return {
		form: {
			challenge,
			setChallenge,
			currentEvent,
			isFormValid,
			challengeTypeList,
			csvFile,
			csvFileError,
			parseCsvFile,
			isLoading:
				isSubmitLoading ||
				isHolesDataLoading ||
				isGroupsDataLoading ||
				isParticipantsLoading ||
				isAddPlayersSubmitLoading,
			onNextClick: async () => {
				const { success, errors } = await submitChallengeDetails(
					challenge,
					originalChallenge,
					originalGroups,
					groupsDetails,
					originalHoles,
					holesDetails
				);

				if (success) {
					dispatch(
						setToastSuccess(
							`This challenge was successfully ${
								challengeId ? 'updated' : 'created'
							}.`
						)
					);
				} else {
					dispatch(setToastError({ message: 'Unexpected errors', errors }));
				}
				navigate(`/events/${eventId}`);
			},
			onBackClick: () => navigate(-1),
		},
		hole: {
			holesDetails,
			nextAvailableHoleNum,
			newHoleId,
			isHoleDetailsOpen,
			setIsHoleDetailsOpen,
			addHole: (details: HoleDetails) => {
				setHolesDetails([...holesDetails, details]);
				setNewHoleId(newHoleId - 1);
			},
			updateHole: (details: HoleDetails) => {
				const newArr = [...holesDetails];
				const index = holesDetails.findIndex(x => x.id === details.id);
				if (index > -1) {
					newArr[index] = details;
					setHolesDetails(newArr);
				}
			},
			deleteHole: (id: number) => {
				const newArr = [...holesDetails];
				const index = holesDetails.findIndex(x => x.id === id);
				newArr.splice(index, 1);
				setHolesDetails(newArr);
			},
			updateAllHoles: (details: HoleDetails[]) => {
				setHolesDetails(details);
			},
		},
		group: {
			requiredDatasheetHeaders,
			nonRequiredDatasheetHeaders,
			groupsDetails,
			newGroupId,
			isGroupDetailsOpen,
			setIsGroupDetailsOpen,
			allParticipants: allParticipants?.map(x => ({
				id: x.id,
				firstName: x.firstName,
				lastName: x.lastName,
			})),
			addGroup: (details: GroupDetails) => {
				setGroupsDetails([...groupsDetails, details]);
				setNewGroupId(newGroupId - 1);
			},
			updateGroup: (details: GroupDetails) => {
				const newArr = [...groupsDetails];
				const index = groupsDetails.findIndex(x => x.id === details.id);
				if (index > -1) {
					newArr[index] = details;
					setGroupsDetails(newArr);
				}
			},
			deleteGroup: (id: number) => {
				const newArr = [...groupsDetails];
				const index = groupsDetails.findIndex(x => x.id === id);
				newArr.splice(index, 1);
				setGroupsDetails(newArr);
			},
		},
	};
};

export default useChallengeDetails;
