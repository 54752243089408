import React from 'react';

import './Loader.scss';

interface Props {
	customStyles?: { [key: string]: string };
}

const Loader = ({ customStyles }: Props) => (
	<div className='loader' style={customStyles} />
);

export default Loader;
