const states = [
	{
		value: 'State',
		displayText: 'State',
		disabled: true,
		key: 0,
	},
	{
		value: 'Alabama',
		displayText: 'Alabama',
		disabled: false,
		key: 1,
	},
	{
		value: 'Alaska',
		displayText: 'Alaska',
		disabled: false,
		key: 2,
	},
	{
		value: 'Arizona',
		displayText: 'Arizona',
		disabled: false,
		key: 3,
	},
	{
		value: 'Arkansas',
		displayText: 'Arkansas',
		disabled: false,
		key: 4,
	},
	{
		value: 'California',
		displayText: 'California',
		disabled: false,
		key: 5,
	},
	{
		value: 'Colorado',
		displayText: 'Colorado',
		disabled: false,
		key: 6,
	},
	{
		value: 'Connecticut',
		displayText: 'Connecticut',
		disabled: false,
		key: 7,
	},
	{
		value: 'Delaware',
		displayText: 'Delaware',
		disabled: false,
		key: 8,
	},
	{
		value: 'Florida',
		displayText: 'Florida',
		disabled: false,
		key: 9,
	},
	{
		value: 'Georgia',
		displayText: 'Georgia',
		disabled: false,
		key: 10,
	},
	{
		value: 'Hawaii',
		displayText: 'Hawaii',
		disabled: false,
		key: 11,
	},
	{
		value: 'Idaho',
		displayText: 'Idaho',
		disabled: false,
		key: 12,
	},
	{
		value: 'Illinois',
		displayText: 'Illinois',
		disabled: false,
		key: 13,
	},
	{
		value: 'Indiana',
		displayText: 'Indiana',
		disabled: false,
		key: 14,
	},
	{
		value: 'Iowa',
		displayText: 'Iowa',
		disabled: false,
		key: 15,
	},
	{
		value: 'Kansas',
		displayText: 'Kansas',
		disabled: false,
		key: 16,
	},
	{
		value: 'Kentucky',
		displayText: 'Kentucky',
		disabled: false,
		key: 17,
	},
	{
		value: 'Louisiana',
		displayText: 'Louisiana',
		disabled: false,
		key: 18,
	},
	{
		value: 'Maine',
		displayText: 'Maine',
		disabled: false,
		key: 19,
	},
	{
		value: 'Maryland',
		displayText: 'Maryland',
		disabled: false,
		key: 20,
	},
	{
		value: 'Massachusetts',
		displayText: 'Massachusetts',
		disabled: false,
		key: 21,
	},
	{
		value: 'Michigan',
		displayText: 'Michigan',
		disabled: false,
		key: 22,
	},
	{
		value: 'Minnesota',
		displayText: 'Minnesota',
		disabled: false,
		key: 23,
	},
	{
		value: 'Mississippi',
		displayText: 'Mississippi',
		disabled: false,
		key: 24,
	},
	{
		value: 'Missouri',
		displayText: 'Missouri',
		disabled: false,
		key: 25,
	},
	{
		value: 'Montana',
		displayText: 'Montana',
		disabled: false,
		key: 26,
	},
	{
		value: 'Nebraska',
		displayText: 'Nebraska',
		disabled: false,
		key: 27,
	},
	{
		value: 'Nevada',
		displayText: 'Nevada',
		disabled: false,
		key: 28,
	},
	{
		value: 'New Hampshire',
		displayText: 'New Hampshire',
		disabled: false,
		key: 29,
	},
	{
		value: 'New Jersey',
		displayText: 'New Jersey',
		disabled: false,
		key: 30,
	},
	{
		value: 'New Mexico',
		displayText: 'New Mexico',
		disabled: false,
		key: 31,
	},
	{
		value: 'New York',
		displayText: 'New York',
		disabled: false,
		key: 32,
	},
	{
		value: 'North Carolina',
		displayText: 'North Carolina',
		disabled: false,
		key: 33,
	},
	{
		value: 'North Dakota',
		displayText: 'North Dakota',
		disabled: false,
		key: 34,
	},
	{
		value: 'Ohio',
		displayText: 'Ohio',
		disabled: false,
		key: 35,
	},
	{
		value: 'Oklahoma',
		displayText: 'Oklahoma',
		disabled: false,
		key: 36,
	},
	{
		value: 'Oregon',
		displayText: 'Oregon',
		disabled: false,
		key: 37,
	},
	{
		value: 'Pennsylvania',
		displayText: 'Pennsylvania',
		disabled: false,
		key: 38,
	},
	{
		value: 'Rhode Island',
		displayText: 'Rhode Island',
		disabled: false,
		key: 39,
	},
	{
		value: 'South Carolina',
		displayText: 'South Carolina',
		disabled: false,
		key: 40,
	},
	{
		value: 'South Dakota',
		displayText: 'South Dakota',
		disabled: false,
		key: 41,
	},
	{
		value: 'Tennessee',
		displayText: 'Tennessee',
		disabled: false,
		key: 42,
	},
	{
		value: 'Texas',
		displayText: 'Texas',
		disabled: false,
		key: 43,
	},
	{
		value: 'Utah',
		displayText: 'Utah',
		disabled: false,
		key: 44,
	},
	{
		value: 'Vermont',
		displayText: 'Vermont',
		disabled: false,
		key: 45,
	},
	{
		value: 'Virginia',
		displayText: 'Virginia',
		disabled: false,
		key: 46,
	},
	{
		value: 'Washington',
		displayText: 'Washington',
		disabled: false,
		key: 47,
	},
	{
		value: 'Washington DC',
		displayText: 'Washington DC',
		disabled: false,
		key: 48,
	},
	{
		value: 'West Virginia',
		displayText: 'West Virginia',
		disabled: false,
		key: 49,
	},
	{
		value: 'Wisconsin',
		displayText: 'Wisconsin',
		disabled: false,
		key: 50,
	},
	{
		value: 'Wyoming',
		displayText: 'Wyoming',
		disabled: false,
		key: 51,
	},
];

export default states;
