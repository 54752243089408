import { useEffect, useState } from 'react';

const useModal = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	// Prevent scrolling in background when modal is open
	useEffect(() => {
		const body = document.getElementsByTagName('body')[0];
		if (isModalOpen) {
			body.classList.add('modal-open');
		} else {
			body.classList.remove('modal-open');
		}
	}, [isModalOpen]);

	const toggleModal = () => {
		setIsModalOpen(prev => !prev);
	};

	return { isModalOpen, toggleModal };
};

export default useModal;
