import React from 'react';

import Button from '../../forms/Button/Button';
import FormTitle from '../FormTitle/FormTitle';
import Modal from './Modal';

interface Props {
	type?: string;
	title?: string;
	isVisible: boolean;
	toggleModal: () => void;
	onSubmit: () => void;
	customStyles?: { [key: string]: string };
}

const DeleteModal = (props: Props) => {
	const { type, title, isVisible, toggleModal, onSubmit, customStyles } = props;

	return (
		<Modal
			isVisible={isVisible}
			customStyles={{
				...customStyles,
				maxWidth: '50rem',
			}}
		>
			<>
				<FormTitle
					title={`Delete ${title}?`}
					customStyles={{ marginTop: '0' }}
				/>
				<div className='flex-col gap-2'>
					<p className='m-0'>Are you sure you want to delete {title}?</p>
					<p className='m-0'>
						If so, this {type?.toLowerCase()} and all its data will be
						permanently deleted.
					</p>
				</div>
			</>

			<div className='flex-row gap-2' style={{ marginTop: '6rem' }}>
				<Button variant='outlined' onClick={toggleModal}>
					Cancel
				</Button>
				<Button onClick={onSubmit}>Delete</Button>
			</div>
		</Modal>
	);
};

export default DeleteModal;
