import decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import SessionStorageService from '../api/SessionStorageService';
import {
	getAuthToken,
	parseStringFromAuthResponse,
	removeAuthToken,
	setAuthToken,
} from '../auth/auth-util';
import { AUTH_URL, LOGOUT_URL } from './auth-config';

interface JwtDecode {
	exp: number;
}

const useAuth = () => {
	const [isAuthToken, setIsAuthToken] = useState(false);

	const logout = () => {
		const relativePath = window.location.pathname;
		if (relativePath !== SessionStorageService.currentPage) {
			// save the current page in session storage so it will navigate back to this page if browser refreshes
			SessionStorageService.currentPage = relativePath;
		}
		removeAuthToken();
		window.location.assign(LOGOUT_URL);
	};

	const onPrompt = () => {
		// TODO
	};

	// Logout in 15 minutes if user is idle
	useIdleTimer({
		onIdle: logout,
		onPrompt,
		timeout: 900 * 1000,
	});

	useEffect(() => {
		const storedAuthToken = getAuthToken();
		let expiration = 0;

		if (storedAuthToken) {
			const decoded: { exp: number } = decode(storedAuthToken);
			expiration = decoded.exp;
			if (DateTime.fromSeconds(decoded.exp) < DateTime.now()) {
				logout();
			} else {
				setIsAuthToken(true);
			}
		} else {
			// Pull values from url
			const authToken = parseStringFromAuthResponse('id_token=', '&');

			// If values aren't in url, send them to authentication page
			if (!authToken) {
				window.location.assign(AUTH_URL);
			} else {
				const { exp }: JwtDecode = decode(authToken);
				expiration = exp;

				setAuthToken(authToken);
				setIsAuthToken(true);
			}
		}

		// Auto-logout once token is expired
		const timeoutId = setTimeout(
			logout,
			DateTime.fromSeconds(expiration)?.diff(DateTime.now()).toMillis()
		);

		// logout on browser close
		window.addEventListener('beforeunload', logout);

		return () => {
			clearTimeout(timeoutId);
			window.removeEventListener('beforeunload', logout);
		};
	}, []);

	return { isAuthToken };
};

export default useAuth;
