import { useEffect, useState } from 'react';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useAddParticipantMutation,
	useLazyGetParticipantQuery,
	useUpdateParticipantMutation,
} from '../../../api/dashboard/endpoints/organization-endpoints';
import SlParticipant from '../../../api/dashboard/schema/SlParticipant';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';

const useAddPlayerForm = (
	toggleModal: (playerToAdd: SlParticipant | null) => void,
	playerId: number | undefined,
	groupId: number | undefined
) => {
	const [getPlayer, { data: player }] = useLazyGetParticipantQuery();
	const [createPlayer] = useAddParticipantMutation();
	const [updatePlayer] = useUpdateParticipantMutation();

	const isNewPlayer = playerId === undefined;

	const dispatch = useAppDispatch();

	const orgId = +SessionStorageService.orgId;

	const [currentPlayer, setCurrentPlayer] = useState<SlParticipant>({
		id: -1,
		orgId,
		firstName: '',
		lastName: '',
		dateOfBirth: '',
		dominantHand: '',
		address: '',
		state: '',
		city: '',
		zip: '',
		height: null,
		weight: null,
		gender: '',
		email: '',
		phone: '',
		division: null,
		description: '',
	});
	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	useEffect(() => {
		if (playerId) {
			getPlayer({ orgId, playerId });
		}
	}, [playerId]);

	useEffect(() => {
		if (player) {
			setCurrentPlayer(player);
		}
	}, [player]);

	// Form validation
	useEffect(() => {
		if (
			currentPlayer.firstName.length > 0 &&
			currentPlayer.lastName.length > 0 &&
			currentPlayer.dateOfBirth.length > 0
		) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [currentPlayer]);

	const onSave = async () => {
		let updatedPlayer: SlParticipant | null = null;
		try {
			if (isNewPlayer) {
				updatedPlayer = await createPlayer({
					orgId,
					participant: currentPlayer,
				}).unwrap();
			} else {
				updatedPlayer = await updatePlayer({
					orgId,
					participant: currentPlayer,
				}).unwrap();
			}

			dispatch(
				setToastSuccess(
					`The player was successfully ${isNewPlayer ? 'created' : 'updated'}.`
				)
			);
		} catch {
			dispatch(
				setToastError({
					message: `Failed to ${isNewPlayer ? 'create' : 'update'} player.`,
					errors: [],
				})
			);
		}
		toggleModal(updatedPlayer);
	};

	return {
		currentPlayer,
		setCurrentPlayer,
		isFormValid,
		isNewPlayer,
		onSave,
	};
};

export default useAddPlayerForm;
