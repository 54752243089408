import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useCreateMarketingInfoMutation,
	useGetMarketingInfoQuery,
	useUpdateMarketingInfoMutation,
} from '../../../api/dashboard/endpoints/event-endpoints';
import { useUploadFileToS3Mutation } from '../../../api/dashboard/endpoints/organization-endpoints';
import SlMarketing, {
	SlPrize,
} from '../../../api/dashboard/schema/SlMarketing';
import { SlOrgImage } from '../../../api/dashboard/schema/SlOrganization';
import { getFileExtension } from '../../../misc/helper-util';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';

const useMarketingForm = (eventId: number) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const orgId = +SessionStorageService.orgId;

	const { data: marketingInfo, isError } = useGetMarketingInfoQuery({
		orgId,
		eventId,
	});

	const [createMarketingInfo, { isLoading: isCreateMarketingLoading }] =
		useCreateMarketingInfoMutation();
	const [updateMarketingInfo, { isLoading: isUpdateMarketingLoading }] =
		useUpdateMarketingInfoMutation();
	const [uploadFileToS3, { isLoading: isUploadFileLoading }] =
		useUploadFileToS3Mutation();

	const [isPromotionSectionChecked, setIsPromotionSectionChecked] =
		useState<boolean>(false);
	const [isPlaySectionChecked, setIsPlaySectionChecked] =
		useState<boolean>(false);
	const [isNewMarketingInfo, setIsNewMarketingInfo] = useState<boolean>(true);

	const defaultImage: SlOrgImage = {
		file: undefined,
		fileName: '',
	};

	const [form, setForm] = useState<SlMarketing>({
		id: -1,
		eventId,
		title: '',
		subtitle: '',
		heroImagePath: '',
		heroImage: defaultImage,
		linkText: '',
		linkUrl: '',
		prizeText: '',
		prizeDetailsLink: '',
		rules: [''],
		prizes: [
			{
				id: -1,
				marketingInfoId: -1,
				title: '',
				description: '',
				imagePath: '',
				image: defaultImage,
			},
		],
		showImages: true,
	});

	const [newPromotionCardId, setNewPromotionCardId] = useState<number>(-2);

	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	useEffect(() => {
		if (marketingInfo && !isError) {
			// need to set image objects to the filename returned for both hero and each prize because they aren't returned in payload from API
			setForm({
				...marketingInfo,
				heroImage: {
					file: undefined,
					fileName: marketingInfo.heroImagePath.split('/').slice(-1)[0],
				},
				prizes: marketingInfo.prizes.map(prize => ({
					...prize,
					image: {
						file: undefined,
						fileName: prize.imagePath.split('/').slice(-1)[0],
					},
				})),
				rules: marketingInfo.rules.length > 0 ? marketingInfo.rules : [''],
			});
			setIsNewMarketingInfo(false);

			if (marketingInfo.prizes.length > 0) {
				setIsPromotionSectionChecked(true);
			}
			if (marketingInfo.rules.length > 0) {
				setIsPlaySectionChecked(true);
			}
		}
	}, [marketingInfo, isError]);

	useEffect(() => {
		if (isPromotionSectionChecked && form.prizes.length === 0) {
			setForm({
				...form,
				prizes: [
					{
						id: -1,
						marketingInfoId: -1,
						title: '',
						description: '',
						imagePath: '',
						image: defaultImage,
					},
				],
			});
		}

		let isPromotionCardValid = true;
		form.prizes.forEach(prize => {
			if (
				prize.title.length === 0 ||
				(form.showImages && prize.image?.fileName.length === 0) ||
				prize.description.length === 0
			) {
				isPromotionCardValid = false;
			}
		});

		const isHeroLinkSectionValid =
			(form.linkText.length === 0 && form.linkUrl.length === 0) ||
			(form.linkText.length > 0 && form.linkUrl.length > 0);

		const isPromotionSectionValid =
			!isPromotionSectionChecked ||
			(form.prizes.length > 0 &&
				form.prizes.length > 0 &&
				isPromotionCardValid);

		const isPlaySectionValid =
			!isPlaySectionChecked ||
			(form.rules.length > 0 && form.rules.filter(x => x === '').length === 0);

		setIsFormValid(
			form.title.length > 0 &&
				isHeroLinkSectionValid &&
				isPromotionSectionValid &&
				isPlaySectionValid
		);
	}, [form, isPromotionSectionChecked, isPlaySectionChecked]);

	return {
		form: {
			form,
			setForm,
			isPromotionSectionChecked,
			setIsPromotionSectionChecked,
			isPlaySectionChecked,
			setIsPlaySectionChecked,
			isFormValid,
			newPromotionCardId,
			isSubmitLoading:
				isCreateMarketingLoading ||
				isUpdateMarketingLoading ||
				isUploadFileLoading,
		},
		handlers: {
			onBackClick: () => navigate(-1),
			onNextClick: async () => {
				const imagePath = `images/events/${eventId ?? -1}`;

				const newForm = { ...form };
				if (form.heroImage?.file) {
					const heroExtension = getFileExtension(form.heroImage?.file.name);
					newForm.heroImagePath = `${imagePath}/hero.${heroExtension}`;
				}

				if (!isPromotionSectionChecked) {
					newForm.prizes = [];
				} else {
					newForm.prizes.forEach((prize, index) => {
						if (prize.image?.file) {
							const extension = getFileExtension(prize.image?.file.name);
							prize.imagePath = `${imagePath}/prizes/${index}.${extension}`;
						}
					});
				}
				if (!isPlaySectionChecked) {
					newForm.rules = [];
				}

				try {
					if (isNewMarketingInfo) {
						await createMarketingInfo({ orgId, marketing: newForm }).unwrap();
					} else {
						await updateMarketingInfo({ orgId, marketing: newForm }).unwrap();
					}

					// if endpoint was successful, then add hero and promotion images to S3 bucket
					let isFileUploadError = false;

					if (newForm.heroImage?.file) {
						try {
							const imageData = new FormData();
							imageData.append('file', newForm.heroImage?.file as Blob);
							imageData.append('fileName', newForm.heroImagePath);

							await uploadFileToS3({
								orgId,
								body: imageData,
							}).unwrap();
						} catch {
							isFileUploadError = true;
						}
					}

					if (newForm.showImages) {
						newForm.prizes.forEach(async prize => {
							try {
								const imageData = new FormData();
								imageData.append('file', prize.image?.file as Blob);
								imageData.append('fileName', prize.imagePath);

								await uploadFileToS3({
									orgId,
									body: imageData,
								}).unwrap();
							} catch {
								isFileUploadError = true;
							}
						});
					}

					if (isFileUploadError) {
						dispatch(
							setToastError({
								message:
									'There was an error uploading the images to the S3 bucket.',
								errors: [],
							})
						);
					} else {
						dispatch(
							setToastSuccess(
								'The marketing info was successfully saved for this event.'
							)
						);
					}
				} catch {
					dispatch(
						setToastError({
							message: 'Failed to update the marketing info for this event.',
							errors: [],
						})
					);
				}
				navigate(`/events/${eventId}`);
			},
			saveHeroImage: async (file: File | undefined) => {
				setForm({ ...form, heroImage: { file, fileName: file?.name ?? '' } });
			},
			addPromotionCard: (card: SlPrize) => {
				setForm({ ...form, prizes: [...form.prizes, card] });
				setNewPromotionCardId(newPromotionCardId - 1);
			},
			updatePromotionCard: (card: SlPrize) => {
				const newArr = [...form.prizes];
				const index = form.prizes.findIndex(x => x.id === card.id);
				if (index > -1) {
					newArr[index] = card;
					setForm({ ...form, prizes: newArr });
				}
			},
			deletePromotionCard: (id: number) => {
				const newArr = [...form.prizes];
				const index = form.prizes.findIndex(x => x.id === id);
				newArr.splice(index, 1);
				setForm({ ...form, prizes: newArr });
			},
			addRule: () => {
				setForm({ ...form, rules: [...form.rules, ''] });
			},
			updateRule: (index: number, rule: string) => {
				const newArr = [...form.rules];
				newArr[index] = rule;
				setForm({ ...form, rules: newArr });
			},
			deleteRule: (index: number) => {
				const newArr = [...form.rules];
				newArr.splice(index, 1);
				setForm({ ...form, rules: newArr });
			},
		},
	};
};

export default useMarketingForm;
