/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum ModalType {
	Delete = 'Delete',
	Authenticate = 'Authenticate',
	Edit = 'Edit',
}

export enum ChallengeType {
	Default = '',
	PinPoint = 'PinPoint',
	PinPointLeaderboard = 'PinPointLeaderboard',
	FullRound = 'FullRound',
	LongestDrive = 'LongestDrive',
}

export enum ActionType {
	Event = 'Event',
	Challenge = 'Challenge',
	Player = 'Player',
	Marketing = 'Marketing',
}

export enum OrgLinkType {
	about = 'about',
	contact = 'contact',
	linkedin = 'linkedin',
	twitter = 'twitter',
	youtube = 'youtube',
	other = 'other',
}

export enum TeeType {
	default = 'default',
	short = 'short',
	long = 'long',
}

export enum MapType {
	bing = 'bing',
	google = 'google',
}

export enum EventFilterType {
	all = 'all',
	active = 'active',
	upcoming = 'upcoming',
	past = 'past',
}

export enum DivisionType {
	junior = 'junior',
	amateur = 'amateur',
	professional = 'professional',
}
