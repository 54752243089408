import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useCreateEventMutation,
	useLazyGetEventQuery,
	useUpdateEventMutation,
} from '../../../api/dashboard/endpoints/event-endpoints';
import { useUploadFileToS3Mutation } from '../../../api/dashboard/endpoints/organization-endpoints';
import SlEvent from '../../../api/dashboard/schema/SlEvent';
import { SlOrgImage } from '../../../api/dashboard/schema/SlOrganization';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import { OrgLinkType } from '../../shared/types/enums';

const useManageEventForm = () => {
	const navigate = useNavigate();

	const orgId = +SessionStorageService.orgId;
	const dispatch = useAppDispatch();

	const { eventId } = useParams();
	const isNewEvent = eventId === undefined;

	const [getEvent, { data: event }] = useLazyGetEventQuery();

	const [isFormValid, setIsFormValid] = useState(false);
	const [uploadFileToS3] = useUploadFileToS3Mutation();

	const [currentEvent, setCurrentEvent] = useState<SlEvent>({
		id: -1,
		name: '',
		orgId,
		description: '',
		startDate: '',
		endDate: '',
		address: '',
		city: '',
		state: '',
		venueName: '',
		zipCode: '',
		links: [
			{
				type: OrgLinkType.about,
				orgId,
				link: '',
			},
			{
				type: OrgLinkType.contact,
				orgId,
				link: '',
			},
			{
				type: OrgLinkType.linkedin,
				orgId,
				link: '',
			},
			{
				type: OrgLinkType.twitter,
				orgId,
				link: '',
			},
			{
				type: OrgLinkType.youtube,
				orgId,
				link: '',
			},
			{
				type: OrgLinkType.other,
				orgId,
				link: '',
			},
		],
	});
	const [challengeImage, setChallengeImage] = useState<SlOrgImage>({
		file: undefined,
		fileName: '',
	});

	const [isDescriptionValid, setIsDescriptionValid] = useState(true);

	const [createEvent] = useCreateEventMutation();
	const [updateEvent] = useUpdateEventMutation();

	useEffect(() => {
		if (eventId) {
			getEvent({ orgId, eventId: +eventId });
		}
	}, [eventId]);

	useEffect(() => {
		if (event) {
			setCurrentEvent({
				...event,
				// use link values returned from DB, but if they don't exist, then use default link value
				links: currentEvent.links.map(
					x => event.links.find(p => p.type === x.type) || x
				),
			});
		}
	}, [event]);

	// set links
	const setLinks = (type: OrgLinkType, value: string) => {
		setCurrentEvent({
			...currentEvent,
			links: currentEvent.links.map(x => {
				if (x.type === type) {
					return {
						...x,
						link: value,
					};
				}
				return x;
			}),
		});
	};

	// Form validation
	useEffect(() => {
		if (
			currentEvent.name.length > 0 &&
			currentEvent.startDate.length > 0 &&
			currentEvent.endDate.length > 0 &&
			isDescriptionValid
		) {
			setIsFormValid(true);
		} else if (isFormValid) {
			setIsFormValid(false);
		}
	}, [currentEvent, isDescriptionValid]);

	const saveImage = async (file: File | undefined) => {
		setChallengeImage({ file, fileName: file?.name ?? '' });
	};

	return {
		handlers: {
			onNextClick: async () => {
				try {
					let updatedEvent: SlEvent;
					if (isNewEvent) {
						updatedEvent = await createEvent(currentEvent).unwrap();
					} else {
						updatedEvent = await updateEvent(currentEvent).unwrap();
					}

					let isFileUploadError = false;
					if (challengeImage.file) {
						try {
							const imageData = new FormData();
							imageData.append('file', challengeImage.file as Blob);
							imageData.append(
								'fileName',
								`images/events/${updatedEvent.id}/event-logo.png`
							);
							await uploadFileToS3({
								orgId: updatedEvent.orgId,
								body: imageData,
							}).unwrap();
						} catch {
							isFileUploadError = true;
						}
					}

					if (!isFileUploadError) {
						dispatch(
							setToastSuccess(
								`The event was successfully ${
									isNewEvent ? 'created' : 'updated'
								}.`
							)
						);
					} else {
						dispatch(
							setToastError({
								message:
									'There was an error uploading the image to the S3 bucket.',
								errors: [],
							})
						);
					}

					navigate(`/events/${updatedEvent.id}`);
				} catch {
					dispatch(
						setToastError({
							message: `Failed to ${isNewEvent ? 'create' : 'update'} event.`,
							errors: [],
						})
					);
					navigate(`/events`);
				}
			},
			onBackClick: () => navigate(-1),
			saveImage,
		},
		form: {
			isFormValid,
			currentEvent,
			challengeImage,
			setLinks,
			setCurrentEvent,
			setIsDescriptionValid,
		},
	};
};

export default useManageEventForm;
