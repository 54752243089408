import React, { PropsWithChildren } from 'react';

import { InfoIcon } from '../../../../assets';
import './FormControl.scss';

interface Props extends PropsWithChildren {
	id: string;
	label: string;
	small?: boolean;
	style?: React.CSSProperties;
	required?: boolean;
	infoIconHoverElement?: JSX.Element;
}

const FormControl = ({
	id,
	label,
	small,
	style,
	required,
	infoIconHoverElement: infoIconText,
	children,
}: Props) => (
	<div
		className={`form-control${small ? '--small' : ''} w-100 flex-col`}
		style={style}
	>
		<label htmlFor={id} className='flex flex-row align-center'>
			{label} {required ? ' *' : ''}
			{infoIconText && (
				<InfoIcon
					onClick={() => {}}
					classes='info-icon__small'
					customStyles={{
						marginLeft: '0.7rem',
					}}
					hoverElement={infoIconText}
					hoverStyles={{ width: '30rem' }}
				/>
			)}
		</label>
		{children}
	</div>
);

export default FormControl;
