import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import {
	useCreateMarketingModalInfoMutation,
	useGetMarketingModalInfoQuery,
	useUpdateMarketingModalInfoMutation,
} from '../../../api/dashboard/endpoints/event-endpoints';
import { SlMarketingModal } from '../../../api/dashboard/schema/SlMarketingModal';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';

const useMarketingModalForm = (eventId: number) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const orgId = +SessionStorageService.orgId;

	const { data: marketingModalInfo, isError } = useGetMarketingModalInfoQuery({
		orgId,
		eventId,
	});

	const [
		createMarketingModalInfo,
		{ isLoading: isCreateMarketingModalLoading },
	] = useCreateMarketingModalInfoMutation();
	const [
		updateMarketingModalInfo,
		{ isLoading: isUpdateMarketingModalLoading },
	] = useUpdateMarketingModalInfoMutation();

	const [form, setForm] = useState<SlMarketingModal>({
		id: -1,
		eventId,
		title: '',
		subtitle: '',
		subtitleDescription: '',
		link: '',
		marketingPrompts: [''],
	});

	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [isNewMarketingModalInfo, setIsNewMarketingModalInfo] =
		useState<boolean>(true);

	useEffect(() => {
		if (marketingModalInfo && !isError) {
			setForm(marketingModalInfo);
			setIsNewMarketingModalInfo(false);
		}
	}, [marketingModalInfo, isError]);

	useEffect(() => {
		const isFooterValid =
			(form.subtitle.length === 0 &&
				form.subtitleDescription.length === 0 &&
				form.link.length === 0) ||
			(form.subtitle.length > 0 &&
				form.subtitleDescription.length > 0 &&
				form.link.length > 0);

		setIsFormValid(
			form.title.length > 0 &&
				isFooterValid &&
				form.marketingPrompts.length > 0 &&
				form.marketingPrompts.filter(x => x === '').length === 0
		);
	}, [form]);

	return {
		form: {
			form,
			setForm,
			isFormValid,
			isSubmitLoading:
				isCreateMarketingModalLoading || isUpdateMarketingModalLoading,
		},
		handlers: {
			onBackClick: () => navigate(-1),
			onNextClick: async () => {
				try {
					if (isNewMarketingModalInfo) {
						await createMarketingModalInfo({
							orgId,
							marketingModal: form,
						}).unwrap();
					} else {
						await updateMarketingModalInfo({
							orgId,
							marketingModal: form,
						}).unwrap();
					}

					dispatch(
						setToastSuccess(
							'The marketing modal was successfully saved for this event.'
						)
					);
				} catch {
					dispatch(
						setToastError({
							message: 'Failed to update the marketing modal for this event.',
							errors: [],
						})
					);
				}
				navigate(`/events/${eventId}`);
			},
			addPrompt: () => {
				setForm({ ...form, marketingPrompts: [...form.marketingPrompts, ''] });
			},
			updatePrompt: (index: number, prompt: string) => {
				const newArr = [...form.marketingPrompts];
				newArr[index] = prompt;
				setForm({ ...form, marketingPrompts: newArr });
			},
			deletePrompt: (index: number) => {
				const newArr = [...form.marketingPrompts];
				newArr.splice(index, 1);
				setForm({ ...form, marketingPrompts: newArr });
			},
		},
	};
};

export default useMarketingModalForm;
