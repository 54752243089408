import React from 'react';
import { useParams } from 'react-router-dom';

import { DeleteXSvg, PlusSvg } from '../../../assets';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import Button from '../../shared/forms/Button/Button';
import FormControl from '../../shared/forms/FormControl/FormControl';
import IconButton from '../../shared/forms/IconButton/IconButton';
import Textarea from '../../shared/forms/Textarea/Textarea';
import useMarketingModalForm from './useMarketingModalForm';

const MarketingModalForm = () => {
	const { eventId } = useParams();
	const {
		form: { form, setForm, isFormValid, isSubmitLoading },
		handlers: {
			onBackClick,
			onNextClick,
			addPrompt,
			updatePrompt,
			deletePrompt,
		},
	} = useMarketingModalForm(+(eventId ?? -1));

	return (
		<section className='marketing-details form-width m-auto'>
			<FormTitle title='Marketing Modal' />
			{isSubmitLoading && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<form className='flex-col gap-2'>
				<div className='flex-row gap-2'>
					<FormControl id='modal-title' label='Modal Title' required>
						<input
							id='modal-title'
							value={form.title}
							onChange={e => setForm({ ...form, title: e.target.value })}
						/>
					</FormControl>
				</div>

				<hr className='break-section' />

				<section className='marketing-details__input-list-section flex-col gap-2'>
					<h2 className='m-0'>Prompts</h2>
					{form.marketingPrompts.map((prompt, index) => (
						<div style={{ position: 'relative' }} key={`Prompt ${index + 1}`}>
							<FormControl
								id={`prompt-input-${index}`}
								label={`Prompt ${index + 1}`}
								required
							>
								<button
									type='button'
									onClick={() => deletePrompt(index)}
									className='marketing-details__button delete-rule transparent p-0'
									disabled={form.marketingPrompts.length === 1}
									title={
										form.marketingPrompts.length === 1
											? 'You must have at least one prompt'
											: ''
									}
								>
									<img src={DeleteXSvg} alt={`Delete prompt ${index}`} />
								</button>
								<input
									id={`prompt-input-${index}`}
									value={prompt}
									onChange={e => updatePrompt(index, e.target.value)}
									style={{ paddingRight: '4rem' }}
								/>
							</FormControl>
						</div>
					))}
					<div className='marketing-details__input-list-section__button'>
						<IconButton
							icon={PlusSvg}
							title='Add Prompt'
							classNames='bebas-large primary-blue'
							onClick={addPrompt}
						>
							ADD PROMPT
						</IconButton>
					</div>
				</section>

				<hr className='break-section' />

				<section className='marketing-details__footer-section flex-col gap-2'>
					<h2 className='m-0'>Footer</h2>
					<div className='flex-row gap-2'>
						<FormControl
							id='footer-subtitle'
							label='SubTitle'
							required={
								form.link.length > 0 || form.subtitleDescription.length > 0
							}
						>
							<input
								id='footer-subtitle'
								value={form.subtitle}
								onChange={e => setForm({ ...form, subtitle: e.target.value })}
							/>
						</FormControl>
						<FormControl
							id='footer-link'
							label='Link'
							required={
								form.subtitle.length > 0 || form.subtitleDescription.length > 0
							}
						>
							<input
								id='footer-link'
								value={form.link}
								onChange={e => setForm({ ...form, link: e.target.value })}
							/>
						</FormControl>
					</div>
					<div className='flex-row'>
						<FormControl
							id='footer-subtitle-description'
							label='SubTitle Description'
							required={form.subtitle.length > 0 || form.link.length > 0}
						>
							<Textarea
								id='footer-subtitle-description'
								value={form.subtitleDescription}
								onChange={val => {
									if (val.length <= 100)
										setForm({ ...form, subtitleDescription: val });
								}}
								charLimit={100}
								customStyles={{ height: '8rem' }}
							/>
						</FormControl>
					</div>
				</section>
				<section className='flex-row gap-2' style={{ marginTop: '6rem' }}>
					<Button variant='outlined' onClick={onBackClick}>
						Back
					</Button>
					<Button disabled={!isFormValid} onClick={onNextClick}>
						Save
					</Button>
				</section>
			</form>
		</section>
	);
};

export default MarketingModalForm;
