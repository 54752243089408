// eslint-disable-next-line import/prefer-default-export
import * as XLSX from 'xlsx';

export const alphaNumericSort = (a: string, b: string) =>
	a.localeCompare(b, undefined, {
		numeric: true,
		sensitivity: 'base',
	});

export const changeDateFormat = (date: string) => {
	const dateArray = date.split('-');
	const [year, month, day] = dateArray;
	return `${month}/${day}/${year}`;
};

export const getFileExtension = (value: string | undefined) =>
	value?.split('.').pop() ?? 'png';

export const capitalizeFirstLetter = (value: string) =>
	value.charAt(0).toUpperCase() + value.slice(1);

export const convertToCamelCase = (value: string) => {
	const regex = /\s+(\w)?/gi;
	return value
		.toLowerCase()
		.replace(regex, (match, letter) => letter.toUpperCase());
};

export const reverseCamelCase = (value: string) => {
	// put spaces between characters that have an upper case (break up camel case)
	const spacedString = value.replace(/([A-Z])/g, ' $1').trim();
	return capitalizeFirstLetter(spacedString);
};

export const convertStringToDate = (value: string | undefined) =>
	value ? new Date(new Date(value).toISOString().slice(0, -1)) : null;

export const parseDataSheet = async <Type>(
	file: File | undefined,
	requiredHeaders: string[] = []
) => {
	let responseData: Type[] = [];
	if (file) {
		const data = await file.arrayBuffer();
		const workbook = XLSX.read(data, {
			type: 'binary',
			dateNF: 'YYYY-MM-DD',
		});

		const worksheet = workbook.Sheets[workbook.SheetNames[0]];

		// Loop through headers so they are in camel case format
		if (worksheet && worksheet['!ref']) {
			const ref = XLSX.utils.decode_range(worksheet['!ref']);
			for (let C = ref.s.c; C <= ref.e.c; ++C) {
				const cell = worksheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
				if (cell.t === 's') {
					const newValue = convertToCamelCase(cell.v);
					cell.v = newValue;
					requiredHeaders = requiredHeaders?.filter(x => x !== newValue);

					if (cell.w) cell.w = convertToCamelCase(cell.w);
				}
			}
		}

		responseData = XLSX.utils.sheet_to_json(worksheet, {
			header: 0,
			defval: null,
			raw: false,
		});
	}

	return {
		data: responseData,
		requiredHeaders,
	};
};
