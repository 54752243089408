import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../../api/SessionStorageService';
import { useGetOrganizationsQuery } from '../../../../api/dashboard/endpoints/organization-endpoints';
import { CaptechSportsExperiences, ShotLanderLogo } from '../../../../assets';
import { setToastError } from '../../../../redux/app-slice';
import { useAppDispatch } from '../../../../redux/store';
import './Home.scss';

const Home = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [isOrgDisabled, setIsOrgDisabled] = useState<boolean>(false);

	const { data, error } = useGetOrganizationsQuery();

	const disableOrg = () => {
		setIsOrgDisabled(true);

		dispatch(
			setToastError({
				message: 'This organization is disabled.',
				errors: [
					'Please contact your administrator to enable this organization again.',
				],
				showIndefinitely: true,
			})
		);
	};

	useEffect(() => {
		if (error) {
			disableOrg();
		}
		if (data) {
			if (data.length === 1) {
				const newOrg = data[0];
				SessionStorageService.orgId = newOrg.id.toString();

				// if organization is not active, show error message
				if (!newOrg.active) {
					disableOrg();
				}
			} else if (+SessionStorageService.orgId < 0) {
				SessionStorageService.haveMultipleOrgs = 'true';
				navigate('/organizations');
			}
		}
	}, [data, error]);

	return (
		<section className='home-section flex-col align-center justify-center w-100 h-100'>
			<div className='home-section__blur w-100 h-100' />
			<img
				src={ShotLanderLogo}
				alt='Shot Lander logo displaying a golf ball landing on crosshairs.'
				style={{ zIndex: 2 }}
			/>
			<div
				className='flex-row align-center justify-center'
				style={{ columnGap: '5rem', marginTop: '6rem' }}
			>
				<Link to='/events' className='z-2 decoration-none'>
					<button
						type='button'
						className='flex-col align-center home-button justify-center bebas-large'
						disabled={isOrgDisabled}
					>
						EVENTS
					</button>
				</Link>
			</div>
			<img
				src={CaptechSportsExperiences}
				alt='CapTech Sports Experiences logo'
				style={{ zIndex: 2, position: 'absolute', bottom: 36, left: 42 }}
			/>
		</section>
	);
};

export default Home;
