import React from 'react';
import { useNavigate } from 'react-router-dom';

import SessionStorageService from '../../../api/SessionStorageService';
import { useDeleteEventMutation } from '../../../api/dashboard/endpoints/event-endpoints';
import { DownIconSvg } from '../../../assets';
import { setToastError, setToastSuccess } from '../../../redux/app-slice';
import { useAppDispatch } from '../../../redux/store';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Loader from '../../shared/components/Loader/Loader';
import DeleteModal from '../../shared/components/Modal/DeleteModal';
import Paginator from '../../shared/components/Paginator/Paginator';
import ScrollableTable from '../../shared/components/ScrollableTable/ScrollableTable';
import { widthOfActionPopup } from '../../shared/constants';
import ActionPopup from '../EventDetails/ActionPopup';
import useListEvents from './useListEvents';

const ListEvents = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const orgId = +SessionStorageService.orgId;

	const [deleteEvent] = useDeleteEventMutation();

	const {
		columns,
		data,
		isLoading,
		isFilterVisible,
		setIsFilterVisible,
		filterOptions,
		filterValue,
		selectedEvent,
		isModalOpen,
		toggleModal,
		onPageChange,
		pageSize,
		pageIndex,
		totalElements,
	} = useListEvents();

	return (
		<section className='table-width m-auto'>
			<FormTitle title='Events Dashboard' />
			{isLoading && (
				<>
					<div
						aria-label='overlay'
						className='dark-overlay w-100 h-100 flex-col justify-center align-center'
					/>
					<Loader
						customStyles={{
							position: 'fixed',
							top: '30%',
							left: 'calc(50% - 6rem)',
						}}
					/>
				</>
			)}
			<div className='flex-row w-100 mb-1' style={{ position: 'relative' }}>
				<button
					type='button'
					className='ml-auto bebas-large primary-blue transparent'
					onClick={() => navigate('/manage-event')}
				>
					Add Event
				</button>
				<button
					type='button'
					className='bebas-large primary-blue transparent'
					onClick={() => setIsFilterVisible(prev => !prev)}
					style={{ marginLeft: '4rem' }}
				>
					{filterValue.text}
					<img
						style={{ marginLeft: '0.5rem' }}
						src={DownIconSvg}
						alt='dropdown'
					/>
				</button>
				{isFilterVisible && (
					<ActionPopup
						options={filterOptions}
						styles={{
							top: 25,
							right: 0,
							width: `${widthOfActionPopup / 10}rem`,
						}}
						mouseLeaveHandler={() => setIsFilterVisible(false)}
					/>
				)}
			</div>
			<ScrollableTable
				columns={columns}
				data={data ?? []}
				classes='box-shadow'
				tableStyles={{ width: '100%', paddingBottom: '1rem' }}
			/>
			<Paginator
				updatePageResults={onPageChange}
				pageSize={pageSize}
				totalElements={totalElements}
				pageIndex={pageIndex}
			/>
			<DeleteModal
				isVisible={isModalOpen}
				toggleModal={toggleModal}
				onSubmit={async () => {
					if (selectedEvent) {
						try {
							await deleteEvent({
								orgId,
								eventId: selectedEvent.eventId,
							}).unwrap();
							dispatch(setToastSuccess('This event was successfully deleted.'));
						} catch {
							dispatch(
								setToastError({
									message: `Failed to delete ${selectedEvent.eventName}`,
									errors: [],
								})
							);
						}
						toggleModal();
					}
				}}
				type='event'
				title={selectedEvent?.eventName}
			/>
		</section>
	);
};

export default ListEvents;
