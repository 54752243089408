import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from 'react-text-mask';

import { convertStringToDate } from '../../../../misc/helper-util';
import './DatePicker.scss';

interface Props {
	id: string;
	date: string | undefined;
	minDate?: string;
	maxDate?: string;
	onChange: (date: string) => void;
}

const CustomDatePicker = ({
	id,
	date,
	minDate,
	maxDate,
	onChange,
	...rest
}: Props) => (
	<DatePicker
		selected={convertStringToDate(date)}
		onChange={(dateChange: Date | null) => {
			if (dateChange) onChange(dateChange.toJSON().slice(0, 10));
			else onChange('');
		}}
		dateFormat='yyyy-MM-dd'
		placeholderText='YYYY-MM-DD'
		popperModifiers={[
			{
				name: 'arrow',
				options: { padding: 50 },
			},
		]}
		todayButton='Today'
		minDate={convertStringToDate(minDate)}
		maxDate={convertStringToDate(maxDate)}
		customInput={
			<MaskedInput
				mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
				keepCharPositions
				className='datepicker-input-calendar'
			/>
		}
		{...rest}
	/>
);

export default CustomDatePicker;
