import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { dashboardApi } from '../api/dashboard/dashboard-api';
import { appSlice } from './app-slice';

export const store = configureStore({
	reducer: {
		app: appSlice.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(dashboardApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
