import {
	createDeleteRequest,
	createGetRequest,
	createPostRequest,
} from '../../api-util';
import { dashboardApi } from '../dashboard-api';
import SlAuthTokenRequest from '../schema/SlAuthTokenRequest';
import SlDeviceUsage from '../schema/SlDeviceUsage';

export const deviceProvisioningEndpoints = dashboardApi.injectEndpoints({
	endpoints: builder => ({
		getDeeplink: builder.query<string, string>({
			query: query => createGetRequest(`device-provisioning/${query}`),
			transformErrorResponse: response =>
				// if response is an object, return false. otherwise, return string
				typeof response.data === 'object' && response.data !== null
					? false
					: response.data,
		}),
		getDeviceUsage: builder.query<SlDeviceUsage, string>({
			query: query => createGetRequest(`/device-provisioning/${query}/usage`),
		}),
		getQRCode: builder.query<string, string>({
			query: query => createGetRequest(`/device-provisioning/${query}/qrcode`),
			transformErrorResponse: response =>
				// if response is an object, return false. otherwise, return string
				typeof response.data === 'object' && response.data !== null
					? false
					: response.data,
		}),
		createAuthToken: builder.mutation<
			void,
			{ query: string; body: SlAuthTokenRequest }
		>({
			query: ({ query, body }) =>
				createPostRequest(`/device-provisioning/${query}`, body),
		}),
		deleteAuthToken: builder.mutation<void, string>({
			query: query => createDeleteRequest(`device-provisioning/${query}`),
		}),
	}),
});

export const {
	useLazyGetDeeplinkQuery,
	useLazyGetDeviceUsageQuery,
	useLazyGetQRCodeQuery,
	useCreateAuthTokenMutation,
	useDeleteAuthTokenMutation,
} = deviceProvisioningEndpoints;
