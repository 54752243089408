import React, { LegacyRef, ReactNode } from 'react';

import { DeleteXSvg } from '../../../../assets';
import './Modal.scss';

interface Props {
	modalRef?: LegacyRef<HTMLDivElement> | undefined;
	isVisible: boolean;
	toggleModal?: () => void;
	customStyles?: { [key: string]: string };
	children: ReactNode;
}

const Modal = (props: Props) => {
	const { modalRef, isVisible, toggleModal, customStyles, children } = props;

	return isVisible ? (
		<>
			<div
				aria-label='overlay'
				className='dark-overlay w-100 h-100 flex-col justify-center align-center'
			/>
			<div ref={modalRef} className='modal flex-col' style={customStyles}>
				{toggleModal && (
					<button
						type='button'
						onClick={toggleModal}
						className='modal-close transparent p-0 ml-auto'
					>
						<img src={DeleteXSvg} alt='Close modal' />
					</button>
				)}
				<div className='modal-content'>{children}</div>
			</div>
		</>
	) : null;
};

export default Modal;
