import React, { useMemo } from 'react';

import SlParticipant from '../../../api/dashboard/schema/SlParticipant';
import states from '../../../data/states';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import Button from '../../shared/forms/Button/Button';
import DatePicker from '../../shared/forms/DatePicker/DatePicker';
import FormControl from '../../shared/forms/FormControl/FormControl';
import Select from '../../shared/forms/Select/Select';
import { DivisionType } from '../../shared/types/enums';
import {
	divisionOptions,
	dominantHandOptions,
	genderOptions,
	heightOptions,
	weightOptions,
} from './selectOptions';
import useAddPlayerForm from './useAddPlayerForm';

interface Props {
	toggleModal: (playerToAdd: SlParticipant | null) => void;
	playerId?: number;
	groupId?: number;
}

const AddPlayerForm = ({ toggleModal, playerId, groupId }: Props) => {
	const { currentPlayer, setCurrentPlayer, isFormValid, isNewPlayer, onSave } =
		useAddPlayerForm(toggleModal, playerId, groupId);

	return (
		<div className='form-width m-auto'>
			<FormTitle
				title={`${isNewPlayer ? 'New' : 'Edit'} Player Information`}
				customStyles={{ marginTop: '1rem' }}
			/>
			<form>
				<div className='row'>
					<FormControl id='first-name' label='First Name' required>
						<input
							id='first-name'
							onChange={e =>
								setCurrentPlayer({
									...currentPlayer,
									firstName: e.target.value,
								})
							}
							value={currentPlayer.firstName}
						/>
					</FormControl>
					<FormControl id='last-name' label='Last Name' required>
						<input
							id='last-name'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, lastName: e.target.value })
							}
							value={currentPlayer.lastName}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='dob' label='Date of Birth' required>
						<DatePicker
							id='dob'
							date={currentPlayer.dateOfBirth}
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, dateOfBirth: e })
							}
						/>
					</FormControl>
					<FormControl id='gender' label='Gender'>
						<Select
							id='gender'
							value={currentPlayer.gender}
							onChange={value =>
								setCurrentPlayer({ ...currentPlayer, gender: value })
							}
							options={useMemo(() => genderOptions, [])}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='height' label='Height (in.)'>
						<Select
							id='height'
							value={currentPlayer.height?.toString() ?? ''}
							onChange={value =>
								setCurrentPlayer({ ...currentPlayer, height: +value })
							}
							options={heightOptions}
						/>
					</FormControl>
					<FormControl id='weight' label='Weight (lbs.)'>
						<Select
							id='weight'
							value={currentPlayer.weight?.toString() ?? ''}
							onChange={value =>
								setCurrentPlayer({ ...currentPlayer, weight: +value })
							}
							options={weightOptions}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='dominant-hand' label='Dominant Hand'>
						<Select
							id='dominant-hand'
							value={currentPlayer.dominantHand}
							onChange={value =>
								setCurrentPlayer({ ...currentPlayer, dominantHand: value })
							}
							options={dominantHandOptions}
						/>
					</FormControl>
					<FormControl id='division-level' label='Division'>
						<Select
							id='division-level'
							value={currentPlayer.division?.toString() ?? ''}
							onChange={value =>
								setCurrentPlayer({
									...currentPlayer,
									division: DivisionType[value as keyof typeof DivisionType],
								})
							}
							options={divisionOptions}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='email' label='Email'>
						<input
							id='email'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, email: e.target.value })
							}
							value={currentPlayer.email}
						/>
					</FormControl>
					<FormControl id='phone' label='Phone'>
						<input
							id='phone'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, phone: e.target.value })
							}
							value={currentPlayer.phone ?? ''}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='street-address' label='Street Address'>
						<input
							id='street-address'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, address: e.target.value })
							}
							value={currentPlayer.address}
						/>
					</FormControl>
					<FormControl id='city' label='City'>
						<input
							id='city'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, city: e.target.value })
							}
							value={currentPlayer.city}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='state' label='State'>
						<Select
							id='state'
							onChange={value =>
								setCurrentPlayer({ ...currentPlayer, state: value })
							}
							value={currentPlayer.state !== 'State' ? currentPlayer.state : ''}
							options={states}
						/>
					</FormControl>
					<FormControl id='zip-code' label='Zip Code'>
						<input
							id='zip-code'
							onChange={e =>
								setCurrentPlayer({ ...currentPlayer, zip: e.target.value })
							}
							value={currentPlayer.zip}
						/>
					</FormControl>
				</div>
				<div className='row'>
					<FormControl id='description' label='Description'>
						<input
							id='description'
							onChange={e =>
								setCurrentPlayer({
									...currentPlayer,
									description: e.target.value,
								})
							}
							value={currentPlayer.description}
						/>
					</FormControl>
				</div>
				<div className='row' style={{ marginTop: '2rem' }}>
					<Button
						classes='w-50 m-auto'
						onClick={onSave}
						disabled={!isFormValid}
					>
						{isNewPlayer ? 'Add New Player' : 'Save Player'}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default AddPlayerForm;
