import React from 'react';

import { DownIconSvg } from '../../../../assets';
import './Select.scss';

interface Props {
	id: string;
	onChange: (value: string) => void;
	value: string;
	options: {
		value: string;
		displayText: string;
		disabled?: boolean;
	}[];
	small?: boolean;
	customStyles?: { [key: string]: string };
}

const Select = ({
	id,
	options,
	onChange,
	value,
	small,
	customStyles,
}: Props) => (
	<div className={`select-wrapper${small ? '--small' : ''} w-100`}>
		<select
			id={id}
			value={value}
			className='select w-100'
			style={small ? { padding: '0 0.8rem', ...customStyles } : {}}
			onChange={e => onChange(e.target.value)}
		>
			{/* This option is needed so nothing is selected initially */}
			<option hidden> </option>
			{options.map(({ value: val, disabled, displayText }) => (
				<option key={val} disabled={disabled} value={val}>
					{displayText}
				</option>
			))}
		</select>
		<div className='caret-container'>
			<img src={DownIconSvg} alt='dropdown' />
		</div>
	</div>
);

export default Select;
