import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	AuthenticateSvg,
	CalendarSvg,
	DescriptionSvg,
	EditSvg,
	FlagPoleSvg,
	LocationSvg,
	PlusSvg,
	TrashCanSvg,
} from '../../../assets';
import IconButton from '../../../features/shared/forms/IconButton/IconButton';
import FormTitle from '../../shared/components/FormTitle/FormTitle';
import AuthenticationModal from '../../shared/components/Modal/AuthenticationModal';
import DeleteModal from '../../shared/components/Modal/DeleteModal';
import Modal from '../../shared/components/Modal/Modal';
import ScrollableTable from '../../shared/components/ScrollableTable/ScrollableTable';
import { widthOfActionPopup } from '../../shared/constants';
import Button from '../../shared/forms/Button/Button';
import Card from '../../shared/layout/card/Card';
import { ActionType, ModalType } from '../../shared/types/enums';
import AddPlayerForm from '../AddPlayerForm/AddPlayerForm';
import ActionPopup from './ActionPopup';
import './EventDetails.scss';
import useEventDetails, {
	ChallengeData,
	MarketingType,
} from './useEventDetails';

const EventDetails = () => {
	const navigate = useNavigate();

	const {
		state: {
			isModalOpen,
			toggleModal,
			isPopupVisible,
			setIsPopupVisible,
			popupTop,
			popupLeft,
			actionType,
			elementId,
			rowName,
			handleModal,
			currentEvent,
			handleDelete,
			updatePlayer,
			marketingInfoId,
			marketingModalId,
			marketingOfferId,
		},
		data: { roundColumns, roundData, playerColumns, playerData },
	} = useEventDetails();

	const [authRounds, setAuthRounds] = useState(roundData);
	const [modalType, setModalType] = useState<ModalType>();

	const pickModalType = (type: ModalType, newAuthRounds?: ChallengeData[]) => {
		const selectedRound = roundData?.find(x => x.id === elementId);

		if (newAuthRounds) setAuthRounds(newAuthRounds);
		else if (selectedRound) setAuthRounds([selectedRound]);

		setModalType(type);
		toggleModal();
	};

	const pageTitle = currentEvent?.name ?? 'Event Not Found';
	const address = currentEvent?.address
		? `${currentEvent?.address}, ${currentEvent?.city}, ${currentEvent?.state} ${currentEvent?.zipCode}`
		: '';

	return (
		<section className='form-width m-auto'>
			<FormTitle title={pageTitle} />
			<div className='flex-col' style={{ rowGap: '4rem' }}>
				<Card
					title={pageTitle}
					buttons={useMemo(
						() => [
							{
								text: 'Edit',
								action: () => {
									navigate(`/manage-event/${currentEvent?.id}`);
								},
							},
						],
						[currentEvent?.id]
					)}
				>
					<div style={{ columnGap: '2rem' }} className='flex-row'>
						<div style={{ width: '40%' }} className='flex-col'>
							<div className='event-card-content__description'>
								<img
									src={CalendarSvg}
									alt='Calendar Icon for the Event Details card'
								/>
								<span>
									{currentEvent?.startDate} - {currentEvent?.endDate}
								</span>
							</div>
							<div className='event-card-content__description'>
								<img
									src={FlagPoleSvg}
									alt='Flag Pole Icon for the Event Details card'
									style={{ transform: 'scale(1.1)' }}
								/>
								<span>{currentEvent?.venueName}</span>
							</div>
							<div className='event-card-content__description'>
								<img
									src={LocationSvg}
									alt='Location Icon for the Event Details card'
									style={{ transform: 'scale(1.3)' }}
								/>
								<span>{address}</span>
							</div>
						</div>
						<div style={{ width: '60%' }}>
							<div className='event-card-content__description'>
								<img
									src={DescriptionSvg}
									alt='Description Icon for the Event Details card'
								/>
								<span>{currentEvent?.description}</span>
							</div>
						</div>
					</div>
				</Card>

				<Card title='Marketing Details'>
					<div className='marketing-details-card flex-col'>
						<h4>
							<b>{MarketingType.MarketingPage}</b>
						</h4>
						<p className='grey-dark'>
							Make changes to the contents of the marketing page. Edit how to
							play, the subtitle, prize list, and external links.
						</p>
						<div className='flex-row gap-2'>
							<IconButton
								key='edit-marketing'
								onClick={() => navigate('marketing')}
								icon={EditSvg}
								title='Edit'
							>
								<div>{marketingInfoId ? 'Edit' : 'Add'}</div>
							</IconButton>
							{marketingInfoId && (
								<IconButton
									key='delete-marketing'
									onClick={() => {
										handleModal(
											ActionType.Marketing,
											marketingInfoId,
											MarketingType.MarketingPage
										);
										setModalType(ModalType.Delete);
										toggleModal();
									}}
									icon={TrashCanSvg}
									title='Delete Marketing'
								>
									<div>Delete</div>
								</IconButton>
							)}
						</div>
						<br />

						<h4>
							<b>{MarketingType.MarketingModal}</b>
						</h4>
						<p className='grey-dark'>
							Make changes to the contents of the marketing modal. Edit the
							title, marketing prompts, subtitle, and external links.
						</p>
						<div className='flex-row gap-2'>
							<IconButton
								key='edit-marketing-modal'
								onClick={() => navigate('marketing/modal')}
								icon={EditSvg}
								title='Edit'
							>
								<div>{marketingModalId ? 'Edit' : 'Add'}</div>
							</IconButton>
							{marketingModalId && (
								<IconButton
									key='delete-marketing-modal'
									onClick={() => {
										handleModal(
											ActionType.Marketing,
											marketingModalId,
											MarketingType.MarketingModal
										);
										setModalType(ModalType.Delete);
										toggleModal();
									}}
									icon={TrashCanSvg}
									title='Delete Marketing Modal'
								>
									<div>Delete</div>
								</IconButton>
							)}
						</div>

						<br />
						<h4>
							<b>{MarketingType.Offer}</b>
						</h4>
						<p className='grey-dark'>
							Add or remove an offer presented to users in the landing page and
							the shot viewer.
						</p>
						<div className='flex-row gap-2'>
							<IconButton
								key='edit-offer'
								onClick={() => navigate('marketing/sponsorship')}
								icon={EditSvg}
								title='Edit'
							>
								<div>{marketingOfferId ? 'Edit' : 'Add'}</div>
							</IconButton>
							{marketingOfferId && (
								<IconButton
									key='delete-offer'
									onClick={() => {
										handleModal(
											ActionType.Marketing,
											marketingOfferId,
											MarketingType.Offer
										);
										setModalType(ModalType.Delete);
										toggleModal();
									}}
									icon={TrashCanSvg}
									title='Delete Offer'
								>
									<div>Delete</div>
								</IconButton>
							)}
						</div>
					</div>
				</Card>

				<Card
					title='Challenge Details'
					buttons={useMemo(
						() => [
							{
								icon: AuthenticateSvg,
								text: 'Authenticate Challenges',
								action: () => pickModalType(ModalType.Authenticate, roundData),
								disabled: !roundData || roundData.length === 0,
								hoverTitle:
									!roundData || roundData.length === 0
										? 'Create at least one challenge first'
										: '',
							},
							{
								icon: PlusSvg,
								text: 'Add Challenge',
								action: () => navigate('challenges'),
							},
						],
						[roundData]
					)}
				>
					{roundData && (
						<ScrollableTable
							columns={roundColumns}
							data={roundData}
							tableStyles={{
								height: roundData.length >= 4 ? '20.7rem' : 'auto',
							}}
						/>
					)}
				</Card>
				<Card title='Player Details'>
					{playerData && (
						<ScrollableTable
							columns={playerColumns}
							data={playerData}
							tableStyles={{
								height: playerData.length >= 4 ? '20.7rem' : 'auto',
							}}
						/>
					)}
				</Card>
				<Button
					classes='w-50 m-auto'
					onClick={() => {
						handleModal(ActionType.Event, currentEvent?.id ?? -1, pageTitle);
						pickModalType(ModalType.Delete);
					}}
				>
					Delete
				</Button>
			</div>

			{isPopupVisible && (
				<ActionPopup
					text={actionType}
					id={elementId}
					pickModalType={pickModalType}
					styles={{
						top: popupTop,
						left: popupLeft,
						width: `${widthOfActionPopup / 10}rem`,
					}}
					mouseLeaveHandler={() => setIsPopupVisible(false)}
				/>
			)}
			<DeleteModal
				isVisible={isModalOpen && modalType === ModalType.Delete}
				toggleModal={toggleModal}
				onSubmit={handleDelete}
				type={actionType ?? ''}
				title={rowName ?? ''}
			/>
			{authRounds && authRounds.length > 0 && (
				<AuthenticationModal
					isVisible={isModalOpen && modalType === ModalType.Authenticate}
					toggleModal={toggleModal}
					title={
						authRounds.length > 1
							? 'Authenticate Challenges'
							: 'Authenticate Challenge'
					}
					subTitle={authRounds.length > 1 ? pageTitle : authRounds[0].challenge}
					rounds={authRounds}
				/>
			)}
			<Modal
				isVisible={isModalOpen && modalType === ModalType.Edit}
				toggleModal={toggleModal}
				customStyles={{ width: '90rem', height: '92rem' }}
			>
				<AddPlayerForm playerId={elementId} toggleModal={updatePlayer} />
			</Modal>
		</section>
	);
};

export default EventDetails;
