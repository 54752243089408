import React, { useEffect, useState } from 'react';

import { DeleteXSvg } from '../../../../assets';
import { resetToast } from '../../../../redux/app-slice';
import { useAppDispatch } from '../../../../redux/store';
import './Toast.scss';

export interface SlToast {
	isVisible: boolean;
	isSuccess: boolean;
	message: string;
	subMessages: string[];
	showIndefinitely?: boolean;
}

interface Props {
	params: SlToast;
}

const Toast = ({ params }: Props) => {
	const dispatch = useAppDispatch();

	const [top, setTop] = useState<number>(0);

	useEffect(() => {
		const changeTop = () => {
			setTop(window.scrollY);
		};

		window.addEventListener('scroll', changeTop);

		return () => window.removeEventListener('scroll', changeTop);
	}, []);

	useEffect(() => {
		if (params.isVisible && !params.showIndefinitely) {
			const timeoutMilliseconds = params.subMessages.length > 0 ? 8000 : 3000;
			setTimeout(() => {
				dispatch(resetToast());
			}, timeoutMilliseconds);
		}
	}, [params.isVisible, params.showIndefinitely]);

	return (
		<div
			className={`toast box-shadow gibson-large ${
				params.isSuccess ? 'toast-success' : 'toast-error'
			} ${params.isVisible ? 'toast-open' : ''}`}
			style={{ top: `calc(${top}px + 5rem)` }}
		>
			<span className='flex-row align-center gibson-normal bold'>
				{params.message}
			</span>
			{params.subMessages && params.subMessages.length > 0 && (
				<ul>
					{params.subMessages.map(x => (
						<li className='gibson-normal' key={x}>
							{x}
						</li>
					))}
				</ul>
			)}
			<button
				type='button'
				className='toast__exit transparent'
				onClick={() => dispatch(resetToast())}
			>
				<img className='toast__exit' src={DeleteXSvg} alt='Exit Toast' />
			</button>
		</div>
	);
};

export default Toast;
